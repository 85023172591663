import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Form } from '~/shared/form';

export const FieldAutocomplete: <T extends Record<string, any>, K extends keyof T>(
	props: {
		items: T[];
		valueProp: K;
		labelProp: keyof T;
		enabledProp?: keyof T;
		field: Form.Field<T[K][]> | Form.Field<T[K][] | undefined>;
	} & TextFieldProps,
) => JSX.Element = observer(({ field, items, valueProp, labelProp, enabledProp, ...props }) => {
	return (
		<Autocomplete
			fullWidth
			multiple
			options={items}
			getOptionLabel={option => option[labelProp]}
			getOptionDisabled={option => (enabledProp ? !option[enabledProp] : false)}
			value={
				field.value
					?.map(v => items.find(i => i[valueProp] === v))
					.filter((v): v is (typeof items)[number] => !!v) ?? []
			}
			onChange={(_, value) => field.set(value.map(v => v[valueProp]))}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option[valueProp]}>
						{option[labelProp]}
					</li>
				);
			}}
			renderInput={params => (
				<TextField
					{...props}
					{...params}
					error={!!field.hasError}
					helperText={field.helperText}
					label={field.label}
					variant="standard"
				/>
			)}
		/>
	);
});
