import { FormControlLabel, Switch, SwitchProps } from '@mui/material';
import { Form } from '~/shared/form/form';

export const FieldSwitch = Form.field<boolean, SwitchProps>(({ field, ...props }) => {
	return (
		<FormControlLabel
			control={<Switch checked={field.value ?? false} onChange={e => field.set(e.target.checked)} {...props} />}
			label={field.label}
		/>
	);
});
