import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { Button, Drawer, IconButton, LinearProgress, SxProps } from '@mui/material';
import { DataGrid, GridValidRowModel } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { ChangeLogService } from '~/service/service.changelog';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import { DateTimeColumn, GridColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';
import { Col, Row, Text } from '~/view/view.box';

export const ChangeLogPage = observer(() => {
	const [changeLogSvc, ui] = useService(ChangeLogService, UIService);
	const columns = useGridColumns<API.ChangeLogItem>(() => [
		DateTimeColumn({ field: 'createdAt', headerName: 'At' }),
		{ field: 'staffName', headerName: 'By', minWidth: 150 },
		{ field: 'action', headerName: 'Action', minWidth: 100 },
		{ field: 'entity', headerName: 'Entity', minWidth: 120 },
		LinkColumn({ field: 'entityId', headerName: 'ID', minWidth: 100 }),
		{ field: 'title', headerName: 'Title', minWidth: 200 },
		{ field: 'comment', headerName: 'Comment', flex: 1 },
	]);
	const router = useNavigate();

	ui.usePageTitle('ChangeLog');
	const [params] = useTypedSearchParams(AppRoutes.ChangeLog);

	useEffect(() => {
		changeLogSvc.filterByEntity(params.entity as API.ChangeLogEntity | undefined, params.id);
	}, [changeLogSvc, params.entity, params.id]);

	useEffect(() => changeLogSvc.loadAndSubscribe(), [changeLogSvc]);

	return (
		<>
			<ChangeLogDrawer />
			<GridContainer async={changeLogSvc.async}>
				<DataGrid
					initialState={changeLogSvc.gridState.initial()}
					loading={changeLogSvc.async.loading}
					rowCount={changeLogSvc.totalCount}
					rows={changeLogSvc.items.slice()}
					columns={columns}
					paginationMode="server"
					sortingMode="server"
					disableColumnFilter
					onStateChange={changeLogSvc.gridState.onStateChange}
					onRowClick={params => {
						changeLogSvc.setSelectedItem(params.row);
					}}
					slots={{
						loadingOverlay: LinearProgress,
						noRowsOverlay: () => <GridNoRows>No change logs found</GridNoRows>,
						pagination: MuiGridPagination,
						toolbar: () => (
							<GridToolbarView onReload={changeLogSvc.reload} gridState={changeLogSvc.gridState}>
								{params.entity && params.id && (
									<Col pl={4}>
										<Button
											variant="contained"
											color="warning"
											onClick={() => {
												changeLogSvc.resetFilterByEntity();
												router(AppRoutes.ChangeLog.buildPath({}));
											}}
										>
											Reset filter: {params.entity}:{params.id}
										</Button>
									</Col>
								)}
							</GridToolbarView>
						),
					}}
				/>
			</GridContainer>
		</>
	);
});

const Anchor = styled.a({
	textDecoration: 'underline',
});

function LinkColumn<T extends GridValidRowModel>(def: GridColumn<T>): GridColumn<T> {
	return {
		width: 200,
		filterable: true,
		renderCell: ({ value, row }) => {
			return (
				<Anchor href={`/${row.entity.toLowerCase()}/${row.entityId}`} target="_blank">
					{value}
				</Anchor>
			);
		},
		...def,
	};
}

const sxDrawer: SxProps = {
	width: 350,
	flexShrink: 0,
	'& .MuiDrawer-paper': {
		width: 350,
		top: 64,
		px: 2,
		height: 'calc(100% - 64px)',
		boxSizing: 'border-box',
		zIndex: 1000,
	},
};

const ChangeLogDrawer = observer(() => {
	const [changeLogSvc] = useService(ChangeLogService);
	const selected = changeLogSvc.selectedItem;
	const diff = selected?.diff.split('\n').filter(s => !!s);

	return (
		<Drawer sx={sxDrawer} PaperProps={{ elevation: 4 }} variant="persistent" anchor="right" open={!!selected}>
			<Col>
				<Row>
					<IconButton onClick={() => changeLogSvc.setSelectedItem(undefined)}>
						<Close />
					</IconButton>
				</Row>
				<Text style={{ overflowWrap: 'anywhere' }}>Comment: {selected?.comment}</Text>
				<pre>
					{selected?.action === 'DELETE'
						? JSON.stringify(JSON.parse(selected.diff), null, 2)
						: diff && diff.length
						? diff.map((line, i) => {
								const color = line.startsWith('\u001b[31m') || line.startsWith('-') ? 'red' : 'green';
								return (
									<div key={i} style={{ color }}>
										{line
											.replace('\u001b[31m', '')
											.replace('\u001b[39m', '')
											.replace('\u001b[32m', '')}
									</div>
								);
						  })
						: selected?.comment
						? null
						: 'Nothing was changed'}
				</pre>
			</Col>
		</Drawer>
	);
});
