import dayjs from 'dayjs';
import { makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { GridStateModel } from '~/model/model.gridstate';
import { PushCampaignCreateDto } from '~/shared/dto/admin/push.campaign.dto';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

@Singleton()
export class PushCampaignService {
	readonly items = observable.array([] as API.PushCampaign[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('push');

	@observable totalCount = 0;

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.pushCampaignGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState.sort,
				sortDir: this.gridState.sortDir,
			});
			return () => {
				this.items.replace(data.items);
				this.totalCount = data.total;
			};
		});
	};

	async getOne(id: number | undefined): Promise<PushCampaignCreateDto | API.PushCampaign> {
		if (id) {
			return this.async.exec(() =>
				this.api.pushCampaignGetOne(id).then(resp => ({
					...resp.data,
					sendAt: dayjs(resp.data.sendAt).add(-dayjs().utcOffset(), 'minute').toDate(),
				})),
			);
		} else {
			const rv = new PushCampaignCreateDto();
			rv.sendAt = dayjs().add(1, 'day').toDate();
			return rv;
		}
	}

	save(id: number | undefined, data: PushCampaignCreateDto) {
		return this.async.exec(async () => {
			data.sendAt = new Date(
				Date.UTC(
					data.sendAt.getFullYear(),
					data.sendAt.getMonth(),
					data.sendAt.getDate(),
					data.sendAt.getHours(),
					data.sendAt.getMinutes(),
				),
			);
			if (id) {
				await this.api.pushCampaignUpdate(id, data);
			} else {
				await this.api.pushCampaignCreate(data);
			}
		});
	}

	stop(id: number) {
		return this.async.call(async () => {
			this.api.pushCampaignStop(id);
		});
	}

	delete = (id: number) => {
		return this.async.exec(() => this.api.pushCampaignDelete(id)).then(this.reload);
	};
}
