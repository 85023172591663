import { validateSync } from 'class-validator';
import { singleton } from 'tsyringe';
import { field } from '~/shared/fields';

@singleton()
export class EnvService {
	@field.String({ min: 1 })
	AUTH_GOOGLE_CLIENTID = process.env.APP_AUTH_GOOGLE_CLIENTID!;

	@field.String({ min: 1 })
	API_URL = process.env.APP_API_URL!;

	@field.String({ min: 1 })
	PAGE_TITLE = process.env.APP_PAGE_TITLE!;

	@field.String({ min: 1 })
	ENV = process.env.APP_ENV! as 'production' | 'development' | 'staging';

	constructor() {
		setTimeout(() => {
			validateSync(this).forEach(e => console.error(e.toString()));
		}, 0);
	}
}
