import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Virtuoso } from 'react-virtuoso';
import { AppRoutes } from '~/app/app.routes';
import { FragranceExperienceModel } from '~/model/model.fragrance.experience';
import { UIService } from '~/service/service.ui';
import { FragranceId } from '~/shared/dto/id';
import { useModel, useService } from '~/shared/service/service.base';

export const FragranceExperiencesDialog = observer<{ fragranceId: FragranceId }>(({ fragranceId }) => {
	const [ui] = useService(UIService);
	const model = useModel(FragranceExperienceModel);
	model.use(fragranceId);

	return (
		<Dialog open={ui.dialog === FragranceExperiencesDialog}>
			<DialogTitle>Users have this fragrance on their shelves:</DialogTitle>
			<DialogContent>
				<List sx={{ height: '50vh' }}>
					<Virtuoso
						data={model.data}
						itemContent={(_, item) => (
							<ListItemButton
								key={item.userId}
								href={AppRoutes.UserEdit.buildPath({ id: item.userId })}
								target="_blank"
							>
								{item.userNickName}
							</ListItemButton>
						)}
					/>
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={ui.hideDialog}>Close</Button>
			</DialogActions>
		</Dialog>
	);
});
