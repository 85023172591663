import { field } from '../../fields';
import { UserRole, UserStatus } from '../model.enums';

export class UserChangeStatusDto {
	@field.Enum({ UserStatus })
	status!: UserStatus;
}

export class UserChangeCommentDto {
	@field.String({ trim: true, max: 1024 })
	comment!: string;
}

export class UserChangeRoleDto {
	@field.Enum({ UserRole })
	role!: UserRole;
}

export class UserMessageDto {
	@field.String({ min: 1, max: 100, trim: true })
	title!: string;

	@field.String({ min: 1, max: 500, trim: true })
	message!: string;

	@field.String({ optional: true })
	description?: string;

	@field.String({ optional: true, regex: /^[a-zA-Z0-9-_.~%]{1,50}$/ })
	analyticsLabel?: string;

	@field.String({ optional: true })
	imgLeft?: string;

	@field.String({ optional: true })
	imgRight?: string;
}
