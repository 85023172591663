import { action, makeObservable, observable } from 'mobx';
import { useEffect } from 'react';
import { API } from '~/api';
import { FragranceId } from '~/api/api.declaration';
import { ApiService } from '~/service/service.api';
import { Model } from '~/shared/service/service.base';
import { Async } from '~/shared/tools/async';

@Model()
export class FragranceExperienceModel {
	@observable offset = 0;
	@observable data = [] as API.FragranceExperience[];
	readonly limit = 20;
	readonly async = new Async();
	private fragranceId?: FragranceId;

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	use = (fragranceId: FragranceId) => {
		this.fragranceId = fragranceId;
		useEffect(() => {
			this.fetchNextPage();
		}, []);
	};

	fetchNextPage = () => {
		this.async.call(async () => {
			if (this.fragranceId) {
				const { data } = await this.api.fragranceGetExperiences(this.fragranceId, {
					limit: this.limit,
					offset: this.offset,
				});
				return () => {
					this.data = [...this.data, ...data.items];
					this.offset += this.limit;
				};
			}
		});
	};

	@action.bound reload() {
		this.offset = 0;
		this.fetchNextPage();
	}
}
