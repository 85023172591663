import { debounce } from '@mui/material';
import { action, makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

export type RecommendSet = 'fragrances' | 'newbies' | 'initiated';

@Singleton()
export class RecommendsService implements Record<RecommendSet, object> {
	readonly async = new Async();

	fragrances = observable.array<API.FragranceItem>([], { deep: false });
	newbies = observable.array<API.FragranceItem>([], { deep: false });
	initiated = observable.array<API.FragranceItem>([], { deep: false });
	@observable filter?: string = undefined;
	@observable private filterQuery?: string = undefined;
	@observable filterMenuOpen = false;
	@observable sort?: 'views' | 'experiences' = undefined;

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	reload = () => {
		this.reloadFragrances();
		this.async.call(async () => {
			const { data } = await this.api.recommendsGetLists();
			return () => {
				this.initiated.replace(data.initiated);
				this.newbies.replace(data.newbies);
			};
		});
	};

	@action.bound filterMenuToggle() {
		this.filterMenuOpen = !this.filterMenuOpen;
	}

	@action.bound sortMostViewed() {
		this.sort = 'views';
		this.filterMenuOpen = false;
		this.reloadFragrances();
	}

	@action.bound sortMostExperienced() {
		this.sort = 'experiences';
		this.filterMenuOpen = false;
		this.reloadFragrances();
	}

	@action.bound resetFilterSort() {
		this.sort = undefined;
		this.filterMenuOpen = false;
		this.filterQuery = '';
		this.reloadFragrances();
	}

	@action.bound drop(from: RecommendSet, to: RecommendSet | null, fromIdx: number, toIdx: number | undefined | null) {
		if (to === null || toIdx === undefined || toIdx === null) {
			from !== 'fragrances' && this[from].splice(fromIdx, 1);
		} else {
			const item = this[from][fromIdx];
			if (!item) return;

			if (from === to) {
				if (from !== 'fragrances') {
					this[to].splice(fromIdx, 1);
					this[to].splice(toIdx, 0, item);
				}
			} else {
				if (!this[to].some(f => f.id === item.id)) {
					this[to].splice(toIdx, 0, item);
				}
			}
		}
	}

	@action.bound setFilter(filter: string | undefined) {
		this.filter = filter;
		this.setFilterQuery(filter);
	}

	@action.bound resetFilter() {
		this.filter = undefined;
		this.filterQuery = undefined;
	}

	save = () => {
		this.async.call(async () => {
			await this.api.recommendsSaveLists({
				initiated: this.initiated.map(i => i.id),
				newbies: this.newbies.map(i => i.id),
			});
		});
	};

	private setFilterQuery = debounce(
		action((filterQuery: string | undefined) => {
			this.filterQuery = filterQuery;
			this.reloadFragrances();
		}),
		2000,
	);

	private reloadFragrances = () => {
		this.async.call(async () => {
			const { data } = await this.api.fragranceGetAll({
				limit: 20,
				offset: 0,
				strategy: 'FTS_V5',
				filter: this.filterQuery,
				sort: this.sort,
				sortDir: 'DESC',
			});
			return () => {
				this.fragrances.replace(data.items);
			};
		});
	};
}
