import { debounce } from '@mui/material';
import { action, makeObservable, observable } from 'mobx';
import { useEffect } from 'react';
import { API } from '~/api';
import { ApiService } from '~/service/service.api';
import { Model } from '~/shared/service/service.base';
import { Async } from '~/shared/tools/async';

@Model()
export class FragranceListModel {
	@observable offset = 0;
	@observable data = [] as API.FragranceItem[];
	@observable filter = '';

	readonly limit = 20;
	readonly async = new Async();

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	use = () => {
		useEffect(() => {
			this.fetchNextPage();
		}, []);
	};

	fetchNextPage = () => {
		this.async.call(async () => {
			const { data } = await this.api.fragranceGetAll({
				limit: this.limit,
				offset: this.offset,
				filter: this.filter,
				strategy: 'FTS_V5',
			});
			return () => {
				this.data = [...this.data, ...data.items];
				this.offset += this.limit;
			};
		});
	};

	@action.bound reload() {
		this.offset = 0;
		this.data = [];
		this.fetchNextPage();
	}

	setFilter = debounce(
		action((filter: string) => {
			if (!this.async.loading && this.filter !== filter) {
				this.filter = filter;
				this.reload();
			}
		}),
		2000,
	);
}
