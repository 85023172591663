import { FTSStrategy } from '~/api/api.declaration';
import { Singleton } from '~/shared/service/service.base';

interface LocalStorageStruct {
	fragranceSearchStrategy: FTSStrategy;
	brandSearchStrategy: FTSStrategy;
}

@Singleton()
export class LocalStorageService {
	setItem<K extends keyof LocalStorageStruct>(key: K, value: LocalStorageStruct[K]) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	getEnum<E extends object>(key: keyof LocalStorageStruct, enumType: E, defaultValue: keyof E): keyof E {
		const value = this.getItem(key);
		if (value && Object.keys(enumType).includes(value)) {
			return value as keyof E;
		}
		return defaultValue;
	}

	getItem<K extends keyof LocalStorageStruct>(key: K): LocalStorageStruct[K] | undefined;
	getItem<K extends keyof LocalStorageStruct>(key: K, defaultValue: LocalStorageStruct[K]): LocalStorageStruct[K];
	getItem<K extends keyof LocalStorageStruct>(
		key: K,
		defaultValue?: LocalStorageStruct[K],
	): LocalStorageStruct[K] | undefined {
		const value = localStorage.getItem(key);
		if (value !== null) {
			try {
				return JSON.parse(value);
			} catch (e) {
				console.warn('Error load from localStorage key ' + key, e);
			}
		}
		return defaultValue;
	}
}
