import { Paper } from '@mui/material';
import { Async } from '~/shared/tools/async';
import { AsyncView } from './view.async';
import { BoxProps, Col, FCC } from './view.box';

export const EditContainer: FCC<{ async: Async }> = ({ children, async }) => {
	return (
		<AsyncView async={async}>
			<Paper
				elevation={1}
				sx={{ px: 4, pt: 8, pb: 4, flexDirection: 'row', display: 'flex', gap: '24px', maxWidth: '90vw' }}
			>
				{children}
			</Paper>
		</AsyncView>
	);
};

export const EditLeft: FCC<BoxProps> = ({ children, ...props }) => {
	return (
		<Col justifyContent="flex-start" mr={4}>
			<Col justifyContent={'flex-start'} gap={2} position="sticky" top={80} {...props}>
				{children}
			</Col>
		</Col>
	);
};

export const EditCenter: FCC = ({ children }) => {
	return (
		<Col justifyContent="flex-start" flexGrow={1} gap={2} minWidth={600}>
			<Col justifyContent={'flex-start'} gap={2} position="sticky" top={80}>
				{children}
			</Col>
		</Col>
	);
};

export const EditRight: FCC = ({ children }) => {
	return (
		<Col justifyContent="flex-start" gap={2} minWidth={180}>
			<Col justifyContent="flex-start" gap={2} position="sticky" top={80}>
				{children}
			</Col>
		</Col>
	);
};
