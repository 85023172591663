import { TextField, TextFieldProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Form } from '~/shared/form/form';

export const FieldNumber = observer(
	<T extends number | null | undefined>({ field, ...props }: { field: Form.Field<T> } & TextFieldProps) => {
		return (
			<TextField
				fullWidth
				type="number"
				error={field.hasError}
				label={field.label}
				onChange={e => field.set(e.target.value ? (e.target.value as any) : undefined)}
				value={field.value !== undefined && field.value !== null ? field.value.toString() : ''}
				helperText={field.helperText}
				{...props}
			/>
		);
	},
);

export const FieldNumberArray = observer(
	<T extends number>({ field, ...props }: { field: Form.Field<T[]> } & TextFieldProps) => {
		return (
			<TextField
				fullWidth
				error={!!field.hasError}
				multiline
				rows={8}
				label={field.label}
				onChange={e =>
					field.set(
						e.target.value
							.split('\n')
							.map(x => +x)
							.filter(isFinite) as T[],
					)
				}
				value={field.value?.join('\n') ?? ''}
				helperText={field.helperText}
				{...props}
			/>
		);
	},
);
