import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { CSSProperties, DependencyList, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthService } from '~/service/service.auth';
import { UIService } from '~/service/service.ui';
import { PermissionKey } from '~/shared/dto/admin/staff.dto';
import { useService } from '~/shared/service/service.base';
import { Col, Row, Text } from '~/view/view.box';

export type GridColumn<T extends GridValidRowModel> = Omit<GridColDef, 'field'> & {
	field: Extract<keyof T, string> | `$${string}`;
	renderCell?: (params: GridRenderCellParams<T>) => JSX.Element;
};

export function useGridColumns<T extends GridValidRowModel>(
	factory: () => (GridColumn<T> | undefined)[],
	deps?: DependencyList,
): GridColumn<T>[] {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => factory().filter((i): i is GridColumn<T> => !!i), deps ?? []);
}

export function AvatarColumn<T extends GridValidRowModel>(
	def: GridColumn<T> & { fallbackImage?: string; tooltip?: (row: T) => string | undefined },
): GridColumn<T> {
	return {
		headerName: '',
		sortable: false,
		width: 64,
		filterable: false,
		renderCell: ({ value, row }) => {
			const tooltip = def.tooltip?.(row);
			return (
				<Tooltip title={tooltip ? <pre>{tooltip}</pre> : ''} placement="top">
					<Avatar src={(Array.isArray(value) ? value[0] : value) || def.fallbackImage} />
				</Tooltip>
			);
		},
		...def,
	};
}

export function DateTimeColumn<T extends GridValidRowModel>(
	def: GridColumn<T> & { treatAsLocalTime?: boolean },
): GridColumn<T> {
	return {
		width: 100,
		filterable: false,
		renderCell: ({ value }) => {
			let dt = dayjs(value);
			if (def.treatAsLocalTime) {
				dt = dt.add(-dt.utcOffset(), 'minute');
			}
			return (
				<Col alignItems="center">
					{dt.isValid() ? (
						<>
							<Text variant="body2">{dt.format('YYYY-MM-DD')}</Text>
							<Text variant="caption">{dt.format('HH:mm:ss')}</Text>
						</>
					) : (
						'-'
					)}
				</Col>
			);
		},
		...def,
	};
}

export function SecondaryColumn<T extends GridValidRowModel>(
	def: GridColumn<T> & { secondary: (row: T) => string; align?: CSSProperties['alignItems'] },
): GridColumn<T> {
	return {
		filterable: false,
		renderCell: ({ value, row }) => {
			return (
				<Col alignItems={def.align ?? 'center'}>
					<Text variant="body2">{value}</Text>
					<Text variant="caption">{def.secondary(row)}</Text>
				</Col>
			);
		},
		...def,
	};
}

export const EmailLink = styled.a({
	textDecoration: 'underline',
});

export function EmailColumn<T extends GridValidRowModel>(
	def: GridColumn<T> & { displayField?: keyof T },
): GridColumn<T> {
	return {
		width: 200,
		filterable: true,
		renderCell: ({ value, row }) => {
			return (
				<EmailLink href={`mailto:${value}`} target="_blank">
					{def.displayField && row[def.displayField] ? row[def.displayField] : value}
				</EmailLink>
			);
		},
		...def,
	};
}

export function LinkColumn<T extends GridValidRowModel>(
	def: GridColumn<T> & { getLink: (row: T) => string },
): GridColumn<T> {
	return {
		width: 200,
		filterable: true,
		renderCell: ({ value, row }) => {
			return (
				<EmailLink href={def.getLink(row)} target="_blank">
					{value}
				</EmailLink>
			);
		},
		...def,
	};
}

export function CheckedColumn<T extends GridValidRowModel>(def: GridColumn<T>): GridColumn<T> {
	return {
		width: 32,
		filterable: false,
		renderCell: ({ value }) => {
			return value ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />;
		},
		...def,
	};
}

const EnumCell = ({ enumName, value }: { enumName: string; value: string }) => {
	const [tr] = useTranslation(['dto']);
	return <>{value ? tr(`${enumName}.${value}`) : '-'}</>;
};

export function EnumColumn<T extends GridValidRowModel>(enumName: string, def: GridColumn<T>): GridColumn<T> {
	return {
		width: 32,
		filterable: false,
		renderCell: ({ value }) => <EnumCell enumName={enumName} value={value} />,
		...def,
	};
}

export function ActionsColumn<T extends GridValidRowModel>({
	onDelete,
	editRoute,
	permission,
	extra,
	...definition
}: {
	onDelete?: (id: number) => Promise<any>;
	editRoute: null | ((arg: T) => string);
	permission: PermissionKey;
	extra?: (r: T) => React.ReactNode;
} & Partial<GridColumn<T>>) {
	const Cell: FC<GridRenderCellParams<T>> = ({ row }) => {
		const [ui, auth] = useService(UIService, AuthService);
		const doDelete = useCallback(() => {
			ui.confirmDelete(() => onDelete?.(row.id).then(() => ui.notify('Deleted successfully', 'success')));
		}, [row.id, ui]);

		return (
			<Row>
				{editRoute && (
					<Link to={editRoute(row)}>
						<IconButton>{auth.can(permission, 'UPDATE') ? <EditIcon /> : <VisibilityIcon />}</IconButton>
					</Link>
				)}
				{onDelete && (
					<IconButton disabled={!auth.can(permission, 'DELETE')} onClick={doDelete}>
						<DeleteIcon />
					</IconButton>
				)}
				{extra?.(row)}
			</Row>
		);
	};
	return {
		field: '$Actions' as '$Actions',
		headerName: '',
		filterable: false,
		hideable: false,
		sortable: false,
		disableColumnMenu: true,
		...definition,
		renderCell: (params: GridRenderCellParams) => <Cell {...params} />,
	};
}

export interface ActionSet<T> {
	label: string;
	icon: React.ComponentType;
	visible?: (row: T) => boolean;
	disabled?: (row: T) => boolean;
	onClick: (row: T) => void;
}

export function ActionSetColumn<T extends GridValidRowModel>({
	actions,
	...definition
}: {
	actions: ActionSet<T>[];
} & Partial<GridColumn<T>>) {
	const Cell: FC<GridRenderCellParams<T>> = ({ row }) => {
		return (
			<Row>
				{actions
					.filter(a => !a.visible || a?.visible(row))
					.map((action, i) => (
						<Tooltip key={i} title={action.label}>
							<IconButton
								disabled={action.disabled?.(row)}
								onClick={() => action.onClick(row)}
								size="small"
							>
								<action.icon />
							</IconButton>
						</Tooltip>
					))}
			</Row>
		);
	};

	return {
		field: '$Actions' as '$Actions',
		headerName: '',
		filterable: false,
		hideable: false,
		sortable: false,
		disableColumnMenu: true,
		...definition,
		renderCell: (params: GridRenderCellParams) => <Cell {...params} />,
	};
}
