import { Alert, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';

export const NotificationView = observer(() => {
	const [ui] = useService(UIService);

	return (
		<Snackbar
			anchorOrigin={{ horizontal: 'center', vertical: ui.notification?.vertical || 'bottom' }}
			open={!!ui.notification?.visible}
			autoHideDuration={5000}
			onClose={ui.clearNotification}
		>
			<Alert severity={ui.notification?.severity}>{ui.notification?.message}</Alert>
		</Snackbar>
	);
});
