import styled from '@emotion/styled';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Async } from '~/shared/tools/async';

const Loading = styled.div({
	background: '#ffffff80',
	top: 0,
	left: 0,
	position: 'absolute',
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	overflow: 'hidden',
});

export const AsyncView = observer<
	PropsWithChildren<{ async: Pick<Async, 'loading' | 'error'>; replace?: boolean; withoutLoading?: boolean }>
>(({ async: { loading, error }, children, replace, withoutLoading }) => {
	if (error) {
		console.error(error);
	}
	let err: string | undefined;
	if (error instanceof AxiosError) {
		err = error.response?.data?.message ?? error.message;
	} else {
		err = typeof error === 'string' ? error : error?.message;
	}
	const [tr] = useTranslation(['errors']);
	return (
		<>
			{!replace ? children : false}
			{loading && !withoutLoading ? (
				<Loading>
					<CircularProgress />
				</Loading>
			) : (
				false
			)}
			<Snackbar
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				open={!!error}
				autoHideDuration={6000}
			>
				<Alert severity="error" sx={{ width: '100%' }}>
					{err && tr(err)}
				</Alert>
			</Snackbar>
		</>
	);
});
