import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/system';
import { FC, PropsWithChildren, useMemo } from 'react';

export interface BoxProps extends MuiBoxProps {
    overflowX?: CSSProperties['overflowX'];
}

export function Box(props: BoxProps) {
    const sx = useMemo(() => ({ ...props.sx, overflowX: props.overflowX }), [props.sx, props.overflowX]);
    return <MuiBox display="flex" flexDirection="column" justifyContent="center" {...props} sx={sx}></MuiBox>;
}

export function Col(props: BoxProps) {
    return <Box display="flex" flexDirection="column" justifyContent="center" {...props}></Box>;
}

export function Row(props: BoxProps) {
    return <Box display="flex" flexDirection="row" alignItems="center" {...props}></Box>;
}

export function Spacer(props: BoxProps) {
    return <Box flex="1 0" {...props}></Box>;
}

export type FCC<T extends object = {}> = FC<PropsWithChildren & T>;

export const Text = Typography;

export const Title = styled('div')({
    flex: 1,
    textAlign: 'left',
    fontWeight: 'bold',
    marginRight: 10,
    whiteSpace: 'nowrap',
});

export const Value = styled('div')({
    flex: 1,
    textAlign: 'right',
    whiteSpace: 'nowrap',
});
