import { Expose, Transform, Type } from 'class-transformer';
import {
	IsBoolean,
	IsDate,
	IsDateString,
	IsEnum,
	IsInt,
	IsNegative,
	IsNumber,
	IsObject,
	IsOptional,
	IsPositive,
	IsString,
	IsUrl,
	Matches,
	Max,
	MaxLength,
	Min,
	MinLength,
	ValidateIf,
	ValidateNested,
} from 'class-validator';
import { L, O, U } from 'ts-toolbelt';
import * as ValidatorJS from 'validator';
// eslint-disable-next-line no-eval
const swagger = eval('try {require("@nestjs/swagger")} catch {}');
const ApiProperty: Function | undefined = swagger ? swagger.ApiProperty : undefined;

function TrimTransform() {
	return Transform(({ value }) =>
		Array.isArray(value)
			? value.map(v => (typeof v === 'string' ? v.trim() : v))
			: typeof value === 'string'
			? value.trim()
			: value,
	);
}

function CaseTransform(toCase: 'lower' | 'upper') {
	const transform = (str: string | null | undefined) =>
		typeof str === 'string' ? (toCase === 'lower' ? str.toLowerCase() : str.toUpperCase()) : str;
	return Transform(({ value }) => (Array.isArray(value) ? value.map(transform) : transform(value)));
}

export namespace field {
	export type NumberOpts = Options & {
		min?: number;
		max?: number;
		finite?: boolean;
		int?: boolean;
		positive?: boolean;
		negative?: boolean;
	};

	export function Number(opts?: NumberOpts) {
		return (target: any, key: string) => {
			const decors = getDefaults({ type: 'number', ...opts });
			const each = opts?.array;
			if (opts?.int) {
				decors.push(IsInt({ each }));
			} else {
				const finite = opts?.finite !== false;
				decors.push(IsNumber({ allowNaN: !finite, allowInfinity: !finite }, { each }));
			}
			if (opts?.positive) {
				decors.push(IsPositive({ each }));
			}
			if (opts?.negative) {
				decors.push(IsNegative({ each }));
			}
			if (typeof opts?.max === 'number') {
				decors.push(Max(opts.max, { each }));
			}
			if (typeof opts?.min === 'number') {
				decors.push(Min(opts.min, { each }));
			}
			decors.push(Type(() => global.Number));
			applyDecorators(decors, opts, target, key);
		};
	}

	export function String(
		opts?: Options & {
			min?: number;
			max?: number;
			trim?: boolean;
			case?: 'lower' | 'upper';
			regex?: RegExp;
		},
	) {
		return (target: any, key: string) => {
			const decors = getDefaults({ type: 'string', ...opts });
			const each = opts?.array;
			decors.push(IsString({ each }));

			if (typeof opts?.max === 'number') {
				decors.push(MaxLength(opts.max, { each }));
			}
			if (typeof opts?.min === 'number') {
				decors.push(MinLength(opts.min, { each }));
			}
			if (opts?.trim) {
				decors.push(TrimTransform());
			}
			if (opts?.case) {
				decors.push(CaseTransform(opts.case));
			}
			if (opts?.regex) {
				decors.push(Matches(opts.regex, { each, message: 'ERR_REGEX' }));
			}
			applyDecorators(decors, opts, target, key);
		};
	}

	export function Url(
		opts?: ValidatorJS.IsURLOptions &
			Options & {
				min?: number;
				max?: number;
			},
	) {
		return String({
			max: 255,
			trim: true,
			...opts,
			decors: [IsUrl(opts, { each: opts?.array })],
		});
	}

	export function File(opts?: Options) {
		return (target: any, targetKey: string) => {
			const decors = getDefaults({ type: 'boolean', apiOverride: { type: 'string', format: 'binary' }, ...opts });
			applyDecorators([...decors, Transform(({ obj, key }) => obj[key])], opts, target, targetKey);
		};
	}

	export function Enum<E extends object>(
		e: E,
		opts?: Options,
	): L.Length<U.ListOf<keyof E>> extends 1 ? PropertyDecorator : 'MUST field.Enum({ YourEnumType }, opts?)' {
		const [enumName, enm] = global.Object.entries(e)[0];
		return String({
			min: 1,
			max: 255,
			trim: true,
			...opts,
			apiOverride: opts?.array
				? {
						isArray: undefined,
						type: 'array',
						items: {
							enum: global.Object.keys(enm).filter(k => isNaN(+k)),
							type: 'string',
						},
				  }
				: {
						enum: global.Object.keys(enm).filter(k => isNaN(+k)),
						enumName,
						type: undefined,
				  },
			decors: [IsEnum(enm, { each: opts?.array })],
		}) as any;
	}

	export function Bool(opts?: Options & { convertString?: boolean }) {
		return (target: any, targetKey: string) => {
			const decors = getDefaults({ type: 'boolean', ...opts });
			if (opts?.convertString) {
				const trueValues = new Set(['true', '1', 'enabled', true, 1]);
				decors.push(
					Transform(({ obj, key }) => {
						const value = obj[key];
						return Array.isArray(value) ? value.map(v => trueValues.has(v)) : trueValues.has(value);
					}),
				);
			}
			decors.push(IsBoolean());
			applyDecorators(decors, opts, target, targetKey);
		};
	}

	export function Obj<T extends object>(type: new () => T, opts?: Options) {
		return (target: any, key: string) => {
			const decors = getDefaults({ ...opts, type });

			const each = opts?.array;
			if ((type as any) !== Object) decors.push(ValidateNested({ each }));
			decors.push(Type(() => type));
			decors.push(IsObject({ each }));
			applyDecorators(decors, opts, target, key);
		};
	}

	export function DateTime(opts?: Options) {
		return (target: any, key: string) => {
			const decors = getDefaults({ ...opts, type: Date });
			const each = opts?.array;
			decors.push(IsDate({ each }));
			applyDecorators(decors, opts, target, key);
		};
	}

	export function DateString(opts?: Options & ValidatorJS.IsISO8601Options) {
		return (target: any, key: string) => {
			const decors = getDefaults({ ...opts, type: Date });
			const each = opts?.array;
			decors.push(IsDateString(opts, { each }));
			applyDecorators(decors, opts, target, key);
		};
	}

	export function UUID(opts?: Options) {
		return String({ min: 36, max: 36, case: 'lower', trim: true, ...opts });
	}

	function applyDecorators(decors: PropertyDecorator[], opts: Options | undefined, target: any, key: string) {
		decors.forEach(d => d(target, key));
		opts?.decors?.forEach(d => d(target, key));
	}

	function getDefaults(opts: O.Required<Options, 'type'>) {
		const decors: PropertyDecorator[] = [Expose()];
		if (opts.optional || opts.maybe) {
			decors.push(IsOptional());
		}
		if (opts.nullable || opts.maybe) {
			decors.push(ValidateIf((object, value) => value !== null));
		}
		if (ApiProperty) {
			decors.push(
				ApiProperty({
					type: opts.type,
					required: !opts.optional && !opts.maybe,
					nullable: opts.nullable || opts.maybe,
					isArray: opts.array,
					...opts.apiOverride,
				}),
			);
		}
		decors.push(addPropertyKey(opts.type));

		return decors;
	}

	export interface Options {
		optional?: true;
		array?: boolean;
		maybe?: boolean;
		nullable?: boolean;
		decors?: PropertyDecorator[];
		type?: string | (new () => object);
		apiOverride?: object;
	}
}

function addPropertyKey(type: any) {
	return function (target: any, key: string | symbol) {
		if (typeof key === 'string') {
			const keys = target.constructor.__keys || (target.constructor.__keys = new Map());
			keys.set(key, type);
		}
	};
}
