import { makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { GridStateModel } from '~/model/model.gridstate';
import { NoteCreateDto } from '~/shared/dto/admin/note.dto';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

@Singleton()
export class NoteService {
	readonly items = observable.array([] as API.Note[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('notes');

	@observable totalCount = 0;
	@observable.ref names: API.NoteName[] = [];

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.noteGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState.sort,
				sortDir: this.gridState.sortDir,
			});
			return () => {
				this.items.replace(data.items);
				this.totalCount = data.total;
			};
		});
	};

	async getOne(id: number | undefined) {
		if (id === undefined) return;
		return this.async.exec(() => this.api.noteGetOne(id).then(resp => resp.data));
	}

	save(id: number | undefined, data: NoteCreateDto) {
		return this.async.exec(async () => {
			await this.api.uploadImgs(data.images);
			if (id === undefined) {
				await this.api.noteCreate(data);
			} else {
				await this.api.noteUpdate(id, data);
			}
		});
	}

	delete = (id: number) => {
		return this.async.exec(() => this.api.noteDelete(id)).then(this.reload);
	};

	fetchNames = () => {
		this.async.call(async () => {
			const { data } = await this.api.noteGetNames();
			return () => (this.names = data.items);
		});
	};
}
