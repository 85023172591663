import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { SettingsService } from '~/service/service.settings';
import { UIService } from '~/service/service.ui';
import { StopWordsDto } from '~/shared/dto/admin/settings.dto';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FieldTextArray } from '~/view/fields/field.textarray';
import { Row } from '~/view/view.box';
import { EditCenter, EditContainer, EditRight } from '~/view/view.edit';

export const StopWordsPage = observer(() => {
	const [ui, auth, settingsSvc] = useService(UIService, AuthService, SettingsService);
	const navBack = useNavBack();

	const form = Form.use(StopWordsDto, {
		name: 'StopWordsDto',
		onSubmit: dto => settingsSvc.saveStopWords(dto),
		initial: () => settingsSvc.getStopWords(),
	});

	const reload = useCallback(() => {
		settingsSvc.getStopWords().then(f => f && form.apply(f));
	}, [form, settingsSvc]);

	ui.usePageTitle('Settings');
	const canSave = auth.can('settings', 'UPDATE');

	return (
		<EditContainer async={settingsSvc.async}>
			<EditCenter>
				<Row gap={2}>
					<FieldTextArray variant="outlined" field={form.$swear} rows={20} />
					<FieldTextArray variant="outlined" field={form.$reserved} rows={20} />
				</Row>
			</EditCenter>
			<EditRight>
				<Button variant="contained" onClick={() => form.submit()?.then(reload)} disabled={!canSave}>
					Save
				</Button>
				<br />
				<Button variant="outlined" onClick={navBack}>
					Cancel
				</Button>
				<Button variant="outlined" onClick={form.reset}>
					Reset
				</Button>
			</EditRight>
		</EditContainer>
	);
});
