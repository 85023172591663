import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { PerfumerService } from '~/service/service.perfumer';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import { ActionsColumn, AvatarColumn, CheckedColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';

export const PerfumerListPage = observer(() => {
	const [perfumers, ui, auth] = useService(PerfumerService, UIService, AuthService);
	const columns = useGridColumns<API.Perfumer>(() => [
		AvatarColumn({
			field: 'images',
			headerName: 'Photo',
		}),
		CheckedColumn({ field: 'active', headerName: 'Active' }),
		{ field: 'id', headerName: 'ID' },
		{ field: 'name', headerName: 'Name', minWidth: 200 },
		{ field: 'company', headerName: 'Company', minWidth: 200 },
		{ field: 'description', headerName: 'Description', minWidth: 200, flex: 1 },
		ActionsColumn({
			editRoute: AppRoutes.PerfumerEdit.buildPath,
			onDelete: perfumers.delete,
			permission: 'perfumers',
		}),
	]);

	ui.usePageTitle('Perfumers');
	useEffect(() => perfumers.loadAndSubscribe(), [perfumers]);

	return (
		<GridContainer async={perfumers.async}>
			<DataGrid
				initialState={perfumers.gridState.initial()}
				loading={perfumers.async.loading}
				rowCount={perfumers.totalCount}
				rows={perfumers.items.slice()}
				columns={columns}
				disableColumnFilter
				paginationMode="server"
				sortingMode="server"
				onStateChange={perfumers.gridState.onStateChange}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No perfumers found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={perfumers.reload}
							gridState={perfumers.gridState}
							onCreateUrl={
								auth.can('perfumers', 'CREATE') ? AppRoutes.PerfumerNew.buildPath({}) : undefined
							}
							exportEnabled={auth.can('perfumers', 'EXPORT')}
						/>
					),
				}}
			/>
		</GridContainer>
	);
});
