import { AppBar, Avatar, Toolbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { AuthService } from '~/service/service.auth';
import { EnvService } from '~/service/service.env';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import pkg from '../package.json';
import { Col, Row, Text } from './view.box';
import { MainMenu } from './view.mainmenu';

export const AppBarView = observer(() => {
	const [auth, ui, env] = useService(AuthService, UIService, EnvService);

	return (
		<AppBar>
			<Toolbar sx={{ alignItems: 'stretch' }}>
				<Row sx={{ flexGrow: 1 }} justifyContent="flex-start">
					<Text variant="h6">{ui.pageTitle || env.PAGE_TITLE}</Text>
					<Col position="absolute" bottom={2}>
						<Text variant="caption">v{pkg.version}</Text>
					</Col>
				</Row>
				<MainMenu />
				{auth.me?.avatar && (
					<Col alignItems="center" ml={1}>
						<Avatar src={auth.me?.avatar} />
						<Text variant="caption">{auth.me?.name}</Text>
					</Col>
				)}
			</Toolbar>
		</AppBar>
	);
});
