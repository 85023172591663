import styled from '@emotion/styled';
import { Checklist } from '@mui/icons-material';
import {
	Button,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	SxProps,
	TextField,
} from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useRef } from 'react';
import { DragDropContext, Draggable, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';
import { API } from '~/api';
import { useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { RecommendSet, RecommendsService } from '~/service/service.recommends';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import { Row, Text } from '~/view/view.box';
import { EditCenter, EditContainer, EditRight } from '~/view/view.edit';

export const RecommendsPage = observer(() => {
	const [ui, auth, recommendsSvc] = useService(UIService, AuthService, RecommendsService);
	const navBack = useNavBack();
	const refMenuBtn = useRef<HTMLButtonElement>(null);

	ui.usePageTitle('Recommends');
	const canSave = auth.can('settings', 'UPDATE');
	useEffect(() => recommendsSvc.reload(), [recommendsSvc]);

	return (
		<EditContainer async={recommendsSvc.async}>
			<EditCenter>
				<DragDropContext
					onDragEnd={e => {
						console.log(e);
						recommendsSvc.drop(
							e.source.droppableId as RecommendSet,
							e.destination?.droppableId as RecommendSet,
							e.source.index,
							e.destination?.index,
						);
					}}
				>
					<Row flex={1} gap={2} alignItems="stretch">
						<Paper sx={sxColPaper}>
							<Row alignItems="center" justifyItems="center" mb={2}>
								<TextField
									fullWidth
									label="Find fragrance"
									value={recommendsSvc.filter ?? ''}
									onChange={e => recommendsSvc.setFilter(e.target.value)}
									InputProps={{
										endAdornment: (
											<IconButton ref={refMenuBtn} onClick={recommendsSvc.filterMenuToggle}>
												<Checklist color={recommendsSvc.sort ? 'info' : undefined} />
											</IconButton>
										),
									}}
								/>
							</Row>
							<DroppableFragList
								droppableId="fragrances"
								data={recommendsSvc.fragrances}
								disableReorder
							/>
						</Paper>
						<Paper sx={sxColPaper}>
							<Text align="center">Newbies ({recommendsSvc.newbies.length})</Text>
							<DroppableFragList droppableId="newbies" data={recommendsSvc.newbies} />
						</Paper>
						<Paper sx={sxColPaper}>
							<Text align="center">Initiated ({recommendsSvc.initiated.length})</Text>
							<DroppableFragList droppableId="initiated" data={recommendsSvc.initiated} />
						</Paper>
					</Row>
				</DragDropContext>
				<Menu
					anchorEl={refMenuBtn.current}
					open={recommendsSvc.filterMenuOpen}
					onClose={recommendsSvc.filterMenuToggle}
				>
					<MenuItem
						sx={{ color: recommendsSvc.sort ? undefined : 'info.main' }}
						onClick={recommendsSvc.resetFilterSort}
					>
						All fragrances
					</MenuItem>
					<MenuItem
						sx={{ color: recommendsSvc.sort === 'experiences' ? 'info.main' : undefined }}
						onClick={recommendsSvc.sortMostExperienced}
					>
						Most added to shelves
					</MenuItem>
					<MenuItem
						sx={{ color: recommendsSvc.sort === 'views' ? 'info.main' : undefined }}
						onClick={recommendsSvc.sortMostViewed}
					>
						Most viewed
					</MenuItem>
				</Menu>
			</EditCenter>
			<EditRight>
				<Button variant="contained" onClick={recommendsSvc.save} disabled={!canSave}>
					Save
				</Button>
				<br />
				<Button variant="outlined" onClick={navBack}>
					Cancel
				</Button>
				<Button variant="outlined" onClick={recommendsSvc.reload}>
					Reset
				</Button>
			</EditRight>
		</EditContainer>
	);
});

const sxColPaper: SxProps = {
	flex: 1,
	p: 2,
	width: '20vw',
	display: 'flex',
	flexDirection: 'column',
};

const DivDroppable = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	gap: 4,
	flex: 1,
});

const DroppableFragList = observer<{ droppableId: string; data: API.FragranceItem[]; disableReorder?: boolean }>(
	({ droppableId, data, disableReorder }) => {
		data = toJS(data);

		return (
			<Droppable droppableId={droppableId}>
				{(provided, snapshot) => (
					<DivDroppable
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={{ backgroundColor: snapshot.isDraggingOver && !disableReorder ? '#eeeeee' : undefined }}
					>
						{data.map((frag, idx) => (
							<Draggable key={frag.id} index={idx} draggableId={`${droppableId}_${frag.id.toString()}`}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={getDraggbleStyle(
											provided.draggableProps.style,
											snapshot,
											!!disableReorder,
											droppableId,
										)}
									>
										<Paper>
											<ListItem>
												<ListItemAvatar>
													<img src={frag.photo} height={48} alt={frag.name} />
												</ListItemAvatar>
												<ListItemText
													primary={frag.name}
													secondary={frag.brandName}
													primaryTypographyProps={{ noWrap: true }}
													secondaryTypographyProps={{ noWrap: true }}
												/>
											</ListItem>
										</Paper>
									</div>
								)}
							</Draggable>
						))}
						<span
							style={{
								display: 'none',
							}}
						>
							{provided.placeholder}
						</span>
					</DivDroppable>
				)}
			</Droppable>
		);
	},
);

function getDraggbleStyle(
	style: CSSProperties | undefined,
	{ isDragging, draggingOver }: DraggableStateSnapshot,
	disableReorder: boolean,
	droppableId: string,
): CSSProperties | undefined {
	// if (disableReorder) {
	// 	if (!snapshot.isDragging) return {};
	// 	if (!snapshot.isDropAnimating) return style;
	// 	return {
	// 		...style,
	// 		transitionDuration: `0.001s`,
	// 	};
	// }
	return {
		...style,
		...(isDragging && {
			pointerEvents: 'auto',
			cursor:
				draggingOver === null || draggingOver === 'fragrances'
					? 'no-drop'
					: draggingOver === droppableId
						? 'ns-resize'
						: 'copy',
		}),
	};
}
