import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { O } from 'ts-toolbelt';
import { Form } from '~/shared/form/form';
import en from './en';

type EN = typeof en;

export const i18n = i18next.createInstance();

i18n.use(initReactI18next)
	.init({
		lng: 'en',
		resources: { en },
		debug: true,
		interpolation: {
			escapeValue: false,
		},
		react: {
			bindI18n: 'languageChanged',
			bindI18nStore: '',
			transEmptyNodeValue: '',
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
			useSuspense: true,
		},
	})
	.then(() => {
		Object.assign(Form.defaultOptions, {
			trNames: i18n.getFixedT(null, 'dto'),
			trValidation: i18n.getFixedT(null, 'validation'),
		});
	});

export type Tr<T extends Record<string, string | number>> = string & { __tr: T };

export interface TrFunc<T extends object> {
	<K extends O.SelectKeys<T, string, 'equals'>>(key: K): T;
	<K extends O.SelectKeys<T, { __tr: any }>>(key: K, params: T[K] extends { __tr: infer P } ? P : {}): T;
}

export function useTr<N extends keyof EN>(ns: N[]): TrFunc<EN[N]> {
	return useTranslation(ns)[0] as any;
}
