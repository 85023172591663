import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { NoteService } from '~/service/service.note';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import { ActionsColumn, AvatarColumn, CheckedColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';

export const NoteListPage = observer(() => {
	const [notes, ui, auth] = useService(NoteService, UIService, AuthService);
	const columns = useGridColumns<API.NoteItem>(() => [
		AvatarColumn({
			field: 'image',
			headerName: 'Image',
		}),
		{ field: 'id', headerName: 'ID' },
		CheckedColumn({ field: 'active', headerName: 'Active' }),
		{ field: 'name', headerName: 'Name', minWidth: 200 },
		{ field: 'synonyms', headerName: 'Synonyms', minWidth: 200, flex: 1 },
		ActionsColumn({
			editRoute: AppRoutes.NoteEdit.buildPath,
			onDelete: notes.delete,
			permission: 'notes',
		}),
	]);

	ui.usePageTitle('Notes');
	useEffect(() => notes.loadAndSubscribe(), [notes]);

	return (
		<GridContainer async={notes.async}>
			<DataGrid
				initialState={notes.gridState.initial()}
				loading={notes.async.loading}
				rowCount={notes.totalCount}
				rows={notes.items.slice()}
				columns={columns}
				disableColumnFilter
				paginationMode="server"
				sortingMode="server"
				onStateChange={notes.gridState.onStateChange}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No notes found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={notes.reload}
							gridState={notes.gridState}
							onCreateUrl={auth.can('notes', 'CREATE') ? AppRoutes.NoteNew.buildPath({}) : undefined}
							exportEnabled={auth.can('notes', 'EXPORT')}
						/>
					),
				}}
			/>
		</GridContainer>
	);
});
