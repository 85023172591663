import { CreateNewFolder } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import ReloadIcon from '@mui/icons-material/Refresh';
import { Button, IconButton, TextField } from '@mui/material';
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridStateModel } from '~/model/model.gridstate';
import { Row } from '../view.box';

export const GridToolbarView = observer<
	PropsWithChildren<{
		onReload?: () => void;
		onCreateUrl?: string;
		gridState?: GridStateModel;
		exportEnabled?: boolean;
		rightMenu?: JSX.Element;
		filterable?: boolean;
	}>
>(({ onReload, onCreateUrl, gridState, exportEnabled, children, rightMenu, filterable = true }) => {
	const nav = useNavigate();

	return (
		<GridToolbarContainer sx={{ justifyContent: 'space-between', minHeight: 64 }}>
			<Row pl={2}>
				{filterable && gridState && (
					<TextField
						size="small"
						value={gridState?.filter ?? ''}
						onChange={gridState?.changeFilter}
						label="Filter..."
						InputProps={{
							endAdornment: gridState?.filter && (
								<IconButton size="small" onClick={gridState.resetFilter}>
									<ClearIcon fontSize="small" />
								</IconButton>
							),
						}}
					/>
				)}
				{children}
			</Row>
			<Row>
				{onCreateUrl && (
					<Button size="small" startIcon={<CreateNewFolder />} onClick={() => nav(onCreateUrl)}>
						Create New
					</Button>
				)}
				{rightMenu}
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				{exportEnabled && <GridToolbarExport />}
				{onReload && (
					<Button size="small" startIcon={<ReloadIcon />} onClick={onReload}>
						Reload
					</Button>
				)}
			</Row>
		</GridToolbarContainer>
	);
});
