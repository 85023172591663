import { Enum, field } from '../../fields';
import { FragranceId } from '../id';
import { UserMessageDto } from './user.dto';

export const FeedbackStatus = Enum('NEW', 'DONE', 'DECLINED');
export type FeedbackStatus = Enum<typeof FeedbackStatus>;

export class FeedbackChangeStatusDto {
	@field.Enum({ FeedbackStatus })
	status!: FeedbackStatus;
}

export class FeedbackReplyDto extends UserMessageDto {
	@field.Enum({ FeedbackStatus }, { optional: true })
	status?: FeedbackStatus;

	@FragranceId({ optional: true })
	fragranceId?: FragranceId;
}
