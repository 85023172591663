import { LinearProgress, MenuItem, TextField } from '@mui/material';
import {
	DataGrid,
	GridCsvExportMenuItem,
	GridExportMenuItemProps,
	GridPrintExportMenuItem,
	GridToolbarExportContainer,
} from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { FTSStrategy } from '~/api/api.declaration';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { BrandService } from '~/service/service.brand';
import { UIService } from '~/service/service.ui';
import { Enum } from '~/shared/fields';
import { useService } from '~/shared/service/service.base';
import { ImportBrandDialog } from '~/view/dialog/dialog.import';
import { ActionsColumn, AvatarColumn, CheckedColumn, DateTimeColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';
import { Row } from '~/view/view.box';
import { PerformTimeView } from '~/view/view.perform.time';

export const BrandListPage = observer(() => {
	const [brands, ui, auth] = useService(BrandService, UIService, AuthService);
	const columns = useGridColumns<API.Brand>(() => [
		AvatarColumn({
			field: 'images',
			headerName: 'Logo',
			fallbackImage: 'https://cdn-icons-png.flaticon.com/512/5110/5110846.png',
			tooltip: row => row.relevance,
		}),
		CheckedColumn({ field: 'active', headerName: 'Active' }),
		{ field: 'id', headerName: 'ID' },
		{ field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
		{ field: 'country', headerName: 'Country' },
		{ field: 'year', headerName: 'Year' },
		DateTimeColumn({ field: 'createdAt', headerName: 'Created' }),
		DateTimeColumn({ field: 'updatedAt', headerName: 'Updated' }),
		ActionsColumn({
			editRoute: AppRoutes.BrandEdit.buildPath,
			onDelete: brands.delete,
			permission: 'brand',
		}),
	]);

	ui.usePageTitle('Brands');
	useEffect(() => brands.loadAndSubscribe(), [brands]);

	return (
		<GridContainer async={brands.async}>
			<DataGrid
				initialState={brands.gridState.initial()}
				loading={brands.async.loading}
				rowCount={brands.totalCount}
				rows={brands.items.slice()}
				columns={columns}
				disableColumnFilter
				density={brands.gridState.density}
				paginationMode="server"
				sortingMode="server"
				onStateChange={brands.gridState.onStateChange}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No brands found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={brands.reload}
							gridState={brands.gridState}
							onCreateUrl={auth.can('brand', 'CREATE') ? AppRoutes.BrandNew.buildPath({}) : undefined}
							exportEnabled={false}
							rightMenu={auth.can('brand', 'EXPORT') ? <GridToolbarExport /> : undefined}
						>
							<Row ml={4} minWidth={200}>
								<TextField
									select
									fullWidth
									label="Search strategy"
									value={brands.searchStrategy}
									onChange={brands.changeSearchStrategy}
								>
									{Enum.keys(FTSStrategy).map(s => (
										<MenuItem key={s} value={s}>
											{s}
										</MenuItem>
									))}
								</TextField>
							</Row>
						</GridToolbarView>
					),
				}}
			/>
			<PerformTimeView time={brands.searchTime} />
			<ImportBrandDialog />
		</GridContainer>
	);
});

function ImportCSVMenuItem(props: GridExportMenuItemProps<{}>) {
	const [ui] = useService(UIService);
	const { hideMenu } = props;

	return (
		<MenuItem
			onClick={() => {
				hideMenu?.();
				ui.showDialog(ImportBrandDialog);
			}}
		>
			Import from CSV
		</MenuItem>
	);
}

function GridToolbarExport({ csvOptions, printOptions, ...other }: any) {
	return (
		<GridToolbarExportContainer {...other}>
			<GridCsvExportMenuItem options={csvOptions} />
			<GridPrintExportMenuItem options={printOptions} />
			<ImportCSVMenuItem />
		</GridToolbarExportContainer>
	);
}
