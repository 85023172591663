import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Form } from '~/shared/form/form';

export const FieldEnum: <T extends Record<string, string>>(
	props: {
		enumeration: T;
		field: Form.Field<keyof T> | Form.Field<keyof T | undefined>;
		enumName?: string;
		nullLabel?: string;
	} & TextFieldProps,
) => JSX.Element = observer(({ field, enumeration, enumName, nullLabel, ...props }) => {
	const [tr] = useTranslation(['dto']);

	return (
		<TextField
			fullWidth
			select
			error={!!field.hasError}
			label={field.label}
			onChange={e => (e.target.value || nullLabel !== undefined ? field.onChange(e) : field.set(null))}
			value={field.value ?? ''}
			helperText={field.helperText}
			{...props}
		>
			{nullLabel !== undefined && <MenuItem value={''}>{nullLabel}&nbsp;</MenuItem>}
			{Object.keys(enumeration).map(key => (
				<MenuItem key={key} value={key}>
					{tr(`${enumName ? enumName + '.' : ''}${key}`)}
				</MenuItem>
			))}
		</TextField>
	);
});

export const FieldMultiEnum: <T extends Record<string, string>>(
	props: {
		enumeration: T;
		field: Form.Field<(keyof T)[]> | Form.Field<(keyof T)[] | undefined>;
	} & TextFieldProps,
) => JSX.Element = observer(({ field, enumeration, ...props }) => {
	return (
		<TextField
			fullWidth
			select
			SelectProps={{
				multiple: true,
			}}
			error={!!field.hasError}
			label={field.label}
			onChange={field.onChange}
			value={field.value ? toJS(field.value) : []}
			helperText={field.helperText}
			{...props}
		>
			{Object.keys(enumeration).map(key => (
				<MenuItem key={key} value={key}>
					{key}
				</MenuItem>
			))}
		</TextField>
	);
});
