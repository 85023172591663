import { observable } from 'mobx';
import { singleton } from 'tsyringe';
import { API } from '~/api';
import { GridStateModel } from '~/model/model.gridstate';
import { StaffUpdateDto } from '~/shared/dto/admin/staff.dto';
import { Async } from '~/shared/tools/async';
import { ApiService } from './service.api';

@singleton()
export class StaffService {
	readonly data = observable.array([] as API.Staff[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('staff');

	constructor(private api: ApiService) {}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.staffGetAll();
			return () => this.data.replace(data.items);
		});
	};

	toggleStatus(id: number) {
		this.async.call(async () => {
			const staff = this.data.find(x => x.id === id);
			if (!staff) return;
			const status = staff.status === 'OK' ? 'BAN' : 'OK';
			await this.api.staffChangeStatus(id, { status });
			return () => {
				const index = this.data.findIndex(x => x.id === id);
				if (index >= 0) {
					this.data[index] = { ...this.data[index], status };
				}
			};
		});
	}

	async getOne(id: number | undefined) {
		if (id === undefined) return;
		return this.async.exec(() => this.api.staffGetOne(id).then(resp => resp.data));
	}

	update(id: number | undefined, data: StaffUpdateDto) {
		return this.async.exec(async () => {
			if (id === undefined) return;
			await this.api.staffUpdate(id, data);
		});
	}

	delete = (id: number) => {
		return this.async.exec(() => this.api.staffDelete(id)).then(this.reload);
	};
}
