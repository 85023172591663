import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { UIService } from '~/service/service.ui';
import { UserService } from '~/service/service.user';
import { useService } from '~/shared/service/service.base';
import { ActionsColumn, AvatarColumn, DateTimeColumn, EmailColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';

export const UserListPage = observer(() => {
	const [userSvc, ui, auth] = useService(UserService, UIService, AuthService);
	const columns = useGridColumns<API.UserItem>(() => [
		AvatarColumn({
			field: 'avatar',
			headerName: 'Avatar',
		}),
		{ field: 'id', headerName: 'ID' },
		{ field: 'nickName', headerName: 'Name', minWidth: 200 },
		auth.can('user', 'EMAIL') ? EmailColumn({ field: 'email', headerName: 'Email' }) : undefined,
		DateTimeColumn({ field: 'createdAt', headerName: 'Created at' }),
		DateTimeColumn({ field: 'lastSeenAt', headerName: 'Last seen at' }),
		{ field: 'oauthProvider', headerName: 'Auth', minWidth: 100 },
		{ field: 'role', headerName: 'Role', minWidth: 100 },
		{ field: 'status', headerName: 'Status', minWidth: 100 },
		{ field: 'postCount', headerName: 'Posts', minWidth: 100, sortable: false },
		{ field: 'experienceCount', headerName: 'Experiences', minWidth: 100, sortable: false },
		{ field: 'comment', headerName: 'Comment', flex: 1 },
		ActionsColumn({
			editRoute: AppRoutes.UserEdit.buildPath,
			permission: 'user',
		}),
	]);

	ui.usePageTitle('Users');
	useEffect(() => userSvc.loadAndSubscribe(), [userSvc]);

	return (
		<GridContainer async={userSvc.async}>
			<DataGrid
				initialState={userSvc.gridState.initial()}
				loading={userSvc.async.loading}
				rowCount={userSvc.totalCount}
				rows={userSvc.items.slice()}
				columns={columns}
				disableColumnFilter
				paginationMode="server"
				sortingMode="server"
				onStateChange={userSvc.gridState.onStateChange}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No users found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={userSvc.reload}
							gridState={userSvc.gridState}
							exportEnabled={auth.can('user', 'EXPORT')}
						/>
					),
				}}
			/>
		</GridContainer>
	);
});
