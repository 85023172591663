import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Form } from '~/shared/form/form';

export const FieldDate = Form.field<Date, DateTimePickerProps<Dayjs> & {}>(({ field, ...props }) => {
	return (
		<DateTimePicker
			label={field.label}
			value={field.value ? dayjs(field.value) : null}
			ampm={false}
			format="YYYY-MM-DD HH:mm"
			slotProps={{
				actionBar: {
					actions: ['clear'],
				},
			}}
			onChange={newValue => field.set(newValue as any)}
			{...props}
		/>
	);
});
