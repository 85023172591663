import { ValidateIf } from 'class-validator';
import { PartialType } from 'nestjs-mapped-types';
import { Enum, field } from '../../fields';
import {
	BrandId,
	FragranceId,
	FragranceLinkId,
	FragrancePhotoId,
	IngredientId,
	NoteId,
	OlfactiveFamilyId,
	PerfumerId,
	UserId,
} from '../id';
import {
	ComplaintType,
	Concentration,
	Gender,
	Longevity,
	ModerationStatus,
	Occasion,
	Season,
	Sillage,
	Weather,
} from '../model.enums';

export const FragranceLinkType = Enum('ARTICLE', 'VIDEO', 'INTERNAL');
export type FragranceLinkType = Enum<typeof FragranceLinkType>;

export const ASINType = Enum('PRIMARY', 'VARIATION', 'MANUAL', 'POTENTIAL', 'BLACKLIST');
export type ASINType = Enum<typeof ASINType>;

export class FragrancePhotoDto {
	@FragrancePhotoId({ optional: true })
	id?: FragrancePhotoId;

	@field.String({ optional: true })
	authorName?: string;

	@UserId({ optional: true })
	authorId?: UserId;

	@field.String({ optional: true })
	caption?: string;

	@field.String()
	@ValidateIf(o => !!o.id)
	fileId!: string;

	@field.String()
	url!: string;

	@field.String({ optional: true })
	authorUser?: string;

	@field.String({ optional: true })
	complainedByUser?: string;

	@field.Enum({ ComplaintType }, { optional: true })
	complaintStatus?: ComplaintType;
}

export class FragranceLinkDto {
	@FragranceLinkId({ optional: true })
	id?: FragranceLinkId;

	@field.String({ min: 1, max: 200 })
	title!: string;

	@field.String({ max: 200 })
	subtitle!: string;

	@field.Url()
	url!: string;

	@field.Enum({ FragranceLinkType })
	type!: FragranceLinkType;
}

export class FragranceASINDto {
	@field.Number({ optional: true })
	id?: number;

	@field.Enum({ ASINType })
	type!: ASINType;

	@field.String()
	asin!: string;
}

export class FragranceUpdateDto {
	@field.String()
	name!: string;

	@BrandId()
	brandId!: BrandId;

	@field.String({ optional: true })
	description?: string;

	@field.String({ array: true, optional: true })
	synonyms?: string[];

	@field.Obj(FragrancePhotoDto, { array: true, optional: true })
	publicPhotos?: FragrancePhotoDto[];

	@field.Obj(FragrancePhotoDto, { array: true, optional: true })
	privatePhotos?: FragrancePhotoDto[];

	@field.Obj(FragrancePhotoDto, { array: true, optional: true })
	hiddenPhotos?: FragrancePhotoDto[];

	@field.Obj(FragrancePhotoDto, { array: true, optional: true })
	preliminaryPhotos?: FragrancePhotoDto[];

	@field.Number({ optional: true, array: true, int: true, min: 1800, max: 2500 })
	years?: number[];

	@PerfumerId({ array: true, optional: true })
	perfumers?: PerfumerId[];

	@field.String({ optional: true })
	comment?: string;

	@field.Bool({ optional: true })
	active?: boolean;

	@field.Bool({ optional: true })
	discontinued?: boolean;

	@NoteId({ array: true, optional: true })
	brandTopNotes?: NoteId[];

	@NoteId({ array: true, optional: true })
	brandMidNotes?: NoteId[];

	@NoteId({ array: true, optional: true })
	brandBaseNotes?: NoteId[];

	@NoteId({ array: true, optional: true })
	noseNotes?: NoteId[];

	@IngredientId({ array: true, optional: true })
	ingredients?: IngredientId[];

	@OlfactiveFamilyId({ optional: true })
	olfactiveId?: OlfactiveFamilyId;

	@field.Enum({ Gender }, { maybe: true })
	gender?: Gender;

	@field.Enum({ Sillage }, { maybe: true })
	sillage?: Sillage;

	@field.Enum({ Longevity }, { maybe: true })
	longevity?: Longevity;

	@field.Enum({ Sillage }, { maybe: true })
	noseSillage?: Sillage;

	@field.Enum({ Longevity }, { maybe: true })
	noseLongevity?: Longevity;

	@field.Enum({ Concentration }, { maybe: true })
	noseConcentration?: Concentration;

	@field.Enum({ Concentration }, { maybe: true })
	concentration?: Concentration;

	@field.Enum({ Occasion }, { maybe: true, array: true })
	noseOccasion?: Occasion[];

	@field.Enum({ Weather }, { maybe: true, array: true })
	noseWeather?: Weather[];

	@field.Enum({ Season }, { maybe: true, array: true })
	noseSeason?: Season[];

	@FragranceId({ array: true, optional: true })
	noseRemindsFragrances?: FragranceId[];

	@field.Obj(FragranceLinkDto, { array: true })
	links: FragranceLinkDto[] = [];

	@field.Obj(FragranceASINDto, { array: true })
	asin: FragranceASINDto[] = [];
}

export class FragranceCreateDto extends FragranceUpdateDto {
	@FragranceId({ optional: true })
	id?: FragranceId;
}

export class FragranceImportDto extends PartialType(FragranceCreateDto) {}

export class FragranceImport {
	@field.Obj(FragranceImportDto, { array: true })
	items!: FragranceImportDto[];

	@field.String()
	fileName!: string;
}

export class FragranceSetSuperDto {
	@FragranceId({ nullable: true })
	superFragranceId!: FragranceId | null;
}

export class FragranceChangePhotoComplaintDto {
	@field.Bool()
	clear!: boolean;

	@field.String()
	fileId!: string;
}

export class FragranceReviewStatusDto {
	@field.Enum({ ModerationStatus })
	status!: ModerationStatus;
}
