import { field } from '../../fields';
import { FragranceId, UserId } from '../id';
import { ModerationStatus } from '../model.enums';

export class PostModerationDto {
	@field.Enum({ ModerationStatus })
	moderationStatus!: ModerationStatus;
}

export class PostUpdateDto {
	@field.DateTime({ optional: true })
	publishAt?: Date;

	@field.Number({ min: 0, max: 5, optional: true })
	rating?: number;

	@field.String({ min: 0, max: 2200, optional: true, trim: true })
	text?: string;

	@FragranceId()
	fragranceId!: FragranceId;

	@field.Number({ min: -100, max: 100, optional: true })
	temperature?: number;

	@field.String({ optional: true, trim: true })
	country?: string;

	@field.String({ optional: true, trim: true })
	city?: string;

	@field.String({ optional: true, trim: true })
	weather?: string;
}

export class PostCreateDto extends PostUpdateDto {
	@UserId()
	userId!: UserId;
}
