import { Dialog, DialogContent } from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { observer } from 'mobx-react-lite';
import { AuthService } from '~/service/service.auth';
import { EnvService } from '~/service/service.env';
import { useService } from '~/shared/service/service.base';
import { AsyncView } from '~/view/view.async';
import { Text } from '~/view/view.box';

export const SignInPage = observer(() => {
	const [env, auth] = useService(EnvService, AuthService);

	return (
		<Dialog open={true} PaperProps={{ sx: { backgroundColor: '#FDEFE8' } }}>
			<DialogContent sx={{ alignContent: 'center', justifyContent: 'center' }}>
				<AsyncView async={auth.async}>
					<img alt="Logo" src="/img/logo.webp" style={{ flex: '0 0 auto' }} />
					<Text align="center">Aromoshelf Admin Panel</Text>
					<br />
					<GoogleOAuthProvider clientId={env.AUTH_GOOGLE_CLIENTID}>
						<GoogleLogin
							onSuccess={credentialResponse => {
								credentialResponse.credential && auth.signInWithGoogle(credentialResponse.credential);
							}}
							size="large"
							width={250}
							useOneTap
							cancel_on_tap_outside={false}
						/>
					</GoogleOAuthProvider>
				</AsyncView>
			</DialogContent>
		</Dialog>
	);
});
