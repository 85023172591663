import { SettingsDto, StopWordsDto } from '~/shared/dto/admin/settings.dto';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

@Singleton()
export class SettingsService {
	readonly async = new Async();

	constructor(private api: ApiService) {}

	async getSettings() {
		return this.async.exec(() => this.api.settingsGet().then(resp => resp.data));
	}

	saveSettings(data: SettingsDto) {
		return this.async.exec(async () => {
			data.imgBrandBig = await this.api.uploadImg(data.imgBrandBig);
			data.imgBrandSmall = await this.api.uploadImg(data.imgBrandSmall);
			data.imgFragrance = await this.api.uploadImg(data.imgFragrance);
			data.imgIngredient = await this.api.uploadImg(data.imgIngredient);
			data.imgNote = await this.api.uploadImg(data.imgNote);
			data.imgPerfumer = await this.api.uploadImg(data.imgPerfumer);
			data.imgUser = await this.api.uploadImg(data.imgUser);
			await this.api.settingsSet(data);
		});
	}

	async getStopWords() {
		return this.async.exec(() => this.api.stopWordsGet().then(resp => resp.data));
	}

	async saveStopWords(dto: StopWordsDto) {
		return this.async.exec(() => this.api.stopWordsSave(dto));
	}
}
