import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Virtuoso } from 'react-virtuoso';
import { FragranceOffersModel } from '~/model/model.fragrance.offers';
import { UIService } from '~/service/service.ui';
import { FragranceId } from '~/shared/dto/id';
import { useModel, useService } from '~/shared/service/service.base';

export const FragranceOffersDialog = observer<{ fragranceId: FragranceId }>(({ fragranceId }) => {
	const [ui] = useService(UIService);
	const model = useModel(FragranceOffersModel);
	model.use(fragranceId);

	return (
		<Dialog open={ui.dialog === FragranceOffersDialog} PaperProps={{ sx: { maxWidth: '100vw' } }}>
			<DialogTitle>Offers from affiliate partners:</DialogTitle>
			<DialogContent>
				<List sx={{ height: '50vh', width: '70vw' }}>
					<Virtuoso
						data={model.data}
						itemContent={(_, item) => (
							<ListItemButton key={item.url} href={item.url} target="_blank">
								<ListItemAvatar>
									<Avatar src={item.image}></Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={item.title}
									secondary={`${item.price} ${item.currency}${
										item.savingsAmount
											? ` (Savings: ${item.savingsAmount} ${item.currency}, ${item.savingsPercent}%)`
											: ''
									} from ${item.merchant}; Valid until: ${dayjs(item.validUntil).format()}`}
								/>
							</ListItemButton>
						)}
					/>
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={ui.hideDialog}>Close</Button>
			</DialogActions>
		</Dialog>
	);
});
