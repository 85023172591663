import { action, makeObservable, observable, runInAction } from 'mobx';
import { useEffect, useMemo } from 'react';
import { O } from 'ts-toolbelt';

type AsyncWorker = (async: Async) => Promise<void | undefined | (() => void)>;

export interface IAsync {
	readonly loading?: boolean;
	readonly error?: Error;

	reset(): void;
}

export class Async implements IAsync {
	@observable loading: boolean = false;
	@observable error?: Error = undefined;
	@observable time = 0;

	//	@LazyGetter()
	get controller() {
		return new AbortController();
	}

	constructor() {
		makeObservable(this);
	}

	@action.bound reset() {
		this.loading = false;
		this.error = undefined;
	}

	call(worker: AsyncWorker) {
		const self = this as O.Writable<Async>;
		let doneHandler = undefined as undefined | (() => void);
		const startAt = Date.now();
		runInAction(() => {
			self.loading = true;
			self.error = undefined;
			self.time = 0;
		});
		worker(self).then(
			action((finalizer: void | undefined | (() => void)) => {
				finalizer?.();
				self.loading = false;
				self.time = Date.now() - startAt;
				doneHandler?.();
			}),
			action((err: any) => {
				self.error = err;
				self.loading = false;
				self.time = Date.now() - startAt;
			}),
		);
		return {
			done: (handler: () => void) => {
				doneHandler = handler;
			},
		};
	}

	exec<T>(worker: () => Promise<T>) {
		const self = this as O.Writable<Async>;
		runInAction(() => {
			self.loading = true;
			self.error = undefined;
		});
		return worker().then(
			action(result => {
				self.loading = false;
				return result;
			}),
			action((err: any) => {
				self.error = err;
				self.loading = false;
				throw err;
			}),
		);
	}

	useOnce = (worker: AsyncWorker) => {
		useEffect(() => {
			this.call(worker);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);
	};
}

export function useAsyncEffect(effect: () => Promise<void>, deps: React.DependencyList) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const async = useMemo(() => new Async(), deps);
	// TODO: cancel on unmount
	useEffect(() => {
		async.call(effect);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
	return async;
}
