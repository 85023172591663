import { field } from '../../fields';
import { NoteId } from '../id';

export class NoteUpdateDto {
	@field.String({ trim: true, min: 1 })
	name!: string;

	@field.String({ array: true })
	synonyms!: string[];

	@field.String({ array: true, optional: true })
	images?: string[];

	@field.Bool({ optional: true })
	active?: boolean;

	@field.String({ optional: true })
	comment?: string;
}

export class NoteCreateDto extends NoteUpdateDto {
	@NoteId()
	id!: NoteId;
}
