import { Button, Menu, MenuItem, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { UIService } from '~/service/service.ui';
import { PermissionKey } from '~/shared/dto/admin/staff.dto';
import { useService } from '~/shared/service/service.base';
import { Text } from './view.box';

interface MenuDescriptor {
	title: string;
	route?: string;
	permission?: PermissionKey;
	submenu?: readonly MenuDescriptor[];
}

const menu: MenuDescriptor[] = [
	{
		title: 'BRANDS',
		route: AppRoutes.BrandList.buildPath({}),
		permission: 'brand',
	},
	{
		title: 'FRAGRANCES',
		route: AppRoutes.FragranceList.buildPath({}),
		permission: 'fragrance',
	},
	{
		title: 'LIBRARY',
		submenu: [
			{
				title: 'PERFUMERS',
				route: AppRoutes.PerfumerList.buildPath({}),
				permission: 'perfumers',
			},
			{
				title: 'NOTES',
				route: AppRoutes.NoteList.buildPath({}),
				permission: 'notes',
			},
			{
				title: 'INGREDIENTS',
				route: AppRoutes.IngredientList.buildPath({}),
				permission: 'notes',
			},
		],
	},
	{
		title: 'COMMUNITY',
		submenu: [
			{
				title: 'FEEDBACK',
				route: AppRoutes.FeedbackList.buildPath({}),
				permission: 'feedback',
			},
			{
				title: 'PUSH',
				route: AppRoutes.PushList.buildPath({}),
				permission: 'push',
			},
			{
				title: 'USERS',
				route: AppRoutes.UserList.buildPath({}),
				permission: 'user',
			},
			{
				title: 'SOTD',
				route: AppRoutes.PostList.buildPath({}),
				permission: 'post',
			},
		],
	},
	{
		title: 'SYSTEM',
		submenu: [
			{
				title: 'CHANGELOG',
				route: AppRoutes.ChangeLog.buildPath({}),
				permission: 'staff',
			},
			{
				title: 'STAFF',
				route: AppRoutes.StaffList.buildPath({}),
				permission: 'staff',
			},
			{
				title: 'SNAPSHOT',
				route: AppRoutes.SnapshotList.buildPath({}),
				permission: 'snapshot',
			},
			{
				title: 'SETTINGS',
				route: AppRoutes.Settings.buildPath({}),
				permission: 'settings',
			},
			{
				title: 'STOPWORDS',
				route: AppRoutes.StopWords.buildPath({}),
				permission: 'settings',
			},
			{
				title: 'RECOMMENDS',
				route: AppRoutes.Recommends.buildPath({}),
				permission: 'settings',
			},
		],
	},
];

export const MainMenu = observer(() => {
	const [auth] = useService(AuthService);
	const location = useLocation();

	return (
		<>
			{menu.map(item => {
				if (item.permission && !auth.can(item.permission, 'READ')) {
					return null;
				}

				if (item.submenu) {
					const selected = item.submenu.some(
						subitem => subitem.route && location.pathname.startsWith(subitem.route),
					);

					return (
						<SubMenu key={item.title} title={item.title} selected={selected}>
							{item.submenu.map(subitem => {
								if (subitem.permission && !auth.can(subitem.permission, 'READ')) {
									return null;
								}
								if (!subitem.route) return null;

								return (
									<SubMenuLink key={subitem.title} to={subitem.route}>
										{subitem.title}
									</SubMenuLink>
								);
							})}
						</SubMenu>
					);
				}

				return item.route ? (
					<MainMenuLink key={item.title} to={item.route}>
						{item.title}
					</MainMenuLink>
				) : null;
			})}
			<Button color="inherit" onClick={auth.tryLogout}>
				Logout
			</Button>
		</>
	);
});

const MainMenuLink = observer<PropsWithChildren<{ to: string }>>(({ to, children }) => {
	const location = useLocation();
	const selected = location.pathname.startsWith(to);
	const theme = useTheme();
	const [ui] = useService(UIService);

	return (
		<Link
			to={to}
			className={'mainMenuItem'}
			onClick={ui.hideMainSubMenu}
			style={selected ? { backgroundColor: theme.palette.primary.light } : undefined}
		>
			<Text variant="button">{children}</Text>
		</Link>
	);
});

const SubMenuLink = observer<PropsWithChildren<{ to: string }>>(({ to, children }) => {
	const location = useLocation();
	const selected = location.pathname.startsWith(to);
	const [ui] = useService(UIService);

	return (
		<Link to={to}>
			<MenuItem onClick={ui.hideMainSubMenu} selected={selected}>
				{children}
			</MenuItem>
		</Link>
	);
});

const SubMenu = observer<PropsWithChildren<{ title: string; selected?: boolean }>>(({ children, title, selected }) => {
	const anchor = useRef<HTMLButtonElement>(null);
	const theme = useTheme();
	const [ui] = useService(UIService);

	return (
		<>
			<Button
				variant="text"
				sx={{ color: 'white', backgroundColor: selected ? theme.palette.primary.light : undefined }}
				ref={anchor}
				onClick={() => ui.setMainSubMenu(title)}
			>
				{title}
			</Button>
			<Menu
				anchorEl={anchor.current}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ horizontal: 'center', vertical: 'top' }}
				open={ui.mainSubMenu === title}
				onClose={ui.hideMainSubMenu}
			>
				{children}
			</Menu>
		</>
	);
});
