import ClearIcon from '@mui/icons-material/Clear';
import { Chip, IconButton, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { Form } from '~/shared/form/form';
import { Row } from '../view.box';

export const FieldSelect: <T extends object, K extends keyof T>(
	props: {
		items: T[];
		valueProp: K;
		labelProp: keyof T;
		enabledProp?: keyof T;
		nullLabel?: string;
		field: Form.Field<T[K]> | Form.Field<T[K] | undefined>;
	} & TextFieldProps,
) => JSX.Element = observer(({ field, items, valueProp, labelProp, enabledProp, nullLabel, ...props }) => {
	return (
		<TextField
			fullWidth
			select
			error={!!field.hasError}
			label={field.label}
			onChange={e => (e.target.value ? field.onChange(e) : field.set(null))}
			value={items.length > 0 && field.value ? `${field.value}` : ''}
			helperText={field.helperText}
			{...props}
		>
			{nullLabel !== undefined && <MenuItem value={''}>{nullLabel}&nbsp;</MenuItem>}
			{items.map((item: any) => (
				<MenuItem
					key={item[valueProp]}
					disabled={enabledProp ? !item[enabledProp] : false}
					value={`${item[valueProp]}`}
				>
					{item[labelProp]}
				</MenuItem>
			))}
		</TextField>
	);
});

export const FieldMultiSelect: <T extends object, K extends keyof T>(
	props: {
		items: T[];
		valueProp: K;
		labelProp: keyof T;
		enabledProp?: keyof T;
		field: Form.Field<T[K][]> | Form.Field<T[K][] | undefined>;
	} & TextFieldProps,
) => JSX.Element = observer(({ field, items, valueProp, labelProp, enabledProp, ...props }) => {
	const renderValue = useCallback(
		(selected: any) => {
			return (
				<Row justifyContent="flex-start" flexWrap="wrap" gap={0.5}>
					{!!items.length &&
						selected.map((value: any) => (
							<Chip
								key={value}
								// eslint-disable-next-line eqeqeq
								label={items.find(i => i[valueProp] == value)?.[labelProp] as any}
								// eslint-disable-next-line eqeqeq
								onDelete={() => field.set(field.value?.filter(v => v != value) ?? [])}
								onMouseDown={event => {
									event.stopPropagation();
								}}
							/>
						))}
				</Row>
			);
		},
		[items, valueProp, labelProp, field],
	);

	return (
		<TextField
			fullWidth
			select
			SelectProps={{
				multiple: true,
				startAdornment:
					field.value && field.value?.length > 0 ? (
						<IconButton size="small" onClick={() => field.set([])}>
							<ClearIcon />
						</IconButton>
					) : undefined,
				renderValue,
			}}
			error={!!field.hasError}
			label={field.label}
			onChange={field.onChange}
			value={items.length > 0 ? toJS(field.value) ?? [] : []}
			helperText={field.helperText}
			{...props}
		>
			{/* // TODO: any */}
			{items.map((item: any) => (
				<MenuItem
					key={item[valueProp]}
					disabled={enabledProp ? !item[enabledProp] : false}
					value={`${item[valueProp]}`}
				>
					{item[labelProp]}
				</MenuItem>
			))}
		</TextField>
	);
});
