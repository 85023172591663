import { ClassConstructor, plainToInstance } from 'class-transformer';
import { A } from 'ts-toolbelt';

export * from './fields';

export function Enum<T extends string>(...values: T[]): { [P in T]: P } {
	return Object.fromEntries(values.map(s => [s, s])) as any;
}
export type Enum<T extends object> = keyof T;
export namespace Enum {
	export function keys<T extends object>(e: T) {
		return Object.keys(e) as (keyof T)[];
	}
	export function extend<T extends object, K extends string>(
		base: T,
		...values: K[]
	): A.Compute<{ [P in K]: P } & T> {
		return { ...base, ...Object.fromEntries(values.map(s => [s, s])) } as any;
	}
}

export type StrKey<T> = Extract<keyof T, string>;

export function plainToDTO<T>(dto: ClassConstructor<T>, plain: T) {
	return plainToInstance(dto, plain, {
		enableImplicitConversion: true,
		excludeExtraneousValues: true,
	});
}
