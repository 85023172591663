import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import BanIcon from '@mui/icons-material/RemoveCircle';
import UnBanIcon from '@mui/icons-material/RemoveCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, LinearProgress } from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { StaffService } from '~/service/service.staff';
import { UIService } from '~/service/service.ui';
import { PermissionAction } from '~/shared/dto/admin/staff.dto';
import { useService } from '~/shared/service/service.base';
import { AvatarColumn, DateTimeColumn, EmailColumn, GridColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';
import { FCC, Row } from '~/view/view.box';

export const StaffListPage = observer(() => {
	const [staff, ui] = useService(StaffService, UIService);
	const columns = useGridColumns<API.Staff>(() => [
		AvatarColumn({ field: 'avatar' }),
		EmailColumn({ field: 'email', headerName: 'Email' }),
		{ field: 'name', headerName: 'Name', width: 150 },
		{ field: 'status', headerName: 'Status', width: 60 },
		DateTimeColumn({ field: 'createdAt', headerName: 'Created' }),
		DateTimeColumn({ field: 'lastSeenAt', headerName: 'Seen' }),
		DateTimeColumn({ field: 'expiresAt', headerName: 'Expires' }),
		PermissionsColumn({ field: 'brandPermission', headerName: 'Brands' }),
		PermissionsColumn({ field: 'staffPermission', headerName: 'Staff' }),
		PermissionsColumn({ field: 'perfumersPermission', headerName: 'Perfumers' }),
		PermissionsColumn({ field: 'fragrancePermission', headerName: 'Fragrances' }),
		PermissionsColumn({ field: 'notesPermission', headerName: 'Notes' }),
		PermissionsColumn({ field: 'feedbackPermission', headerName: 'Feedback' }),
		{
			field: 'id',
			headerName: '',
			minWidth: 150,
			sortable: false,
			flex: 2,
			renderCell: params => <ActionsColumn {...params} />,
			align: 'right',
			disableColumnMenu: true,
			filterable: false,
			hideable: false,
		},
	]);

	ui.usePageTitle('Staff');
	useEffect(() => staff.loadAndSubscribe(), [staff]);

	return (
		<GridContainer async={staff.async}>
			<DataGrid
				initialState={staff.gridState.initial()}
				loading={staff.async.loading}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No staff found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => <GridToolbarView gridState={staff.gridState} onReload={staff.reload} />,
				}}
				rows={staff.data.slice()}
				columns={columns}
				disableColumnFilter
				onStateChange={staff.gridState.onStateChange}
			/>
		</GridContainer>
	);
});

const Permission = styled.span({
	borderRadius: 2,
	border: '1px solid #D0D0D0',
	color: '#D0D0D0',
	marginRight: 1,
	width: 16,
	height: 16,
	lineHeight: '16px',
	textAlign: 'center',
});

const stylePermissionOn: CSSProperties = {
	color: '#404040',
	borderColor: '#404040',
};

function PermissionsColumn(def: GridColumn<API.Staff>): GridColumn<API.Staff> {
	const Cell: FC<GridRenderCellParams> = ({ value }) => (
		<Row>
			{Object.keys(PermissionAction).map(k => (
				<Permission key={k} style={value?.includes(k) ? stylePermissionOn : undefined}>
					{k.slice(0, 1).toUpperCase()}
				</Permission>
			))}
		</Row>
	);

	return {
		width: 110,
		sortable: false,
		filterable: false,
		renderCell: params => <Cell {...params} />,
		...def,
	};
}

const ActionsColumn: FCC<GridRenderCellParams<API.Staff>> = ({ row }) => {
	const [staff, auth, ui] = useService(StaffService, AuthService, UIService);
	const doDelete = useCallback(() => {
		ui.confirmDelete(() => staff.delete(row.id).then(() => ui.notify('Deleted successfully', 'success')));
	}, [row.id, staff, ui]);

	return (
		<Row>
			<Link to={AppRoutes.StaffEdit.buildPath(row)}>
				<IconButton>{auth.can('staff', 'UPDATE') ? <EditIcon /> : <VisibilityIcon />}</IconButton>
			</Link>
			<IconButton
				disabled={row.id === auth.me?.id || !auth.can('staff', 'UPDATE')}
				onClick={() => staff.toggleStatus(row.id)}
			>
				{row.status === 'OK' ? <BanIcon /> : <UnBanIcon />}
			</IconButton>
			<IconButton onClick={doDelete} disabled={row.id === auth.me?.id || !auth.can('staff', 'DELETE')}>
				<DeleteIcon />
			</IconButton>
		</Row>
	);
};
