import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { PostService } from '~/service/service.post';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import { ActionsColumn, AvatarColumn, DateTimeColumn, SecondaryColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';

export const PostListPage = observer(() => {
	const [posts, ui, auth] = useService(PostService, UIService, AuthService);
	const columns = useGridColumns<API.PostItem>(() => [
		AvatarColumn({ field: 'image' }),
		{ field: 'id', headerName: 'ID' },
		SecondaryColumn({
			field: 'fragrance',
			headerName: 'Fragrance',
			secondary: r => r.brand,
			minWidth: 200,
			align: 'left',
		}),
		DateTimeColumn({ field: 'createdAt', headerName: 'Created At' }),
		DateTimeColumn({ field: 'publishAt', headerName: 'Publish At' }),
		{ field: 'user', headerName: 'User Name', minWidth: 200 },
		{ field: 'text', headerName: 'Text', flex: 1 },
		ActionsColumn({
			editRoute: AppRoutes.PostEdit.buildPath,
			permission: 'post',
		}),
	]);

	ui.usePageTitle('Posts');
	useEffect(() => posts.loadAndSubscribe(), [posts]);

	return (
		<GridContainer async={posts.async}>
			<DataGrid
				initialState={posts.gridState.initial()}
				loading={posts.async.loading}
				rowCount={posts.totalCount}
				rows={posts.items.slice()}
				columns={columns}
				disableColumnFilter
				paginationMode="server"
				sortingMode="server"
				onStateChange={posts.gridState.onStateChange}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No posts found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={posts.reload}
							gridState={posts.gridState}
							onCreateUrl={auth.can('post', 'CREATE') ? AppRoutes.PostNew.buildPath({}) : undefined}
							exportEnabled={auth.can('post', 'EXPORT')}
						/>
					),
				}}
			/>
		</GridContainer>
	);
});
