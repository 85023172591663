import { Enum, field } from '../../fields';
import { UserId } from '../id';

export const PushCampaignStatus = Enum('DRAFT', 'SCHEDULED', 'ONGOING', 'FINISHED', 'CANCELED');
export type PushCampaignStatus = Enum<typeof PushCampaignStatus>;

export class PushUserCondition {
	@UserId({ array: true })
	userIds!: UserId[];
}

export class PushExperienceRatingCondition {
	@field.Number({ min: 0, max: 100, int: true })
	percentOfSetRates!: number;

	@field.Bool({ optional: true })
	hasNoExperiences?: boolean;
}

export class PushAudienceConditions {
	@field.Obj(PushUserCondition, { optional: true })
	user?: PushUserCondition;

	@field.Obj(PushExperienceRatingCondition, { optional: true })
	experienceRating?: PushExperienceRatingCondition;
}

export class PushCampaignCreateDto {
	@field.String({ min: 1, max: 255 })
	campaignName!: string;

	@field.String({ min: 1, max: 255 })
	title!: string;

	@field.String({ min: 1, max: 512 })
	message!: string;

	@field.String({ optional: true, max: 1024 })
	description?: string;

	@field.String({ optional: true })
	imgLeft?: string;

	@field.String({ optional: true })
	imgRight?: string;

	@field.DateTime()
	sendAt!: Date;

	@field.Bool()
	sendPush = true;

	@field.Bool()
	sendNotice = true;

	@field.Bool()
	sound = true;

	@field.Bool()
	vibrate = true;

	@field.Bool()
	light = true;

	@field.Bool()
	badge = true;

	@field.Number({ maybe: true })
	pushTTL?: number | null;

	@field.Number({ maybe: true })
	noticeTTL?: number | null;

	@field.String({ regex: /^[a-zA-Z0-9-_.~%]{1,50}$/ })
	analyticsLabel!: string;

	@field.Enum({ PushCampaignStatus })
	status!: PushCampaignStatus;

	@field.Obj(PushAudienceConditions)
	audienceConditions = new PushAudienceConditions();
}

export class PushCampaignUpdateDto extends PushCampaignCreateDto {}
