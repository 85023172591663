import { OpenInBrowser } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import {
	Alert,
	Button,
	debounce,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Paper,
	Tab,
	Tabs,
	TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Fragrance, FragranceId, Gender, Longevity } from '~/api/api.declaration';
import { AppRoutes, useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { BrandService } from '~/service/service.brand';
import { FragranceService } from '~/service/service.fragrance';
import { IngredientService } from '~/service/service.ingredient';
import { NoteService } from '~/service/service.note';
import { OlfactiveService } from '~/service/service.olfactive';
import { PerfumerService } from '~/service/service.perfumer';
import { UIService } from '~/service/service.ui';
import { FragranceCreateDto } from '~/shared/dto/admin/fragrance.dto';
import { Concentration, Occasion, Season, Sillage, Weather } from '~/shared/dto/model.enums';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FragranceExperiencesDialog } from '~/view/dialog/dialog.fragrance.experiences';
import { FieldAutocomplete } from '~/view/fields/field.autocomplete';
import { FieldEnum, FieldMultiEnum } from '~/view/fields/field.enum';
import { FieldNumber } from '~/view/fields/field.number';
import { FieldMultiSelect, FieldSelect } from '~/view/fields/field.select';
import { FieldSwitch } from '~/view/fields/field.switch';
import { FieldText } from '~/view/fields/field.text';
import { FieldTextArray } from '~/view/fields/field.textarray';
import { Col, Row } from '~/view/view.box';
import { EditCenter, EditContainer, EditRight } from '~/view/view.edit';
import { FragranceAffiliate } from './fragrance.edit.affiliate';
import { FragranceLinks } from './fragrance.edit.links';
import { FragrancePhotos } from './fragrance.edit.photos';
import { FragranceEditReviews } from './fragrance.edit.reviews';

export const FragranceEditPage = observer(() => {
	const [ui, auth, fragrances, brands, olfactives, perfumers, notes, ingredients] = useService(
		UIService,
		AuthService,
		FragranceService,
		BrandService,
		OlfactiveService,
		PerfumerService,
		NoteService,
		IngredientService,
	);
	const navBack = useNavBack();
	const { id } = useTypedParams(AppRoutes.StaffEdit);
	const [tab, setTab] = useState('general' as 'general' | 'nose' | 'impressions' | 'clasped' | 'links' | 'affiliate');

	const form = Form.use(FragranceCreateDto, {
		name: 'FragranceCreateDto',
		onSubmit: dto =>
			fragrances.save(id, dto).then(() => {
				ui.notify('Fragrance saved successfully', 'success');
			}),
		initial: async () => {
			const fragrance = await fragrances.getOne(id);

			if (fragrance?.noseRemindsFragrances?.length) {
				const remindsFragrances = await fragrances.getByIds(fragrance.noseRemindsFragrances ?? [])
				setRemindsMeOptions(remindsFragrances)
			}
			return fragrance;
		},
	});

	const [remindsMeOptions, setRemindsMeOptions] = useState<Pick<Fragrance, 'id' | 'name'>[]>([]);

	useEffect(() => fragrances.setSuperId(form.initial?.superId), [form.initial?.superId, fragrances]);
	useEffect(() => brands.fetchNames(), [brands]);
	useEffect(() => olfactives.fetchNames(), [olfactives]);
	useEffect(() => perfumers.fetchNames(), [perfumers]);
	useEffect(() => notes.fetchNames(), [notes]);
	useEffect(() => ingredients.fetchNames(), [ingredients]);

	ui.usePageTitle(form.initial?.name ? `Fragrance - ${form.initial?.name}` : '');
	const canSave = id ? auth.can('fragrance', 'UPDATE') : auth.can('fragrance', 'CREATE');

	const reload = useCallback(() => {
		fragrances.getOne(id).then(f => f && form.apply(f));
	}, [form, fragrances, id]);

	const fillNoseNotes = useCallback(() => {
		const set = new Set([
			...(form.$noseNotes.value ?? []),
			...(form.$brandTopNotes.value ?? []),
			...(form.$brandMidNotes.value ?? []),
			...(form.$brandBaseNotes.value ?? []),
		]);
		form.$noseNotes.set([...set]);
	}, [form.$brandBaseNotes.value, form.$brandMidNotes.value, form.$brandTopNotes.value, form.$noseNotes]);

	const fetchRemindsMeOptions = useMemo(
		() =>
			debounce(
				async (inputValue: string) => {
					try {
						if (isNaN(+inputValue) || !inputValue) return;

						const fragrance = await fragrances.getOne(+inputValue)

						if (!fragrance) return;

						setRemindsMeOptions((prev) => {
							if (prev.find((el) => el.id === fragrance.id)) return [...prev];

							return [fragrance, ...prev]
						});
					} catch (e) {
						console.error(e);
					}
				},
				400,
			),
		[fragrances],
	);

	return (
		<Form.Provider value={form}>
			<EditContainer async={fragrances.async}>
				<FragrancePhotos form={form} id={id} reload={reload} />
				<EditCenter>
					<Tabs value={tab} onChange={(_, v) => setTab(v)} aria-label="main tabs" centered>
						<Tab label="General" value="general" />
						<Tab label="Nose info" value="nose" />
						<Tab label="Impressions" value="impressions" />
						<Tab label="Clasped" value="clasped" />
						<Tab label="Links" value="links" />
						<Tab label="Affiliate" value="affiliate" />
					</Tabs>
					{tab === 'general' && (
						<>
							<Row gap={4}>
								<FieldNumber
									disabled={!!id}
									field={form.$id}
									InputProps={{ endAdornment: <Col height={40} /> }}
								/>
								<TextField
									fullWidth
									label={`Super fragrance ${form.initial?.superName ?? ''}`}
									disabled={!!form.initial?.superId}
									value={fragrances.superIdStr ?? ''}
									onChange={e => fragrances.setSuperId(e.target.value)}
									sx={{ backgroundColor: form.initial?.superId ? '#ff000020' : undefined }}
									helperText={
										form.initial?.superSetBy
											? `Set by ${form.initial.superSetBy} at ${dayjs(
													form.initial.superSetAt,
												).format('YYYY-MM-DD HH:mm')}`
											: undefined
									}
									InputProps={{
										endAdornment: form.initial?.superId ? (
											<IconButton
												onClick={() =>
													fragrances.removeSuperId(id).done(() => window.location.reload())
												}
											>
												<RemoveIcon />
											</IconButton>
										) : (
											<IconButton
												disabled={!fragrances.superId}
												onClick={() =>
													fragrances.postSuperId(id).done(() => window.location.reload())
												}
											>
												<SaveIcon />
											</IconButton>
										),
										startAdornment: form.initial?.superId && (
											<IconButton
												onClick={() =>
													window.open(
														AppRoutes.FragranceEdit.buildPath({
															id: form.initial?.superId ?? 0,
														}),
														'_blank',
													)
												}
											>
												<OpenInBrowser />
											</IconButton>
										),
									}}
								/>
							</Row>
							<FieldText field={form.$name} />
							<FieldText field={form.$description} multiline />
							<FieldTextArray field={form.$synonyms} multiline rows={undefined} />
							<Row gap={4}>
								<FieldSelect
									items={brands.names}
									valueProp="id"
									labelProp="name"
									enabledProp="active"
									field={form.$brandId}
								/>
								<FieldSelect
									field={form.$olfactiveId}
									items={olfactives.names}
									valueProp="id"
									labelProp="name"
									nullLabel=""
								/>
							</Row>
							<Row gap={4}>
								<FieldEnum field={form.$gender} enumeration={Gender} nullLabel="" />
								<FieldMultiSelect
									items={fragrances.years}
									valueProp="id"
									labelProp="name"
									field={form.$years}
								/>
							</Row>
							<FieldAutocomplete
								items={perfumers.names}
								valueProp="id"
								labelProp="name"
								enabledProp="active"
								field={form.$perfumers}
							/>
							<FieldAutocomplete
								items={notes.names}
								valueProp="id"
								labelProp="name"
								enabledProp="active"
								field={form.$brandBaseNotes}
							/>
							<FieldAutocomplete
								items={notes.names}
								valueProp="id"
								labelProp="name"
								enabledProp="active"
								field={form.$brandMidNotes}
							/>
							<FieldAutocomplete
								items={notes.names}
								valueProp="id"
								labelProp="name"
								enabledProp="active"
								field={form.$brandTopNotes}
							/>
							<FieldAutocomplete
								items={ingredients.names}
								valueProp="id"
								labelProp="name"
								field={form.$ingredients}
							/>
							<FieldText multiline field={form.$comment} />
							<FieldSwitch field={form.$active} />
							<FieldSwitch field={form.$discontinued} />
						</>
					)}
					{tab === 'nose' && (
						<>
							<Col>
								<Row gap={4}>
									<FieldEnum
										field={form.$noseSillage}
										enumeration={Sillage}
										enumName="Sillage"
										nullLabel=""
									/>
									<FieldEnum
										field={form.$noseLongevity}
										enumeration={Longevity}
										enumName="Longevity"
										nullLabel=""
									/>
									<FieldEnum
										field={form.$noseConcentration}
										enumeration={Concentration}
										enumName="Concentration"
										nullLabel=""
									/>
								</Row>
								<Row gap={4}>
									<FieldMultiEnum field={form.$noseWeather} enumeration={Weather} />
									<FieldMultiEnum field={form.$noseOccasion} enumeration={Occasion} />
									<FieldMultiEnum field={form.$noseSeason} enumeration={Season} />
								</Row>
							</Col>
							<FieldAutocomplete
								items={notes.names}
								valueProp="id"
								labelProp="name"
								enabledProp="active"
								field={form.$noseNotes}
							/>
							<FieldAutocomplete
								items={remindsMeOptions}
								valueProp="id"
								labelProp="name"
								placeholder="Search by Fragrance ID"
								onChange={(event) => fetchRemindsMeOptions(event.target.value)}
								field={form.$noseRemindsFragrances}
							/>
						</>
					)}
					{tab === 'impressions' && (
						<>
							<Row gap={4}>
								<FieldEnum
									disabled
									field={form.$sillage}
									enumeration={Sillage}
									enumName="Sillage"
									nullLabel=""
								/>
								<FieldEnum
									disabled
									field={form.$longevity}
									enumeration={Longevity}
									enumName="Longevity"
									nullLabel=""
								/>
							</Row>
							<Row gap={4} alignItems="flex-start">
								<Col>
									<TextField
										multiline
										disabled
										value={form.initial?.occasion
											.sort((a, b) => b.votes - a.votes)
											.map(({ occasion, votes }) => `${occasion}: ${votes}`)
											.join('\n')}
										label="Occasions"
									/>
								</Col>
								<Col>
									<TextField
										multiline
										disabled
										value={form.initial?.weather
											.sort((a, b) => b.votes - a.votes)
											.map(({ weather, votes }) => `${weather}: ${votes}`)
											.join('\n')}
										label="Weathers"
									/>
								</Col>
								<Col>
									<TextField
										multiline
										disabled
										value={form.initial?.season
											.sort((a, b) => b.votes - a.votes)
											.map(({ season, votes }) => `${season}: ${votes}`)
											.join('\n')}
										label="Seasons"
									/>
								</Col>
							</Row>
							<Row gap={4}>
								<FieldEnum
									disabled
									field={form.$concentration}
									enumeration={Concentration}
									enumName="Concentration"
									nullLabel=""
								/>
							</Row>
							{id && <FragranceEditReviews fragranceId={id as FragranceId} />}
						</>
					)}
					{tab === 'clasped' && (
						<List>
							{form.initial?.clasped?.map(c => (
								<Paper>
									<ListItemButton
										key={c.id}
										onClick={() => window.open(AppRoutes.FragranceEdit.buildPath({ id: c.id }))}
									>
										<ListItemText primary={c.name} secondary={c.id} />
									</ListItemButton>
								</Paper>
							))}
						</List>
					)}
					{tab === 'affiliate' && <FragranceAffiliate form={form} />}
					{tab === 'links' && <FragranceLinks field={form.$links} />}
				</EditCenter>
				<EditRight>
					{form.dirty && (
						<Alert variant="filled" severity="warning" icon={null}>
							There are unsaved changes
						</Alert>
					)}
					<Button
						variant="contained"
						onClick={() => {
							form.submit()?.then(() => navBack());
						}}
						disabled={!canSave}
					>
						Save and back
					</Button>
					<Button
						variant="outlined"
						onClick={() => {
							form.submit()?.then(reload);
						}}
						disabled={!canSave}
					>
						Save
					</Button>
					<Button variant="outlined" onClick={navBack}>
						Cancel
					</Button>
					<br />
					<Button variant="outlined" onClick={form.reset}>
						Reset
					</Button>
					<Button variant="outlined" onClick={fillNoseNotes}>
						Fill Nose notes
					</Button>

					{id && (
						<>
							<br />
							<Button variant="outlined" onClick={() => ui.openHistory('FRAGRANCE', id)}>
								Show history
							</Button>
							<Button
								variant="outlined"
								onClick={() => window.open(AppRoutes.BrandEdit.buildPath({ id: +form.$brandId.value }))}
							>
								Show brand
							</Button>
							{auth.can('user', 'READ') && (
								<Button variant="outlined" onClick={() => ui.showDialog(FragranceExperiencesDialog)}>
									Show experiences
								</Button>
							)}
							<br />
							<Button
								disabled={!auth.can('fragrance', 'DELETE')}
								variant="outlined"
								color="error"
								onClick={() =>
									ui.confirmDelete(() =>
										fragrances.delete(id).then(() => {
											navBack();
											ui.notify('Fragrance deleted successfully', 'success');
										}),
									)
								}
							>
								Delete
							</Button>
						</>
					)}
				</EditRight>
			</EditContainer>
			{id && <FragranceExperiencesDialog fragranceId={id as FragranceId} />}
		</Form.Provider>
	);
});
