import { plainToInstance } from 'class-transformer';
import { ValidationError, getMetadataStorage, validateSync } from 'class-validator';
import { TFunction } from 'i18next';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC, PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';
import { C } from 'ts-toolbelt';

export namespace Form {
	const context = createContext(null as FormClass<any> | null);
	export const defaultOptions: Omit<Options<any>, 'onSubmit'> = {};

	export const Provider = context.Provider;

	export class FormClass<T extends object, I extends Partial<T> = T> {
		readonly fields: ReadonlyMap<string, Form.Field<any>>;
		@observable.ref initial?: I = undefined;
		@observable.ref errors?: ValidationError[] = undefined;

		@computed get dirty() {
			return [...this.fields.values()].some(f => f.touched);
		}

		private childForms = [] as FormClass<any>[];

		constructor(readonly dto: C.Class<[], T>, readonly opts: Form.Options<T, I>) {
			makeObservable(this);
			const fields = new Map();
			const data = plainToInstance(
				dto,
				{},
				{
					enableImplicitConversion: true,
					excludeExtraneousValues: true,
				},
			);
			for (const name of Object.keys(data)) {
				const fld = new Form.Field(name, { ...Form.defaultOptions, ...opts }, this);
				fields.set(name, fld);
				(this as any)['$' + name] = fld;
			}
			this.fields = fields;

			if (typeof opts.initial === 'function') {
				this.setInitial(new dto() as any);
				opts.initial().then(this.setInitial, undefined);
			} else {
				this.setInitial(opts.initial);
			}
		}

		@action.bound apply(data: Partial<T>) {
			for (const [name, fld] of this.fields) {
				fld.set((data as any)[name], false);
			}
		}

		@action.bound reset() {
			this.initial && this.apply(this.initial);
			for (const fld of this.fields.values()) {
				fld.error = undefined;
				fld.touched = false;
			}
		}

		@action.bound submit() {
			let childValid = true;
			for (const child of this.childForms) {
				const valid = child.validate();
				if (valid) child.submit();
				else childValid = false;
			}
			if (!childValid) return;
			for (const fld of this.fields.values()) {
				fld.preValidate?.(fld);
			}
			const inst = this.getInstance();
			if (!this.validate(inst)) {
				return;
			}
			const rv = this.opts.onSubmit?.(inst);
			for (const field of this.fields.values()) {
				field.touched = false;
			}
			return rv;
		}

		@action validate(inst?: T) {
			this.errors = undefined;
			const errs = validateSync(inst ?? this.getInstance());
			if (errs.length === 0) return true;
			for (const err of errs) {
				const fld = this.fields.get(err.property);
				if (!fld) continue;
				fld.error = this.opts.trValidation
					? this.opts.trValidation?.(this.validationKey(err, err.property))
					: err.constraints
					? Object.values(err.constraints)[0]
					: undefined;
			}
			console.warn('Validation failed', errs);
			this.errors = errs;
			return false;
		}

		@action.bound private setInitial(data: I | undefined | null) {
			if (data) {
				this.initial = data;
				this.apply(data);
			}
		}

		getInstance() {
			const doc = Object.fromEntries([...this.fields.entries()].map(([k, v]) => [k, toJS(v.value)]));
			const rv = plainToInstance(this.dto, doc, {
				enableImplicitConversion: true,
				excludeExtraneousValues: true,
			});
			this.opts.hookGetInstance?.(rv);
			return rv;
		}

		private validationKey(error: ValidationError | undefined, propertyName: string) {
			if (!error?.constraints) return '';
			const err = Object.entries(error.constraints)[0];

			const storage = getMetadataStorage();
			const metas = error.target
				? storage.getTargetValidationMetadatas(error.target.constructor, '', true, false)
				: [];
			for (const meta of metas) {
				if (meta.propertyName !== propertyName) continue;
				const constraint = storage
					.getTargetValidatorConstraints(meta.constraintCls)
					.find(c => c.name === err[0]);
				if (!constraint) continue;
				if (meta.message && typeof meta.message === 'string') {
					return meta.message;
				} else {
					const args: { [key: string]: any } = {};
					meta.constraints?.forEach((arg, i) => {
						args[`arg${i + 1}`] = arg;
					});
					return constraint.name;
				}
			}
			return err[0];
		}
	}

	export class Field<T> {
		@observable error?: string | null = undefined;
		@observable value: T = undefined!;
		@observable touched = false;

		preValidate?: (fld: Field<T>) => void; // TODO: fuckin onBlur _after_ onPress

		@computed get helperText() {
			return this.error;
		}

		@computed get hasError() {
			return !!this.error;
		}

		@computed get label() {
			const key = `${this.formOpts.name ? this.formOpts.name + '.' : ''}${this.name}`;
			return this.formOpts.trNames?.(key) ?? this.name;
		}

		@computed get maxLength() {
			const storage = getMetadataStorage();
			const metas = storage.getTargetValidationMetadatas(this.form.dto, '', true, false) ?? [];
			for (const meta of metas) {
				if (meta.propertyName !== this.name) continue;
				const constraint = storage
					.getTargetValidatorConstraints(meta.constraintCls)
					.find(c => c.name === 'maxLength');
				if (!constraint) continue;
				return meta.constraints[0];
			}
			return 0;
		}

		constructor(readonly name: string, protected formOpts: Options<any>, protected form: FormClass<any, any>) {
			makeObservable(this);
		}

		@action touch() {
			this.touched = true;
		}

		@action onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			this.set((e.target as any).value as T);
		};

		@action set = (val: T | null | undefined, touch = true) => {
			this.error = undefined;
			this.value = val as any;
			touch && this.touch();
			if (this.formOpts.validateOn === 'change') {
				this.form.validate();
			}
			if (this.formOpts.onChange) {
				this.formOpts.onChange(this.form.getInstance());
			}
		};
	}

	export interface Options<T extends object, I extends Partial<T> = Partial<T>> {
		onSubmit?: (dto: T) => PromiseLike<any> | void;
		onChange?: (dto: T) => void;
		hookGetInstance?: (dto: T) => void;
		validateOn?: 'submit' | 'change';
		initial?: I | (() => Promise<I | undefined>) | null;
		trValidation?: TFunction | null;
		trNames?: TFunction | null;
		name?: string;
	}

	export type FieldProps<T, P extends object> = { field: Form.Field<T> } & P;

	export function field<T, P extends object = {}>(
		component: FC<
			PropsWithChildren<
				{ field: Form.Field<T> | Form.Field<T | undefined> | Form.Field<T | null | undefined> } & P
			>
		>,
	) {
		return observer(component);
	}

	export function use<T extends object, I extends T>(dto: C.Class<[], T>, opts: Options<T, I>): Form<T, I> {
		// eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks
		const form = useMemo(() => new FormClass(dto, opts), [dto]);
		const parentForm = useContext(context);
		useEffect(() => {
			if (parentForm) {
				const childForms = parentForm['childForms'];
				childForms.push(form);
				return () => {
					childForms.splice(childForms.findIndex(f => f === form));
				};
			}
		}, [parentForm]);
		return form as any;
	}

	export function create<T extends object, I extends T>(dto: C.Class<[], T>, opts: Options<T, I>): Form<T, I> {
		return new FormClass(dto, opts) as any;
	}
}

export type Form<T extends object, I extends Partial<T> = T> = Form.FormClass<T, I> & {
	[P in Extract<keyof T, string> as `$${P}`]: Form.Field<T[P]>;
};
