import { makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { GridStateModel } from '~/model/model.gridstate';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

@Singleton()
export class UserService {
	readonly items = observable.array([] as API.UserItem[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('user');

	@observable writers = [] as API.UserItem[];
	@observable totalCount = 0;

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.userGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState.sort,
				sortDir: this.gridState.sortDir,
			});
			return () => {
				this.items.replace(data.items);
				this.totalCount = data.total;
			};
		});
	};

	getWriters = () => {
		this.async.call(async () => {
			const { data } = await this.api.userGetAll({ role: 'WRITER' });
			return () => {
				this.writers = data.items;
			};
		});
	};
}
