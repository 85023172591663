import { SxProps, TypographyProps } from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';
import { Text } from './view.box';

const sxUnderline: SxProps = {
	textDecoration: 'underline',
};

export function LinkView({
	to,
	target,
	...props
}: TypographyProps & { to: string; target?: HTMLAttributeAnchorTarget }) {
	return (
		<Link to={to} target={target}>
			<Text variant="body2" sx={sxUnderline} {...props}></Text>
		</Link>
	);
}
