import { Button, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { AppRoutes, useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { IngredientService } from '~/service/service.ingredient';
import { UIService } from '~/service/service.ui';
import { IngredientCreateDto } from '~/shared/dto/admin/ingredient.dto';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FieldAutocomplete } from '~/view/fields/field.autocomplete';
import { FieldImages } from '~/view/fields/field.images';
import { FieldText } from '~/view/fields/field.text';
import { EditCenter, EditContainer, EditLeft, EditRight } from '~/view/view.edit';

export const IngredientEditPage = observer(() => {
	const [ui, auth, ingredients] = useService(UIService, AuthService, IngredientService);
	const navBack = useNavBack();
	const { id } = useTypedParams(AppRoutes.IngredientEdit);

	const form = Form.use(IngredientCreateDto, {
		name: 'IngredientCreateDto',
		onSubmit: dto =>
			ingredients.save(id, dto).then(() => {
				navBack();
				ui.notify('Ingredient saved successfully', 'success');
			}),
		initial: () => ingredients.getOne(id),
	});

	useEffect(() => ingredients.fetchNames(), [ingredients]);
	ui.usePageTitle(form.initial?.name ? `Ingredient - ${form.initial?.name}` : '');
	const canSave = id ? auth.can('notes', 'UPDATE') : auth.can('notes', 'CREATE');

	return (
		<EditContainer async={ingredients.async}>
			<EditLeft>
				<FieldImages field={form.$images} images={form.initial?.images} />
			</EditLeft>
			<EditCenter>
				{form.initial?.id && <TextField fullWidth disabled value={form.initial?.id} />}
				<FieldText field={form.$name} />
				<FieldText field={form.$casNumber} />
				<FieldText field={form.$vendor} />
				<FieldText field={form.$description} multiline rows={3} />
				<FieldAutocomplete
					items={ingredients.names.filter(n => id !== n.id)}
					valueProp="id"
					labelProp="name"
					field={form.$analogs}
				/>
			</EditCenter>
			<EditRight>
				<Button variant="contained" onClick={form.submit} disabled={!canSave}>
					Save
				</Button>
				<br />
				<Button variant="outlined" onClick={navBack}>
					Cancel
				</Button>
				<Button variant="outlined" onClick={form.reset}>
					Reset
				</Button>
				{id && (
					<>
						<br />
						<Button
							disabled={!auth.can('notes', 'DELETE')}
							variant="outlined"
							color="error"
							onClick={() =>
								ui.confirmDelete(() =>
									ingredients.delete(id).then(() => {
										navBack();
										ui.notify('Ingredient deleted successfully', 'success');
									}),
								)
							}
						>
							Delete
						</Button>
					</>
				)}
			</EditRight>
		</EditContainer>
	);
});
