import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Drawer,
	IconButton,
	LinearProgress,
	Switch,
	SxProps,
} from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { FeedbackType } from '~/api/api.declaration';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { FeedbackService } from '~/service/service.feedback';
import { PostService } from '~/service/service.post';
import { UIService } from '~/service/service.ui';
import { FeedbackReplyDto } from '~/shared/dto/admin/feedback.dto';
import { Enum } from '~/shared/fields';
import { Form } from '~/shared/form';
import { useService } from '~/shared/service/service.base';
import { FieldNumber } from '~/view/fields/field.number';
import { FieldText } from '~/view/fields/field.text';
import { DateTimeColumn, EmailLink, LinkColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';
import { AsyncView } from '~/view/view.async';
import { Col, Row, Text } from '~/view/view.box';
import { LinkView } from '~/view/view.link';

export const FeedbackListPage = observer(() => {
	const [feedbackSvc, ui, auth] = useService(FeedbackService, UIService, AuthService);
	const columns = useGridColumns<API.Feedback>(() => [
		DateTimeColumn({ field: 'createdAt', headerName: 'CreatedAt', filterable: false }),
		{ field: 'status', headerName: 'Status', filterable: false },
		LinkColumn({
			field: 'createdByName',
			getLink: row => AppRoutes.UserEdit.buildPath({ id: row.createdBy }),
			headerName: 'By',
			sortable: false,
			filterable: false,
		}),
		{ field: 'type', headerName: 'Type', width: 170, type: 'singleSelect', valueOptions: Enum.keys(FeedbackType) },
		{ field: 'rate', headerName: 'Rate', width: 70, filterable: false },
		{ field: 'message', flex: 1, headerName: 'Subject', renderCell: renderSubjectCell, filterable: false },
	]);

	ui.usePageTitle('Feedback');
	useEffect(() => feedbackSvc.loadAndSubscribe(), [feedbackSvc]);

	return (
		<>
			<FeedbackDrawer />
			<GridContainer async={feedbackSvc.async}>
				<DataGrid
					initialState={feedbackSvc.gridState.initial()}
					loading={feedbackSvc.async.loading}
					rowCount={feedbackSvc.totalCount}
					rows={feedbackSvc.items.slice()}
					columns={columns}
					paginationMode="server"
					sortingMode="server"
					filterMode="server"
					onStateChange={feedbackSvc.gridState.onStateChange}
					onFilterModelChange={feedbackSvc.changeFilterModel}
					onRowClick={params => {
						feedbackSvc.setSelectedItem(params.row);
					}}
					slots={{
						loadingOverlay: LinearProgress,
						noRowsOverlay: () => <GridNoRows>No feedbacks found</GridNoRows>,
						pagination: MuiGridPagination,
						toolbar: () => (
							<GridToolbarView
								onReload={feedbackSvc.reload}
								gridState={feedbackSvc.gridState}
								exportEnabled={auth.can('feedback', 'EXPORT')}
							/>
						),
					}}
				/>
			</GridContainer>
		</>
	);
});

function renderSubjectCell(params: GridRenderCellParams<API.Feedback>) {
	const row = params.row as API.Feedback;
	if (row.type === 'POST_COMPLAINT') {
		return (
			<LinkView to={AppRoutes.PostEdit.buildPath({ id: row.postId ?? 0 })} target="_blank">
				[{row.complaint}] Post by {row.postAuthor}: {row.postText}
			</LinkView>
		);
	} else if (row.type === 'PHOTO_COMPLAINT') {
		return (
			<LinkView to={AppRoutes.FragranceEdit.buildPath({ id: row.fragranceId ?? 0 })} target="_blank">
				[{row.complaint}] Post photo by {row.postAuthor}: {row.postText}
			</LinkView>
		);
	} else if (row.type === 'REVIEW_COMPLAINT') {
		return (
			<LinkView to={AppRoutes.FragranceEdit.buildPath({ id: row.fragranceId ?? 0 })} target="_blank">
				[{row.complaint}] Fragrance review for {row.fragranceId}
			</LinkView>
		);
	} else if (row.type === 'USER_COMPLAINT') {
		return (
			<LinkView to={AppRoutes.UserEdit.buildPath({ id: row?.userId ?? 0 })} target="_blank">
				[{row.complaint}] User {row.userName}: {row.message}
			</LinkView>
		);
	} else if (row.type === 'FRAG_SUGGESTION') {
		return <Text variant="body2">{row.message}</Text>;
	} else {
		return <Text variant="body2">{row.message}</Text>;
	}
}

const sxDrawer: SxProps = {
	width: 350,
	flexShrink: 0,
	'& .MuiDrawer-paper': {
		width: 350,
		top: 64,
		px: 2,
		height: 'calc(100% - 64px)',
		boxSizing: 'border-box',
		zIndex: 1000,
	},
};

const Item = styled.div({
	flexDirection: 'row',
	display: 'flex',
	justifyContent: 'space-between',
	borderBottom: 'solid #dadada 1px',
	paddingBottom: 3,
	gap: 16,
});
const Title = styled.div({ fontWeight: '600', fontFamily: 'sans-serif', fontSize: 14 });
const Value = styled.div({ fontFamily: 'sans-serif' });

const FeedbackDrawer = observer(() => {
	const [
		{
			selectedItem,
			setSelectedItem,
			resolveSelectedItem,
			reopenSelectedItem,
			declineSelectedItem,
			showReplyDialog,
			async,
			reload,
		},
		postSvc,
		auth,
	] = useService(FeedbackService, PostService, AuthService);

	return (
		<Drawer sx={sxDrawer} PaperProps={{ elevation: 4 }} variant="persistent" anchor="right" open={!!selectedItem}>
			<FeedbackReplyDialog />
			<AsyncView async={async}>
				<Col gap={1}>
					<Row justifyContent="flex-start" py={1}>
						<IconButton onClick={() => setSelectedItem(undefined)}>
							<Close />
						</IconButton>
						{selectedItem?.type}
					</Row>
					<Item>
						<Title>Created at:</Title>
						<Value>{new Date(selectedItem?.createdAt ?? 0).toLocaleString()}</Value>
					</Item>
					<Item>
						<Title>Created by:</Title>
						<Value>
							<LinkView
								to={AppRoutes.UserEdit.buildPath({ id: selectedItem?.createdBy ?? 0 })}
								target="_blank"
							>
								{selectedItem?.createdByName}
							</LinkView>
						</Value>
					</Item>
					{auth.can('user', 'EMAIL') && (
						<Item>
							<Title>Created by email:</Title>
							<Value>
								<EmailLink href={`mailto:${selectedItem?.createdByEmail}`} target="_blank">
									{selectedItem?.createdByEmail}
								</EmailLink>
							</Value>
						</Item>
					)}
					<Item>
						<Title>Status:</Title>
						<Value>{selectedItem?.status}</Value>
					</Item>
					<Item>
						<Title>Message:</Title>
						<Value>{selectedItem?.message}</Value>
					</Item>
					{selectedItem?.statusChangedBy && (
						<>
							<Item>
								<Title>Status changed by:</Title>
								<Value>{selectedItem?.statusChangedBy}</Value>
							</Item>
							<Item>
								<Title>Status changed at:</Title>
								<Value>{new Date(selectedItem?.statusChangedAt ?? 0).toLocaleString()}</Value>
							</Item>
						</>
					)}
					{selectedItem?.type === 'POST_COMPLAINT' ? (
						<>
							<Item>
								<Title>Complaint:</Title>
								<Value>{selectedItem?.complaint}</Value>
							</Item>
							<Item>
								<Title>Post:</Title>
								<LinkView
									to={AppRoutes.PostEdit.buildPath({ id: selectedItem?.postId ?? 0 })}
									target="_blank"
								>
									{selectedItem.postId}
								</LinkView>
							</Item>
							<Item>
								<Title>Post author:</Title>
								<LinkView
									to={AppRoutes.UserEdit.buildPath({ id: selectedItem?.postAuthorId ?? 0 })}
									target="_blank"
								>
									{selectedItem.postAuthor}
								</LinkView>
							</Item>
							<Item>
								<Title>Post text:</Title>
								<Value>{selectedItem?.postText}</Value>
							</Item>
							<Item>
								<Title>Post moderation:</Title>
								<Value>{selectedItem?.postModeration}</Value>
							</Item>
							{selectedItem.postAuthorId ? (
								<>
									{selectedItem?.postModeration !== 'HIDDEN' ? (
										<Button
											variant="contained"
											onClick={() =>
												selectedItem?.postId && postSvc.hide(selectedItem.postId).done(reload)
											}
										>
											Hide post
										</Button>
									) : (
										<Button
											variant="contained"
											onClick={() =>
												selectedItem?.postId &&
												postSvc.approve(selectedItem.postId).done(reload)
											}
										>
											Approve post
										</Button>
									)}

									<Button
										variant="contained"
										color="warning"
										onClick={() =>
											selectedItem?.postId && postSvc.delete(selectedItem.postId).done(reload)
										}
									>
										Delete post
									</Button>
								</>
							) : null}
						</>
					) : selectedItem?.type === 'USER_COMPLAINT' ? (
						<>
							<Item>
								<Title>Complaint:</Title>
								<Value>{selectedItem?.complaint}</Value>
							</Item>
							<Item>
								<Title>User:</Title>
								<LinkView
									to={AppRoutes.UserEdit.buildPath({ id: selectedItem?.userId ?? 0 })}
									target="_blank"
								>
									{selectedItem.userName}
								</LinkView>
							</Item>
						</>
					) : (
						<></>
					)}
					<Row justifyContent="space-around">
						<Button variant="contained" onClick={showReplyDialog}>
							Reply
						</Button>
						{selectedItem?.status === 'NEW' ? (
							<Button variant="contained" onClick={resolveSelectedItem}>
								Resolve
							</Button>
						) : (
							<Button variant="contained" onClick={reopenSelectedItem}>
								Reopen
							</Button>
						)}
						<Button variant="contained" onClick={declineSelectedItem}>
							Decline
						</Button>
					</Row>
				</Col>
			</AsyncView>
		</Drawer>
	);
});

const FeedbackReplyDialog = observer(() => {
	const [{ replyDialogVisible, getLastReply, hideReplyDialog, replyToSelected, async, selectedItem }] =
		useService(FeedbackService);

	const form = Form.use(FeedbackReplyDto, {
		name: 'FeedbackReplyDto',
		onSubmit: replyToSelected,
	});

	useEffect(() => {
		form.reset();
		form.apply(getLastReply() ?? {});
	}, [form, getLastReply, selectedItem]);

	return (
		<Dialog open={replyDialogVisible} PaperProps={{ sx: { p: 2, width: 400 } }}>
			<AsyncView async={async}>
				<DialogTitle>
					<Row justifyContent="space-between">
						<Text>Reply to feedback</Text>
						<IconButton onClick={hideReplyDialog}>
							<Close />
						</IconButton>
					</Row>
				</DialogTitle>
				<DialogContent>
					<Col gap={2}>
						<FieldText field={form.$title} />
						<FieldText multiline field={form.$message} />
						<FieldText multiline field={form.$description} />
						<FieldText field={form.$analyticsLabel} />
						<FieldText field={form.$imgLeft} />
						<FieldText field={form.$imgRight} />
						<FieldNumber field={form.$fragranceId} />
						<Row>
							<Switch
								checked={!!form.$status.value}
								onChange={e => form.$status.set(e.target.checked ? 'DONE' : undefined)}
							/>
							Mark feedback as RESOLVED
						</Row>
					</Col>
				</DialogContent>
				<DialogActions>
					<Button onClick={hideReplyDialog}>Cancel</Button>
					<Button variant="contained" onClick={form.submit}>
						Send
					</Button>
				</DialogActions>
			</AsyncView>
		</Dialog>
	);
});
