import { makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

@Singleton()
export class OlfactiveService {
	@observable.ref names: API.OlfactiveName[] = [];
	async = new Async();

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	fetchNames = () => {
		this.async.call(async () => {
			const { data } = await this.api.olfactiveGetNames();
			return () => (this.names = data.items);
		});
	};
}
