import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { number, route, string } from 'react-router-typesafe-routes/dom';

export const AppRoutes = {
	Index: route(''),
	StaffList: route('staff'),
	StaffEdit: route('staff/:id', { params: { id: number() } }),

	BrandList: route('brand'),
	BrandNew: route('brand/new'),
	BrandEdit: route('brand/:id', { params: { id: number() } }),

	FragranceList: route('fragrance'),
	FragranceNew: route('fragrance/new'),
	FragranceEdit: route('fragrance/:id', { params: { id: number() } }),

	PerfumerList: route('perfumer'),
	PerfumerNew: route('perfumer/new'),
	PerfumerEdit: route('perfumer/:id', { params: { id: number() } }),

	NoteList: route('note'),
	NoteNew: route('note/new'),
	NoteEdit: route('note/:id', { params: { id: number() } }),

	PushList: route('push'),
	PushNew: route('push/new', { searchParams: { clone: number() } }),
	PushEdit: route('push/:id', { params: { id: number() } }),

	IngredientList: route('ingredient'),
	IngredientNew: route('ingredient/new'),
	IngredientEdit: route('ingredient/:id', { params: { id: number() } }),

	UserList: route('user'),
	UserEdit: route('user/:id', { params: { id: number() } }),

	PostList: route('post'),
	PostNew: route('post/new'),
	PostEdit: route('post/:id', { params: { id: number() } }),

	FeedbackList: route('feedback'),
	SnapshotList: route('snapshot'),
	ChangeLog: route('changelog', { searchParams: { entity: string(), id: string() } }),
	Settings: route('settings'),
	StopWords: route('stopwords'),
	Recommends: route('recommends'),
};

export const useNavBack = () => {
	const nav = useNavigate();
	return useCallback(() => nav(-1), [nav]);
};
