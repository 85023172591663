import { validateSync } from 'class-validator';
import csv from 'csvtojson';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { C } from 'ts-toolbelt';
import { API } from '~/api';
import { BrandId, FragranceId, Gender, ImportResult, Longevity } from '~/api/api.declaration';
import { ApiService } from '~/service/service.api';
import { BrandImportItem } from '~/shared/dto/admin/brand.dto';
import { FragranceImportDto } from '~/shared/dto/admin/fragrance.dto';
import { OlfactiveFamilyId, PerfumerId } from '~/shared/dto/id';
import { plainToDTO } from '~/shared/fields';
import { Model } from '~/shared/service/service.base';
import { Async } from '~/shared/tools/async';

export abstract class ImportModel<T extends object> {
	readonly async = new Async();

	@observable.ref data = undefined as T[] | undefined;
	@observable error = undefined as string | undefined;
	@observable errors = 0;
	@observable.ref result = undefined as API.ImportResult | undefined;

	private fileName!: string;

	@computed get canImport() {
		return !!this.data?.length && !this.errors && !this.async.loading && !this.result;
	}

	constructor(
		protected dtoClass: C.Class<[], T>,
		protected postFunction: (data: { items: T[]; fileName: string }) => Promise<{ data: ImportResult }>,
	) {
		makeObservable(this);
	}

	abstract getItemFromRow(row: any): T;

	@action.bound reset() {
		this.data = undefined;
		this.error = undefined;
		this.errors = 0;
		this.result = undefined;
	}

	loadCSV = async (file: File) => {
		try {
			this.fileName = file.name;
			this.reset();
			const content = await file.text();
			const rows = await csv().fromString(content);
			this.convertImportRows(rows);
		} catch (e: any) {
			console.error(e);
			runInAction(() => {
				this.error = e.message;
				this.errors = 1;
			});
		}
	};

	saveImported = () => {
		return this.async.call(async () => {
			if (this.data) {
				const rv = await this.postFunction({ items: this.data, fileName: this.fileName });
				return () => (this.result = rv.data);
			}
		});
	};

	@action protected convertImportRows(rows: any[]) {
		let i = 2;
		const results = [];
		for (const row of rows) {
			try {
				const src = this.getItemFromRow(row);
				const dto = plainToDTO(this.dtoClass, src);
				const errs = validateSync(dto);
				if (errs.length) {
					this.error = `At line ${i}: ` + errs[0].toString();
					this.errors++;
				} else {
					results.push(dto);
				}
				i++;
			} catch (e: any) {
				console.log(e);
				throw new Error(`At line ${i}: ${e.message}`);
			}
		}
		this.data = results;
	}

	protected removeBrackets(str: string | undefined) {
		str = str?.trim() ?? '';
		if (!str) return undefined;
		if (str.startsWith('{')) str = str.slice(1);
		if (str.endsWith('}')) str = str.slice(0, -1);
		return str;
	}

	protected parseNumber(src: string | number | undefined | null): number | undefined | null {
		if (src === undefined || src === null) return undefined;
		if (src === 'null') return null;
		if (typeof src === 'number') return src;
		if (src.trim() === '') return undefined;
		const num = +src;
		if (isFinite(num)) return num;
		throw new Error(`Invalid number "${src}"`);
	}

	protected parseNumberArray<T extends number>(src: string | undefined | null): T[] | undefined {
		if (src === undefined || src === null) return undefined;
		const rv = JSON.parse(src);
		if (Array.isArray(rv) && rv.every(i => typeof i === 'number')) return rv as T[];
		throw new Error(`Invalid array of numbers "${src}"`);
	}

	protected parseBoolean(src: any): boolean | undefined {
		if (src === undefined || src === null || src === 'null') return undefined;
		if (typeof src === 'boolean') return src;
		if (typeof src === 'string') {
			if (src.toLowerCase().trim().startsWith('t')) return true;
			if (src.toLowerCase().trim().startsWith('f')) return false;
		}
		throw new Error(`Invalid boolean "${src}"`);
	}

	protected parseEnum(enumType: any, src: any): any | undefined {
		if (src === undefined || src === null || src === 'null' || src === '') return undefined;
		if (typeof src === 'string') {
			const rv = enumType[src.trim().toUpperCase()];
			if (rv !== undefined) return rv;
		}
		throw new Error(`Invalid enum "${src}"`);
	}
}

@Model()
export class BrandImportModel extends ImportModel<BrandImportItem> {
	constructor(api: ApiService) {
		super(BrandImportItem, api.brandImport.bind(api));
	}

	getItemFromRow(row: any): BrandImportItem {
		return {
			id: this.parseNumber(row.brandId) as BrandId,
			name: row.name,
			active: this.parseBoolean(row.active),
			description: row.description,
			images: this.removeBrackets(row.images)
				?.split(',')
				.map((i: string) => i.trim())
				.filter((i: string) => !!i),
			year: this.parseNumber(row.year) || undefined,
			country: row.country,
			synonyms: this.removeBrackets(row.synonyms)
				?.split(',')
				.map((i: string) => i.trim())
				.filter((i: string) => !!i),
		};
	}
}

@Model()
export class FragranceImportModel extends ImportModel<FragranceImportDto> {
	constructor(api: ApiService) {
		super(FragranceImportDto, api.fragranceImport.bind(api));
	}

	getItemFromRow(row: any): FragranceImportDto {
		return {
			id: this.parseNumber(row.fragranceId) as FragranceId,
			brandId: this.parseNumber(row.brandId) as BrandId,
			name: row.name,
			active: this.parseBoolean(row.active),
			description: row.description,
			publicPhotos: this.removeBrackets(row.images)
				?.split(',')
				.map((i: string) => i.trim())
				.filter((i: string) => !!i)
				.map((fileId: string) => ({
					fileId,
					url: '',
				})),
			discontinued: this.parseBoolean(row.discontinued),
			olfactiveId: this.parseNumber(row.olfactiveId) as OlfactiveFamilyId,
			gender: row.gender as Gender,
			years: this.removeBrackets(row.years)
				?.split(',')
				.map((i: string) => this.parseNumber(i))
				.filter((i: number | undefined | null): i is number => !!i),
			perfumers: this.parseNumberArray<PerfumerId>(row.author),
			noseLongevity: this.parseEnum(Longevity, row.longevity),
			noseSillage: this.parseEnum(Longevity, row.sillage),
			brandBaseNotes: this.parseNumberArray(row.base),
			brandMidNotes: this.parseNumberArray(row.middle),
			brandTopNotes: this.parseNumberArray(row.top),
			noseNotes: this.parseNumberArray(row.user),
			synonyms: this.removeBrackets(row.synonyms)
				?.split(',')
				.map((i: string) => i.trim())
				.filter((i: string) => !!i),
		};
	}
}
