// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

.mainMenuItem {
	background-color: 'inherit';
	padding-left: 8px;
	padding-right: 8px;
	display: flex;
	align-items: center;
}

#app {
	display: flex;
	flex-direction: column;
	align-items: center;
}
`, "",{"version":3,"sources":["webpack://./style/globals.css"],"names":[],"mappings":"AAAA;;CAEC,UAAU;CACV,SAAS;AACV;;AAEA;CACC,cAAc;CACd,qBAAqB;AACtB;;AAEA;CACC,2BAA2B;CAC3B,iBAAiB;CACjB,kBAAkB;CAClB,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;AACpB","sourcesContent":["html,\nbody {\n\tpadding: 0;\n\tmargin: 0;\n}\n\na {\n\tcolor: inherit;\n\ttext-decoration: none;\n}\n\n.mainMenuItem {\n\tbackground-color: 'inherit';\n\tpadding-left: 8px;\n\tpadding-right: 8px;\n\tdisplay: flex;\n\talign-items: center;\n}\n\n#app {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
