import { DeleteForever, Draw, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import { Badge, Button, IconButton, SxProps, Tab, Tabs, TextField } from '@mui/material';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { FragranceService } from '~/service/service.fragrance';
import { UIService } from '~/service/service.ui';
import { FragrancePhotoDto, FragranceUpdateDto } from '~/shared/dto/admin/fragrance.dto';
import { FragrancePhotoStatus } from '~/shared/dto/model.enums';
import { Form } from '~/shared/form';
import { useService } from '~/shared/service/service.base';
import { FieldPhotos } from '~/view/fields/field.photos';
import { Box, Col, Row } from '~/view/view.box';
import { EditLeft } from '~/view/view.edit';

const sxTab: SxProps = {
	px: '4px',
	minWidth: '80px',
};

export const FragrancePhotos = observer(
	({ form, id, reload }: { id: number | undefined; form: Form<FragranceUpdateDto>; reload: () => void }) => {
		const [fragrances] = useService(FragranceService);
		const [imgTab, setImgTab] = useState('public' as 'public' | 'private' | 'hidden' | 'preliminary');

		return (
			<EditLeft minWidth={320}>
				<Tabs value={imgTab} onChange={(_, v) => setImgTab(v)} aria-label="image tabs" centered>
					<Tab
						sx={sxTab}
						title="Public photos (visible to customers)"
						value="public"
						label={
							<Badge badgeContent={form.$publicPhotos.value?.length ?? 0} color="primary">
								<Visibility />
							</Badge>
						}
					/>
					<Tab
						sx={sxTab}
						title="Preliminary photos (blurred)"
						value="preliminary"
						label={
							<Badge badgeContent={form.$preliminaryPhotos.value?.length ?? 0} color="primary">
								<Draw />
							</Badge>
						}
					/>
					<Tab
						sx={sxTab}
						title="Hidden photos (drafts)"
						value="hidden"
						label={
							<Badge badgeContent={form.$hiddenPhotos.value?.length ?? 0} color="primary">
								<VisibilityOff />
							</Badge>
						}
					/>
					<Tab
						sx={sxTab}
						title="Private user photos"
						value="private"
						label={
							<Badge
								badgeContent={form.$privatePhotos.value?.length ?? 0}
								color={form.$privatePhotos.value?.some(p => p.complaintStatus) ? 'error' : 'primary'}
							>
								<Person />
							</Badge>
						}
					/>
				</Tabs>
				{imgTab === 'public' && (
					<FieldPhotos
						field={form.$publicPhotos}
						header={({ photo }) => <PhotoToolbar photo={photo} form={form} status="PUBLIC" />}
						footer={({ photo }) => <PhotoFooter photo={photo} status="PUBLIC" />}
					/>
				)}
				{imgTab === 'preliminary' && (
					<FieldPhotos
						field={form.$preliminaryPhotos}
						header={({ photo }) => <PhotoToolbar photo={photo} form={form} status="PRELIMINARY" />}
						footer={({ photo }) => <PhotoFooter photo={photo} status="PRELIMINARY" />}
					/>
				)}
				{imgTab === 'hidden' && (
					<FieldPhotos
						field={form.$hiddenPhotos}
						header={({ photo }) => <PhotoToolbar photo={photo} form={form} status="HIDDEN" />}
						footer={({ photo }) => <PhotoFooter photo={photo} status="HIDDEN" />}
					/>
				)}
				{imgTab === 'private' && (
					<FieldPhotos
						field={form.$privatePhotos}
						disableAdd
						disableReorder
						header={({ photo }) => (
							<PhotoToolbar photo={photo} form={form} status="PRIVATE" disableDelete />
						)}
						footer={({ photo }) => (
							<Col>
								<PhotoFooter photo={photo} status="PRIVATE" />
								{photo.complaintStatus && photo.complainedByUser ? (
									<Col gap={1}>
										<Row color="red">
											Complained by {photo.complainedByUser}: {photo.complaintStatus}
										</Row>
										<Row gap={2}>
											<Button
												variant="outlined"
												onClick={() =>
													fragrances
														.changePhotoComplaint(id, photo.fileId, false)
														.done(reload)
												}
											>
												Confirm
											</Button>
											<Button
												variant="outlined"
												onClick={() =>
													fragrances.changePhotoComplaint(id, photo.fileId, true).done(reload)
												}
											>
												Clear
											</Button>
										</Row>
									</Col>
								) : null}
							</Col>
						)}
					/>
				)}
			</EditLeft>
		);
	},
);

const PhotoToolbar = observer(
	({
		photo,
		form,
		disableDelete,
		status,
	}: {
		photo: FragrancePhotoDto;
		form: Form<FragranceUpdateDto>;
		disableDelete?: boolean;
		status: FragrancePhotoStatus;
	}) => {
		const [ui] = useService(UIService);
		const action = status === 'PRIVATE' ? 'Copy' : 'Move';
		const currentField = form[`$${status.toLowerCase() as Lowercase<FragrancePhotoStatus>}Photos`];

		const handleDelete = useCallback(() => {
			currentField.set(currentField.value?.filter(i => i !== photo) ?? []);
			ui.notify('Photo removed', 'warning', 'top');
		}, [currentField, photo, ui]);

		const handleMove = useCallback(
			(field: Form.Field<FragrancePhotoDto[] | undefined>) => {
				if (field.value?.some(p => p.fileId === photo.fileId)) {
					ui.notify('Target category contains this photo already', 'warning', 'top');
				} else {
					if (status !== 'PRIVATE') {
						currentField.set(currentField.value?.filter(i => i !== photo) ?? []);
					}
					field.set([...(field.value ?? []), { ...photo, id: undefined }]);
					ui.notify(`Photo ${status === 'PRIVATE' ? 'copied' : 'moved'} successfully`, 'success', 'top');
				}
			},
			[currentField, photo, status, ui],
		);

		return (
			<Row flex={1}>
				{status !== 'PUBLIC' && (
					<IconButton
						sx={{ color: 'white' }}
						title={`${action} PUBLIC (visible to customers)`}
						onClick={() => handleMove(form.$publicPhotos)}
					>
						<Visibility />
					</IconButton>
				)}
				{status !== 'PRELIMINARY' && (
					<IconButton
						sx={{ color: 'white' }}
						title={`${action} to PRELIMINARY (blurred)`}
						onClick={() => handleMove(form.$preliminaryPhotos)}
					>
						<Draw />
					</IconButton>
				)}
				{status !== 'HIDDEN' && (
					<IconButton
						sx={{ color: 'white' }}
						title={`${action} to HIDDEN (drafts)`}
						onClick={() => handleMove(form.$hiddenPhotos)}
					>
						<VisibilityOff />
					</IconButton>
				)}
				<Box flexGrow={1} />
				{!disableDelete && (
					<IconButton sx={{ color: 'white' }} title="Delete photo permanently" onClick={handleDelete}>
						<DeleteForever />
					</IconButton>
				)}
			</Row>
		);
	},
);

const PhotoFooter = observer(({ photo, status }: { photo: FragrancePhotoDto; status: FragrancePhotoStatus }) => {
	return (
		<Col flex={1} px={1}>
			{status !== 'PRIVATE' && (
				<TextField
					fullWidth
					label="Caption"
					value={photo.caption ?? ''}
					onChange={action(e => (photo.caption = e.target.value))}
				/>
			)}
			<TextField
				fullWidth
				label="Author"
				value={photo.authorUser ?? photo.authorName ?? ''}
				disabled={!!photo.authorUser}
				onChange={action(e => (photo.authorName = e.target.value))}
			/>
		</Col>
	);
});
