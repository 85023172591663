import { field } from '../fields';

function idField<K extends number>(
	key: K,
	enumName?: string,
	topOpts?: field.NumberOpts,
): ((opts?: field.NumberOpts) => PropertyDecorator) & { type: K } {
	return ((opts?: field.NumberOpts) =>
		field.Number({
			int: true,
			min: 1,
			max: 0x7fff_ffff,
			apiOverride: { enum: [key], type: 'integer', enumName },
			...topOpts,
			...opts,
		})) as any;
}

export const BrandId = idField(42);
export type BrandId = typeof BrandId.type;

export const FragranceId = idField(43);
export type FragranceId = typeof FragranceId.type;

export const NoteId = idField(44);
export type NoteId = typeof NoteId.type;

export const OlfactiveFamilyId = idField(45);
export type OlfactiveFamilyId = typeof OlfactiveFamilyId.type;

export const PerfumerId = idField(46);
export type PerfumerId = typeof PerfumerId.type;

export const StaffId = idField(47);
export type StaffId = typeof StaffId.type;

export const UserId = idField(48, undefined, { max: Number.MAX_SAFE_INTEGER });
export type UserId = typeof UserId.type;

export const ExperienceId = idField(49);
export type ExperienceId = typeof ExperienceId.type;

export const FeedbackId = idField(50);
export type FeedbackId = typeof FeedbackId.type;

export const ShelfId = idField(51);
export type ShelfId = typeof ShelfId.type;

export const PostId = idField(52);
export type PostId = typeof PostId.type;

export const PostReactionId = idField(53);
export type PostReactionId = typeof PostReactionId.type;

export const StoreId = idField(54);
export type StoreId = typeof StoreId.type;

export const StoreOfferId = idField(55);
export type StoreOfferId = typeof StoreOfferId.type;

export const ChangeLogId = idField(56);
export type ChangeLogId = typeof ChangeLogId.type;

export const UserPhotoId = idField(57);
export type UserPhotoId = typeof UserPhotoId.type;

export const NoticeId = idField(58);
export type NoticeId = typeof NoticeId.type;

export const FragrancePhotoId = idField(59);
export type FragrancePhotoId = typeof FragrancePhotoId.type;

export const PushCampaignId = idField(60);
export type PushCampaignId = typeof PushCampaignId.type;

export const FragranceLinkId = idField(61);
export type FragranceLinkId = typeof FragranceLinkId.type;

export const IngredientId = idField(62);
export type IngredientId = typeof IngredientId.type;

export type CustomNoteId = 100;
export type NoteByUserId = 101;
export type NoteByBrandId = 102;
export type FragrancePerfumerId = 103;
export type UserDeviceId = 104;
export type ExperienceShelfId = 105;
export type ExperienceNoteId = 106;
export type ExperienceRemindId = 107;
export type FragranceIngredientId = 108;
export type ReviewReactionId = 108;

export const NOSE_USER_ID = 1 as UserId;
