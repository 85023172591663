import axios, { Axios } from 'axios';
import { C } from 'ts-toolbelt';

//const log = new Log({ name: 'HTTP', type: 'pretty' });

export function HttpService<T extends () => object>(
	factory: T,
): C.Class<[], ReturnType<T> & { readonly axios: Axios }> {
	const base = class {
		readonly axios = axios.create({
			// temp
			headers: {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
				Expires: '0',
			},
		});
	};
	const apis = factory();
	for (const [name, func] of Object.entries(apis)) {
		(base.prototype as any)[name] = async function (this: InstanceType<typeof base>, ...args: any[]) {
			const req = (func as any)(...args);
			return await this.axios(req);
			// return await log.try(`${req.method?.toUpperCase()} ${req.url}`, async p => {
			// 	p.finally({ params: req.params, body: req.data });
			// 	const resp = await this.axios(req);
			// 	p.finally({ resp: resp.data, status: resp.status });
			// 	return resp;
			// });
		};
	}
	return base as any;
}
