import { GridFilterModel } from '@mui/x-data-grid';
import { action, makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { FeedbackStatus } from '~/api/api.declaration';
import { GridStateModel } from '~/model/model.gridstate';
import { FeedbackReplyDto } from '~/shared/dto/admin/feedback.dto';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

@Singleton()
export class FeedbackService {
	readonly items = observable.array([] as API.Feedback[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('feedback');

	@observable totalCount = 0;
	@observable.ref selectedItem?: API.Feedback = undefined;
	@observable replyDialogVisible = false;

	private filterModel?: GridFilterModel = undefined;

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	@action.bound setSelectedItem(item: API.Feedback | undefined) {
		this.selectedItem = item;
	}

	@action.bound showReplyDialog() {
		this.replyDialogVisible = true;
	}

	@action.bound hideReplyDialog() {
		this.replyDialogVisible = false;
	}

	@action.bound changeFilterModel(model: GridFilterModel) {
		this.filterModel = model;
		this.reload();
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	getLastReply = () => {
		if (!this.selectedItem) return undefined;
		try {
			return JSON.parse(localStorage.getItem('feedback-reply-' + this.selectedItem.type) ?? '{}');
		} catch {
			return undefined;
		}
	};

	reload = () => {
		this.setSelectedItem(undefined);
		this.async.call(async () => {
			const { data } = await this.api.feedbackGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState.sort,
				sortDir: this.gridState.sortDir,
				filterModel: JSON.stringify(this.filterModel?.items.map(i => ({ ...i, id: undefined }))),
			});
			return () => {
				this.items.replace(data.items);
				this.totalCount = data.total;
			};
		});
	};

	resolveSelectedItem = () => {
		this.changeStatus('DONE');
	};

	reopenSelectedItem = () => {
		this.changeStatus('NEW');
	};

	declineSelectedItem = () => {
		this.changeStatus('DECLINED');
	};

	replyToSelected = (dto: FeedbackReplyDto) => {
		this.async.call(async () => {
			if (!this.selectedItem) return;
			const item = await this.api.feedbackReply(this.selectedItem.id, dto);
			localStorage.setItem(
				'feedback-reply-' + item.data.type,
				JSON.stringify({
					title: dto.title,
					message: dto.message,
					description: dto.description,
					imgLeft: dto.imgLeft,
					imgRight: dto.imgRight,
				}),
			);
			this.reload();
			return () => {
				this.selectedItem = item.data;
				this.replyDialogVisible = false;
			};
		});
	};

	protected changeStatus(status: FeedbackStatus) {
		this.async.call(async () => {
			if (!this.selectedItem) return;
			const item = await this.api.feedbackUpdateStatus(this.selectedItem.id, { status });
			this.reload();
			return () => {
				this.selectedItem = item.data;
			};
		});
	}
}
