import { singleton } from 'tsyringe';
import { getAromoshelfAdminAPI } from '~/api/api.declaration';
import { HttpService } from '~/shared/service/service.http';
import { EnvService } from './service.env';

@singleton()
export class ApiService extends HttpService(getAromoshelfAdminAPI) {
	constructor(env: EnvService) {
		super();
		this.axios.defaults.baseURL = env.API_URL;
	}

	async uploadImgs(images: undefined | string[]) {
		if (images) {
			const newImgIdx = images.reduce(
				(rv, url, idx) => (url.startsWith('blob:') ? [...rv, idx] : rv),
				[] as number[],
			);
			if (newImgIdx.length > 0) {
				const files = await Promise.all(newImgIdx.map(i => fetch(images[i]).then(r => r.blob())));
				const { data } = await this.uploadImages({ files });
				data.ids.forEach((id, i) => {
					images[newImgIdx[i]] = id;
				});
			}
		}
	}

	async uploadImg(img: undefined | string) {
		if (img?.startsWith('blob:')) {
			const blob = await fetch(img).then(resp => resp.blob());
			const { data } = await this.uploadImages({ files: [blob] });
			return data.ids[0];
		}
		return img!;
	}
}
