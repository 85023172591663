import { RemoveCircle } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem, Paper } from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useReducer, useRef } from 'react';
import { useTypedParams, useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { PushCampaignStatus } from '~/api/api.declaration';
import { AppRoutes, useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { PushCampaignService } from '~/service/service.push';
import { UIService } from '~/service/service.ui';
import {
	PushAudienceConditions,
	PushCampaignCreateDto,
	PushExperienceRatingCondition,
	PushUserCondition,
} from '~/shared/dto/admin/push.campaign.dto';
import { Enum } from '~/shared/fields';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FieldDate } from '~/view/fields/field.date';
import { FieldEnum } from '~/view/fields/field.enum';
import { FieldNumber, FieldNumberArray } from '~/view/fields/field.number';
import { FieldSwitch } from '~/view/fields/field.switch';
import { FieldText } from '~/view/fields/field.text';
import { Col, Text } from '~/view/view.box';
import { EditCenter, EditContainer, EditLeft, EditRight } from '~/view/view.edit';

export const PushEditPage = observer(() => {
	const [ui, auth, pushSvc] = useService(UIService, AuthService, PushCampaignService);
	const navBack = useNavBack();
	const { id } = useTypedParams(AppRoutes.PushEdit);
	const [{ clone }] = useTypedSearchParams(AppRoutes.PushNew);
	const [criteriaMenuOpen, toggleCriteriaMenu] = useReducer(x => !x, false);
	const btnAddCriteria = useRef<HTMLButtonElement>(null);

	const form = Form.use(PushCampaignCreateDto, {
		name: 'PushCampaignCreateDto',
		onSubmit: dto =>
			pushSvc.save(id, dto).then(() => {
				navBack();
				ui.notify('Campaign saved successfully', 'success');
			}),
		initial: () => pushSvc.getOne(id ?? clone),
	});

	ui.usePageTitle('New campaign');
	const canSave = id ? auth.can('perfumers', 'UPDATE') : auth.can('perfumers', 'CREATE');

	const addCriteria = useCallback(
		(criteria: PushAudienceConditions) => {
			form.$audienceConditions.set({ ...form.$audienceConditions.value, ...criteria });
			toggleCriteriaMenu();
		},
		[form.$audienceConditions],
	);

	const conditions = form.$audienceConditions.value;
	const status = clone ? 'DRAFT' : (form.initial && 'status' in form.initial && form.initial?.status) || 'DRAFT';

	return (
		<EditContainer async={pushSvc.async}>
			<EditLeft>
				{id && <FieldEnum field={form.$status} enumeration={PushCampaignStatus} disabled />}
				<FieldSwitch field={form.$sendPush} />
				<FieldSwitch field={form.$sendNotice} />
				<hr />
				<FieldSwitch field={form.$badge} />
				<FieldSwitch field={form.$sound} />
				<FieldSwitch field={form.$vibrate} />
				<FieldSwitch field={form.$light} />
				<hr />
				<FieldNumber field={form.$noticeTTL} />
				<FieldNumber field={form.$pushTTL} />
			</EditLeft>
			<EditCenter>
				<FieldText field={form.$campaignName} />
				<FieldDate
					field={form.$sendAt}
					disablePast
					view="minutes"
					disabled={status !== 'DRAFT' && status !== 'SCHEDULED'}
				/>
				<FieldText field={form.$title} />
				<FieldText field={form.$message} />
				<FieldText field={form.$description} multiline />
				<FieldText field={form.$analyticsLabel} />
				<FieldText field={form.$imgLeft} />
				<FieldText field={form.$imgRight} />
				<hr />
				<Text>User selection conditions:</Text>
				{Enum.keys(conditions).map(
					(type, i) =>
						conditions[type] && (
							<Fragment key={type}>
								<PushCriteria conditions={conditions} type={type} />
								{i < Object.keys(conditions).length - 1 && <Text align="center">AND</Text>}
							</Fragment>
						),
				)}
				<Button variant="outlined" onClick={toggleCriteriaMenu} ref={btnAddCriteria}>
					Add condition
				</Button>
				<Menu open={criteriaMenuOpen} onClose={toggleCriteriaMenu} anchorEl={btnAddCriteria.current}>
					<MenuItem disabled={!!conditions.user} onClick={() => addCriteria({ user: { userIds: [] } })}>
						Users by ID
					</MenuItem>
					<MenuItem
						disabled={!!conditions.experienceRating}
						onClick={() =>
							addCriteria({ experienceRating: { percentOfSetRates: 0, hasNoExperiences: false } })
						}
					>
						Experience rating
					</MenuItem>
				</Menu>
			</EditCenter>
			<EditRight>
				<Button
					variant="contained"
					onClick={() => {
						form.$status.set('DRAFT');
						form.submit();
					}}
					disabled={!canSave || status === 'CANCELED' || status === 'FINISHED'}
				>
					Save
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						form.$status.set('SCHEDULED');
						form.submit();
					}}
					disabled={!canSave || status !== 'DRAFT'}
				>
					Save and send
				</Button>
				<Button
					variant="outlined"
					onClick={() => id && pushSvc.stop(id).done(navBack)}
					color="warning"
					disabled={(status !== 'ONGOING' && status !== 'SCHEDULED') || !!clone}
				>
					Stop sending
				</Button>
				<br />
				<Button variant="outlined" onClick={navBack}>
					Cancel
				</Button>
				<Button variant="outlined" onClick={form.reset}>
					Reset
				</Button>
				{id && (
					<>
						<br />
						<Button
							disabled={!auth.can('push', 'DELETE')}
							variant="outlined"
							color="error"
							onClick={() =>
								ui.confirmDelete(() =>
									pushSvc.delete(id).then(() => {
										navBack();
										ui.notify('Push deleted successfully', 'success');
									}),
								)
							}
						>
							Delete
						</Button>
					</>
				)}
			</EditRight>
		</EditContainer>
	);
});

const PushCriteria = observer(
	({ conditions, type }: { conditions: PushAudienceConditions; type: keyof PushAudienceConditions }) => {
		const condition = conditions[type];
		const Component = criterias[type];

		if (!condition) return null;

		return (
			<Paper>
				<Col px={2} py={1} position="relative">
					<Component condition={condition as any} />
					<Col position="absolute" top={0} right={0}>
						<IconButton onClick={() => runInAction(() => (conditions[type] = undefined))}>
							<RemoveCircle />
						</IconButton>
					</Col>
				</Col>
			</Paper>
		);
	},
);

const criterias = {
	user: observer(({ condition }: { condition: PushUserCondition }) => {
		const form = Form.use(PushUserCondition, {
			initial: condition,
			name: 'PushUserCriteria',
			validateOn: 'change',
			onChange: d => runInAction(() => Object.assign(condition, d)),
		});

		return (
			<Col>
				<Text>Select users by their ID numbers (one id per line)</Text>
				<FieldNumberArray field={form.$userIds} />
			</Col>
		);
	}),

	experienceRating: observer(({ condition }: { condition: PushExperienceRatingCondition }) => {
		const form = Form.use(PushExperienceRatingCondition, {
			initial: condition,
			name: 'PushExperienceRatingCriteria',
			validateOn: 'change',
			onChange: d => runInAction(() => Object.assign(condition, d)),
		});

		return (
			<Col>
				<Text>Select users which has less than % of experiences with filled ratings</Text>
				<FieldNumber field={form.$percentOfSetRates} />
				<FieldSwitch field={form.$hasNoExperiences} />
			</Col>
		);
	}),
};
