import {
	Autocomplete,
	CircularProgress,
	ListItem,
	ListItemAvatar,
	ListItemText,
	TextField,
	TextFieldProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useReducer, useState } from 'react';
import { API } from '~/api';
import { FragranceId } from '~/api/api.declaration';
import { FragranceListModel } from '~/model/model.fragrance.list';
import { ApiService } from '~/service/service.api';
import { Form } from '~/shared/form';
import { useModel, useService } from '~/shared/service/service.base';

export type FieldFragranceProps = TextFieldProps & {
	field: Form.Field<FragranceId> | Form.Field<FragranceId | undefined>;
	nullLabel?: string;
};

export const FieldFragrance = observer<FieldFragranceProps>(({ field, nullLabel, ...props }) => {
	const { data, async, fetchNextPage, setFilter } = useModel(FragranceListModel);
	const [api] = useService(ApiService);
	const [initial, setInitial] = useState<API.FragranceItem>();
	const [open, toggleOpen] = useReducer(x => !x, false);

	useEffect(() => {
		if (!initial && field.value) {
			api.fragranceGetAll({ filter: field.value.toString() }).then(
				r => setInitial(r.data.items[0]),
				console.error,
			);
		}
	}, [api, field.value, initial]);

	return (
		<Autocomplete
			fullWidth
			open={open}
			onClose={toggleOpen}
			onOpen={toggleOpen}
			options={data.slice()}
			getOptionLabel={option => option.name}
			filterOptions={x => x}
			ListboxProps={{
				onScroll: (event: React.SyntheticEvent) => {
					const listboxNode = event.currentTarget;
					if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1) {
						fetchNextPage();
					}
				},
			}}
			value={data.find(({ id }) => field.value === id) ?? initial ?? null}
			onChange={(_, value) => field.set(value?.id)}
			renderOption={(props, frag) => {
				return (
					<ListItem {...props} key={frag.id}>
						<ListItemAvatar>
							<img src={frag.photo} height={48} alt={frag.name} />
						</ListItemAvatar>
						<ListItemText primary={frag.name} secondary={frag.brandName}></ListItemText>
					</ListItem>
				);
			}}
			onInputChange={(_, v) => {
				open && setFilter(v);
			}}
			renderInput={params => (
				<TextField
					{...props}
					{...params}
					error={!!field.hasError}
					helperText={field.helperText}
					label={field.label}
					variant="standard"
					InputProps={{
						...params.InputProps,
						endAdornment: async.loading ? <CircularProgress color="inherit" size={20} /> : null,
					}}
				/>
			)}
		/>
	);
});
