import { FileCopy } from '@mui/icons-material';
import { IconButton, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { PushCampaignService } from '~/service/service.push';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import { ActionsColumn, DateTimeColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';

export const PushListPage = observer(() => {
	const [pushSvc, ui, auth] = useService(PushCampaignService, UIService, AuthService);
	const columns = useGridColumns<API.PushCampaign>(() => [
		{ field: 'campaignName', headerName: 'Name', minWidth: 150 },
		DateTimeColumn({ field: 'createdAt', headerName: 'Created At' }),
		{ field: 'status', headerName: 'Status', minWidth: 120 },
		{ field: 'createdByName', headerName: 'Created By', minWidth: 150 },
		DateTimeColumn({ field: 'sendAt', headerName: 'Send At', treatAsLocalTime: true }),
		{ field: 'title', headerName: 'Title', flex: 1 },
		{ field: 'errorCount', headerName: 'Errors', minWidth: 50 },
		{ field: 'pushCount', headerName: 'Sent', minWidth: 50 },
		ActionsColumn({
			onDelete: pushSvc.delete,
			permission: 'push',
			editRoute: r => AppRoutes.PushEdit.buildPath({ id: r.id }),
			minWidth: 150,
			extra: r => (
				<Link to={AppRoutes.PushNew.buildPath({}, { clone: r.id })}>
					<IconButton disabled={!auth.can('push', 'CREATE')} onClick={() => {}}>
						<FileCopy />
					</IconButton>
				</Link>
			),
		}),
	]);

	ui.usePageTitle('Push campaigns');
	useEffect(() => pushSvc.loadAndSubscribe(), [pushSvc]);

	return (
		<GridContainer async={pushSvc.async}>
			<DataGrid
				initialState={pushSvc.gridState.initial()}
				loading={pushSvc.async.loading}
				rowCount={pushSvc.totalCount}
				rows={pushSvc.items.slice()}
				columns={columns}
				disableColumnFilter
				paginationMode="server"
				sortingMode="server"
				onStateChange={pushSvc.gridState.onStateChange}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No campaigns found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={pushSvc.reload}
							gridState={pushSvc.gridState}
							onCreateUrl={auth.can('push', 'CREATE') ? AppRoutes.PushNew.buildPath({}) : undefined}
							exportEnabled={false}
						/>
					),
				}}
			/>
		</GridContainer>
	);
});
