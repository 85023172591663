import { action, computed, makeObservable, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { API } from '~/api';
import { ApiService } from '~/service/service.api';
import { UIService } from '~/service/service.ui';
import { UserMessageDto } from '~/shared/dto/admin/user.dto';
import { ShelfId } from '~/shared/dto/id';
import { UserRole } from '~/shared/dto/model.enums';
import { Model } from '~/shared/service/service.base';
import { Async } from '~/shared/tools/async';
import { GridStateModel } from './model.gridstate';

@Model()
export class UserModel {
	readonly async = new Async();
	readonly experiences = observable.array([] as API.UserExperienceItem[], { deep: false });
	readonly posts = observable.array([] as API.UserPostItem[], { deep: false });
	readonly experiencesGrid = new GridStateModel('userexp');
	readonly postGrid = new GridStateModel('userpost');

	@observable experiencesCount = 0;
	@observable postCount = 0;
	@observable.ref user?: API.User = undefined;
	@observable.ref selectedShelfId: ShelfId | 0 = 0;
	@observable.ref selectedExperience?: Partial<API.UserExperience> = undefined;
	@observable adminComment = '';
	@observable sendMessageDialogVisible = false;

	@computed get isAdminCommentChanged() {
		return this.adminComment !== (this.user?.comment ?? '');
	}

	private userId = 0;

	constructor(private api: ApiService, private ui: UIService) {
		makeObservable(this);
	}

	@action.bound toggleSendMessageDialog() {
		this.sendMessageDialogVisible = !this.sendMessageDialogVisible;
	}

	@action.bound setSelectedShelf(id: ShelfId | 0) {
		this.selectedShelfId = id;
		this.reloadExperiences();
	}

	@action.bound setSelectedExperience(id: number | undefined) {
		this.selectedExperience = id ? this.experiences.find(x => x.id === id) : undefined;
		this.async.call(async () => {
			if (id) {
				const { data } = await this.api.userGetExperience(this.userId, id);
				return () => {
					this.selectedExperience = data;
				};
			}
		});
	}

	@action.bound setAdminComment(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		this.adminComment = e.target.value;
	}

	load = (id: number) => {
		this.userId = id;
		this.async.call(async () => {
			const { data } = await this.api.userGetOne(id);
			return () => {
				this.user = data;
				this.adminComment = data.comment || '';
			};
		});
	};

	loadExperiences = () => {
		this.reloadExperiences();
		return this.experiencesGrid.subscribe(this.reloadExperiences);
	};

	reloadExperiences = () => {
		this.async.call(async () => {
			const { data } = await this.api.userGetExperiences(this.userId, {
				filter: this.experiencesGrid.filterQuery,
				limit: this.experiencesGrid.pageSize,
				offset: this.experiencesGrid.offset,
				sort: this.experiencesGrid.sort,
				sortDir: this.experiencesGrid.sortDir,
				shelfId: this.selectedShelfId || undefined,
			});
			return () => {
				this.experiences.replace(data.items);
				this.experiencesCount = data.total;
			};
		});
	};

	loadPosts = () => {
		this.reloadPosts();
		return this.postGrid.subscribe(this.reloadPosts);
	};

	reloadPosts = () => {
		this.async.call(async () => {
			const { data } = await this.api.userGetPosts(this.userId, {
				filter: this.postGrid.filterQuery,
				limit: this.postGrid.pageSize,
				offset: this.postGrid.offset,
				sort: this.postGrid.sort,
				sortDir: this.postGrid.sortDir,
			});
			return () => {
				this.posts.replace(data.items);
				this.postCount = data.total;
			};
		});
	};

	saveAdminComment(): void {
		this.async.call(async () => {
			await this.api.userSetComment(this.userId, { comment: this.adminComment });
			this.load(this.userId);
		});
	}

	ban = () => {
		this.ui.confirm(`Are you sure to ban ${this.user?.nickName}?`, () =>
			this.async.call(async () => {
				await this.api.userSetStatus(this.userId, { status: 'BAN' });
				this.load(this.userId);
			}),
		);
	};

	unban = () => {
		this.async.call(async () => {
			await this.api.userSetStatus(this.userId, { status: 'ACTIVE' });
			this.load(this.userId);
		});
	};

	delete = () => {
		this.ui.confirmDelete(() =>
			this.async.call(async () => {
				await this.api.userDelete(this.userId);
				window.history.back();
			}),
		);
	};

	changeRole(role: UserRole): void {
		this.async.call(async () => {
			await this.api.userChangeRole(this.userId, { role });
			this.load(this.userId);
		});
	}

	sendMessage = (msg: UserMessageDto) => {
		this.async.call(async () => {
			await this.api.userSendMessage(this.userId, msg);
			return () => {
				this.sendMessageDialogVisible = false;
				this.ui.notify('Message sent', 'success');
			};
		});
	};
}
