import { Enum, field } from '../fields';

export const SortDir = Enum('ASC', 'DESC');
export type SortDir = Enum<typeof SortDir>;

export const FTSStrategy = Enum('FTS_V1', 'FTS_V2', 'FTS_V3', 'FTS_V4', 'FTS_V5');
export type FTSStrategy = Enum<typeof FTSStrategy>;

export class PagingDto {
	@field.Number({ int: true, min: 0, optional: true })
	offset = 0;

	@field.Number({ int: true, min: 1, max: 100, optional: true })
	limit = 20;
}

export class LangQueryDto {
	@field.String({ optional: true, trim: true })
	lang?: string;

	@field.Number({ int: true, min: 0, optional: true }) // TODO: hack, orval requires offset
	offset = 0;
}

export class PagingWithLangQueryDto extends PagingDto {
	@field.String({ optional: true, trim: true })
	lang?: string;
}

export class PagingFilterDto {
	@field.Number({ int: true, min: 0, optional: true })
	offset = 0;

	@field.Number({ int: true, min: 1, max: 100, optional: true })
	limit = 20;

	@field.String({ trim: true, optional: true })
	filter?: string;

	@field.Enum({ FTSStrategy }, { optional: true })
	strategy?: FTSStrategy;
}

export class PagingFilterSortDto {
	@field.Number({ int: true, min: 0, optional: true })
	offset = 0;

	@field.Number({ int: true, min: 1, max: 100, optional: true })
	limit = 20;

	@field.String({ trim: true, optional: true })
	filter?: string;

	@field.String({ trim: true, optional: true })
	sort?: string;

	@field.Enum({ SortDir }, { optional: true })
	sortDir?: SortDir;

	@field.String({ max: 4096, optional: true })
	filterModel?: string;

	@field.Enum({ FTSStrategy }, { optional: true })
	strategy?: FTSStrategy;
}

export class PagingResult {
	@field.Number({ int: true })
	total = 0;
}
