import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Avatar, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Rating } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { FragranceId } from '~/api/api.declaration';
import { AppRoutes } from '~/app/app.routes';
import { FragranceReviewModel } from '~/model/model.fragrance.review';
import { AuthService } from '~/service/service.auth';
import { useModel, useService } from '~/shared/service/service.base';
import { AsyncView } from '~/view/view.async';
import { Col, Row, Text } from '~/view/view.box';

export const FragranceEditReviews = observer<{ fragranceId: FragranceId }>(({ fragranceId }) => {
	const model = useModel(FragranceReviewModel);
	model.use(fragranceId);

	return (
		<Col>
			<Text>User reviews:</Text>
			<List sx={{ height: '50vh' }}>
				<Virtuoso
					data={model.data}
					endReached={model.fetchNextPage}
					components={{ Footer: () => <AsyncView async={model.async} /> }}
					itemContent={idx => <FragranceEditReviewItem model={model} index={idx} />}
				/>
			</List>
		</Col>
	);
});

const FragranceEditReviewItem = observer<{ index: number; model: FragranceReviewModel }>(({ index, model }) => {
	const [auth] = useService(AuthService);
	const item = model.data[index];

	return (
		<Fragment key={item.userId}>
			<ListItem
				key={item.userId}
				secondaryAction={
					auth.can('feedback', 'UPDATE') ? (
						<Col alignContent="flex-start">
							{item.moderation !== 'HIDDEN' && (
								<IconButton onClick={() => model.hideReview(item.userId)}>
									<VisibilityOff />
								</IconButton>
							)}
							{(item.moderation === 'NEED_MODERATION' || item.moderation === 'HIDDEN') && (
								<IconButton onClick={() => model.approveReview(item.userId)}>
									<Visibility />
								</IconButton>
							)}
						</Col>
					) : null
				}
			>
				<ListItemIcon>
					<Avatar />
				</ListItemIcon>
				<ListItemText
					primary={
						<Row gap={2} justifyContent="flex-start">
							<a
								href={AppRoutes.UserEdit.buildPath({ id: item.userId })}
								target="_blank"
								rel="noreferrer"
							>
								<Text variant="body2">{item.userNickName}</Text>
							</a>
							<Rating value={item.rating} size="small" readOnly />
							<Text>{item.moderation}</Text>
						</Row>
					}
					secondary={
						<>
							<Text variant="caption">{dayjs(item.createdAt).format()}</Text>
							<br />
							{item.review}
						</>
					}
				/>
			</ListItem>
			<Divider variant="inset" component="li" />
		</Fragment>
	);
});
