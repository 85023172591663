/* eslint-disable jsx-a11y/img-redundant-alt */
import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import { Drawer, IconButton, LinearProgress, MenuItem, SxProps, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { UserModel } from '~/model/model.user';
import { AvatarColumn, DateTimeColumn, LinkColumn, SecondaryColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';
import { AsyncView } from '~/view/view.async';
import { Col, Row, Text } from '~/view/view.box';

export const UserEditShelves = observer<{ model: UserModel }>(({ model }) => {
	const columns = useGridColumns<API.UserExperienceItem>(() => [
		AvatarColumn({ field: 'image', headerName: 'Photo' }),
		LinkColumn({
			field: 'fragranceName',
			headerName: 'Fragrance',
			minWidth: 200,
			sortable: false,
			getLink: row => AppRoutes.FragranceEdit.buildPath({ id: row.fragranceId }),
		}),
		LinkColumn({
			field: 'brandName',
			headerName: 'Brand',
			minWidth: 200,
			sortable: false,
			getLink: row => AppRoutes.BrandEdit.buildPath({ id: row.brandId }),
		}),
		DateTimeColumn({ field: 'createdAt', headerName: 'Added at' }),
		DateTimeColumn({ field: 'updatedAt', headerName: 'Updated at' }),
		{ field: 'concentration', headerName: 'Concentration', minWidth: 200 },
		SecondaryColumn({
			field: 'rating',
			headerName: 'Rating',
			secondary: row => (row.ratingDate ? dayjs(row.ratingDate).format('YYYY-DD-MM') : ''),
			minWidth: 150,
		}),
		{
			field: 'size',
			renderCell: ({ row }) =>
				row.volume && row.size === 'BOTTLE' ? (
					<>{`${row.volume} ${row.volumeSize ?? 'ml'}`}</>
				) : (
					<>{row.size}</>
				),
			headerName: 'Size',
			minWidth: 200,
		},
	]);

	useEffect(() => model.loadExperiences(), [model]);

	return (
		<AsyncView async={model.async}>
			<Col width="90vw" height="calc(100vh - 160px);">
				<ExperienceDrawer model={model} />
				<DataGrid
					initialState={model.experiencesGrid.initial()}
					loading={model.async.loading}
					rowCount={model.experiencesCount}
					rows={model.experiences.slice()}
					columns={columns}
					disableColumnFilter
					paginationMode="server"
					sortingMode="server"
					onStateChange={model.experiencesGrid.onStateChange}
					onRowClick={params => {
						model.setSelectedExperience(params.row.id);
					}}
					slots={{
						loadingOverlay: LinearProgress,
						noRowsOverlay: () => <GridNoRows>No fragrances on the shelf</GridNoRows>,
						pagination: MuiGridPagination,
						toolbar: () => (
							<GridToolbarView
								filterable={false}
								onReload={model.reloadExperiences}
								gridState={model.experiencesGrid}
							>
								<Row ml={4} minWidth={200}>
									<UserShelfSelector model={model} />
								</Row>
							</GridToolbarView>
						),
					}}
				/>
			</Col>
		</AsyncView>
	);
});

const UserShelfSelector = observer<{ model: UserModel }>(({ model }) => {
	const { user } = model;
	return (
		<TextField
			select
			fullWidth
			label="User shelf"
			value={model.selectedShelfId}
			onChange={e => model.setSelectedShelf(e.target.value as any)}
		>
			<MenuItem key={0} value={0}>
				All shelves
			</MenuItem>
			{user?.shelves.map(s => (
				<MenuItem key={s.id} value={s.id}>
					{s.name} ({s.fragranceCount ?? 0})
				</MenuItem>
			))}
		</TextField>
	);
});

const Item = styled.div({
	flexDirection: 'row',
	display: 'flex',
	justifyContent: 'space-between',
	borderBottom: 'solid #dadada 1px',
	paddingBottom: 3,
	gap: 16,
});
const Title = styled.div({ fontWeight: '600', fontFamily: 'sans-serif', fontSize: 14 });
const Value = styled.div({ fontFamily: 'Roboto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' });
const Anchor = styled.a({ textDecoration: 'underline', color: '#000' });

const ExperienceDrawer = observer<{ model: UserModel }>(({ model }) => {
	const { selectedExperience: exp, setSelectedExperience, async } = model;

	return (
		<Drawer sx={sxDrawer} PaperProps={{ elevation: 4 }} variant="persistent" anchor="right" open={!!exp}>
			<AsyncView async={async}>
				<Col gap={1}>
					<Row justifyContent="flex-start" py={1}>
						<IconButton onClick={() => setSelectedExperience(undefined)}>
							<Close />
						</IconButton>
					</Row>
					{exp?.photo && <img src={exp?.photo} width={300} height={300} alt="User photo" />}
					<Item>
						<Title>Fragrance:</Title>
						<Value>{exp?.fragranceName}</Value>
					</Item>
					<Item>
						<Title>Brand:</Title>
						<Value>{exp?.brandName}</Value>
					</Item>
					<Item>
						<Title>Created at:</Title>
						<Value>{dayjs(exp?.createdAt).format()}</Value>
					</Item>
					<Item>
						<Title>Concentration:</Title>
						<Value>{exp?.concentration ?? 'None'}</Value>
					</Item>
					<Item>
						<Title>Longevity:</Title>
						<Value>{exp?.longevity ?? 'None'}</Value>
					</Item>
					<Item>
						<Title>Sillage:</Title>
						<Value>{exp?.sillage ?? 'None'}</Value>
					</Item>
					<Item>
						<Title>Rating:</Title>
						<Value>
							{exp?.rating ? (
								<Text>{`${exp.rating} (${dayjs(exp.ratingDate).format('date')})`}</Text>
							) : (
								'None'
							)}
						</Value>
					</Item>
					<Item>
						<Title>Size:</Title>
						<Value>
							{exp?.size === 'BOTTLE' && exp?.volume ? (
								<Text>
									{exp.volume} {exp.volumeUnit ?? 'ml'}
								</Text>
							) : (
								<Text>{exp?.size}</Text>
							)}
						</Value>
					</Item>
					<Item>
						<Title>Batch code:</Title>
						<Value>{exp?.batchCode ?? 'None'}</Value>
					</Item>
					{exp?.comment && (
						<Item>
							<Title>Comment:</Title>
							<Value>{exp.comment}</Value>
						</Item>
					)}
					<Item>
						<Title>Year:</Title>
						<Value>{exp?.year}</Value>
					</Item>
					<Item>
						<Title>Shelves:</Title>
						<Value>
							{exp?.shelves?.map(s => (
								<Text key={s.id}>{s.name}</Text>
							))}
						</Value>
					</Item>
					<Item>
						<Title>Reminds:</Title>
						<Value>
							{exp?.reminds?.map(r => (
								<Anchor
									target="_blank"
									rel="noreferrer"
									key={r.id}
									href={AppRoutes.FragranceEdit.buildPath({ id: r.id })}
								>
									{r.name}
								</Anchor>
							))}
						</Value>
					</Item>
					<Item>
						<Title>Notes:</Title>
						<Value>
							{exp?.notes?.map((n, idx) =>
								n.id ? (
									<Anchor
										target="_blank"
										rel="noreferrer"
										key={idx}
										href={n.id ? AppRoutes.NoteEdit.buildPath({ id: n.id }) : '#'}
									>
										{n.name}
									</Anchor>
								) : (
									<Text key={idx}>Custom: {n.name}</Text>
								),
							)}
						</Value>
					</Item>
				</Col>
			</AsyncView>
		</Drawer>
	);
});

const sxDrawer: SxProps = {
	width: 350,
	flexShrink: 0,
	'& .MuiDrawer-paper': {
		width: 350,
		top: 64,
		px: 2,
		height: 'calc(100% - 64px)',
		boxSizing: 'border-box',
		zIndex: 1000,
	},
};
