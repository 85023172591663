import { PartialType } from 'nestjs-mapped-types';
import { field } from '../../fields';
import { BrandId } from '../id';

export class BrandChangeActiveDto {
	@field.Bool()
	active!: boolean;
}

export class BrandUpdateDto {
	@field.String({ trim: true, min: 1 })
	name!: string;

	@field.String({ trim: true, optional: true })
	description?: string;

	@field.String({ array: true, optional: true })
	synonyms?: string[];

	@field.String({ optional: true, trim: true })
	country?: string;

	@field.Number({ optional: true, min: 1500, max: 2500 })
	year?: number;

	@field.Bool({ maybe: true })
	active?: boolean;

	@field.String({ optional: true, trim: true, array: true })
	images?: string[];

	@field.String({ optional: true })
	comment?: string;
}

export class BrandCreateDto extends BrandUpdateDto {
	@BrandId()
	id!: BrandId;
}

export class BrandImportItem extends PartialType(BrandCreateDto) {
	@BrandId()
	id!: BrandId;
}

export class BrandImport {
	@field.Obj(BrandImportItem, { array: true })
	items!: BrandImportItem[];

	@field.String()
	fileName!: string;
}
