import { Toolbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet, Route, Routes as RoutesDom } from 'react-router-dom';
import { ChangeLogPage } from '~/page/page.changelog';
import { FeedbackListPage } from '~/page/page.feedback.list';
import { FragranceListPage } from '~/page/page.fragrance.list';
import { IndexPage } from '~/page/page.index';
import { IngredientEditPage } from '~/page/page.ingredient.edit';
import { IngredientListPage } from '~/page/page.ingredient.list';
import { NoteEditPage } from '~/page/page.note.edit';
import { NoteListPage } from '~/page/page.note.list';
import { PostEditPage } from '~/page/page.post.edit';
import { PostListPage } from '~/page/page.post.list';
import { PushEditPage } from '~/page/page.push.edit';
import { PushListPage } from '~/page/page.push.list';
import { RecommendsPage } from '~/page/page.recommends';
import { SettingsPage } from '~/page/page.settings';
import { SnapshotListPage } from '~/page/page.snapshot.list';
import { StopWordsPage } from '~/page/page.stopwords';
import { UserEditPage } from '~/page/page.user.edit';
import { UserListPage } from '~/page/page.user.list';
import { useService } from '~/shared/service/service.base';
import { Text } from '~/view/view.box';
import { NotificationView } from '~/view/view.notification';
import { BrandEditPage } from '../page/page.brand.edit';
import { BrandListPage } from '../page/page.brand.list';
import { ErrorPage } from '../page/page.error';
import { FragranceEditPage } from '../page/page.fragrance.edit';
import { PerfumerEditPage } from '../page/page.perfumer.edit';
import { PerfumerListPage } from '../page/page.perfumer.list';
import { SignInPage } from '../page/page.signin';
import { StaffEditPage } from '../page/page.staff.edit';
import { StaffListPage } from '../page/page.staff.list';
import { AuthService } from '../service/service.auth';
import { AppBarView } from '../view/view.appbar';
import { AppRoutes } from './app.routes';

export const AppView = observer(() => {
	const [auth] = useService(AuthService);

	useEffect(() => auth.loadMe(), [auth]);

	if (auth.async.loading) {
		return <>Loading...</>;
	} else if (auth.async.error || !auth.authenticated) {
		return <SignInPage />;
	} else if (!auth.authorized) {
		return <ErrorPage>Not authorized. Please wait while the administrator authorizes your signup</ErrorPage>;
	} else {
		return (
			<>
				<RoutesDom>
					<Route path="/" element={<MainLayout />}>
						<Route path={AppRoutes.Index.path} element={<IndexPage />} />
						<Route path={AppRoutes.BrandList.path} element={<BrandListPage />} />
						<Route path={AppRoutes.BrandEdit.path} element={<BrandEditPage />} />
						<Route path={AppRoutes.BrandNew.path} element={<BrandEditPage />} />
						<Route path={AppRoutes.NoteList.path} element={<NoteListPage />} />
						<Route path={AppRoutes.NoteEdit.path} element={<NoteEditPage />} />
						<Route path={AppRoutes.NoteNew.path} element={<NoteEditPage />} />
						<Route path={AppRoutes.FragranceList.path} element={<FragranceListPage />} />
						<Route path={AppRoutes.FragranceEdit.path} element={<FragranceEditPage />} />
						<Route path={AppRoutes.FragranceNew.path} element={<FragranceEditPage />} />
						<Route path={AppRoutes.PerfumerList.path} element={<PerfumerListPage />} />
						<Route path={AppRoutes.PerfumerEdit.path} element={<PerfumerEditPage />} />
						<Route path={AppRoutes.PerfumerNew.path} element={<PerfumerEditPage />} />
						<Route path={AppRoutes.StaffList.path} element={<StaffListPage />} />
						<Route path={AppRoutes.StaffEdit.path} element={<StaffEditPage />} />
						<Route path={AppRoutes.StaffEdit.path} element={<StaffEditPage />} />
						<Route path={AppRoutes.FeedbackList.path} element={<FeedbackListPage />} />
						<Route path={AppRoutes.ChangeLog.path} element={<ChangeLogPage />} />
						<Route path={AppRoutes.UserList.path} element={<UserListPage />} />
						<Route path={AppRoutes.UserEdit.path} element={<UserEditPage />} />
						<Route path={AppRoutes.PushList.path} element={<PushListPage />} />
						<Route path={AppRoutes.PushNew.path} element={<PushEditPage />} />
						<Route path={AppRoutes.PushEdit.path} element={<PushEditPage />} />
						<Route path={AppRoutes.IngredientList.path} element={<IngredientListPage />} />
						<Route path={AppRoutes.IngredientEdit.path} element={<IngredientEditPage />} />
						<Route path={AppRoutes.IngredientNew.path} element={<IngredientEditPage />} />
						<Route path={AppRoutes.SnapshotList.path} element={<SnapshotListPage />} />
						<Route path={AppRoutes.Settings.path} element={<SettingsPage />} />
						<Route path={AppRoutes.StopWords.path} element={<StopWordsPage />} />
						<Route path={AppRoutes.Recommends.path} element={<RecommendsPage />} />
						<Route path={AppRoutes.PostList.path} element={<PostListPage />} />
						<Route path={AppRoutes.PostNew.path} element={<PostEditPage />} />
						<Route path={AppRoutes.PostEdit.path} element={<PostEditPage />} />
						<Route
							path="*"
							element={<Text paddingTop={8}>404. Page not found or not implemented yet</Text>}
						/>
					</Route>
				</RoutesDom>
			</>
		);
	}
});

const MainLayout = observer(() => {
	return (
		<>
			<AppBarView />
			<Toolbar />
			<Outlet />
			<NotificationView />
		</>
	);
});
