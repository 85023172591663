import { TextField, TextFieldProps } from '@mui/material';
import { Form } from '~/shared/form/form';

export const FieldTextArray = Form.field<string[], TextFieldProps>(({ field, ...props }) => {
	return (
		<TextField
			fullWidth
			error={!!field.hasError}
			multiline
			rows={8}
			label={field.label}
			onChange={e => field.set(e.target.value.split('\n'))}
			value={field.value?.join('\n') ?? ''}
			helperText={field.helperText}
			{...props}
		/>
	);
});
