/* eslint-disable jsx-a11y/img-redundant-alt */
import { Delete, Visibility, VisibilityOff } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { UserModel } from '~/model/model.user';
import { PostService } from '~/service/service.post';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import {
	ActionSetColumn,
	AvatarColumn,
	CheckedColumn,
	DateTimeColumn,
	LinkColumn,
	SecondaryColumn,
	useGridColumns,
} from '~/view/grid/grid.columns';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';
import { AsyncView } from '~/view/view.async';
import { Col } from '~/view/view.box';

export const UserEditPosts = observer<{ model: UserModel }>(({ model }) => {
	const [postSvc, ui] = useService(PostService, UIService);

	const columns = useGridColumns<API.UserPostItem>(() => [
		CheckedColumn({
			field: 'moderation',
			headerName: 'Visible',
			valueGetter: ({ value }) => !value || value !== 'HIDDEN',
		}),
		AvatarColumn({ field: 'image', headerName: 'Photo' }),
		LinkColumn({
			field: 'fragranceName',
			headerName: 'Fragrance',
			minWidth: 200,
			sortable: false,
			getLink: row => AppRoutes.FragranceEdit.buildPath({ id: row.fragranceId }),
		}),
		DateTimeColumn({ field: 'createdAt', headerName: 'Added' }),
		DateTimeColumn({ field: 'updatedAt', headerName: 'Updated at' }),
		SecondaryColumn({
			field: 'temperature',
			sortable: false,
			headerName: 'Weather',
			valueGetter: ({ value }) => (value === null || value === undefined ? '' : `${value} °C`),
			secondary: row => row.weather ?? '',
			minWidth: 150,
		}),
		SecondaryColumn({ field: 'city', secondary: row => row.country ?? '', headerName: 'Location', minWidth: 100 }),
		{ field: 'rating', headerName: 'Rating', minWidth: 50 },
		{ field: 'lang', headerName: 'Lang', minWidth: 50 },
		{ field: 'text', headerName: 'Text', flex: 1 },
		ActionSetColumn({
			actions: [
				{
					label: 'Delete',
					icon: Delete,
					onClick: row => ui.confirmDelete(() => postSvc.delete(row.id).done(model.reloadPosts)),
				},
				{
					label: 'Hide',
					icon: VisibilityOff,
					visible: row => row.moderation !== 'HIDDEN',
					onClick: row => postSvc.hide(row.id).done(model.reloadPosts),
				},
				{
					label: 'Approve',
					icon: Visibility,
					visible: row => row.moderation === 'HIDDEN',
					onClick: row => postSvc.approve(row.id).done(model.reloadPosts),
				},
			],
		}),
	]);

	useEffect(() => model.loadPosts(), [model]);

	return (
		<AsyncView async={model.async}>
			<Col width="90vw" height="calc(100vh - 160px);">
				<DataGrid
					initialState={model.postGrid.initial()}
					loading={model.async.loading}
					rowCount={model.postCount}
					rows={model.posts.slice()}
					columns={columns}
					disableColumnFilter
					paginationMode="server"
					sortingMode="server"
					onStateChange={model.postGrid.onStateChange}
					slots={{
						loadingOverlay: LinearProgress,
						noRowsOverlay: () => <GridNoRows>No posts by this user</GridNoRows>,
						pagination: MuiGridPagination,
						toolbar: () => (
							<GridToolbarView
								filterable={false}
								onReload={model.reloadPosts}
								gridState={model.postGrid}
							></GridToolbarView>
						),
					}}
				/>
			</Col>
		</AsyncView>
	);
});
