import { Enum, field } from '../../fields';

export const StaffStatus = Enum('NEW', 'BAN', 'OK');
export type StaffStatus = Enum<typeof StaffStatus>;

export const PermissionAction = Enum('CREATE', 'READ', 'UPDATE', 'DELETE', 'EXPORT');
export type PermissionAction = Enum<typeof PermissionAction>;

export const UserPermissionAction = Enum.extend(PermissionAction, 'EMAIL');
export type UserPermissionAction = Enum<typeof UserPermissionAction>;

export class StaffUpdateDto {
	@field.Enum({ StaffStatus })
	status!: StaffStatus;

	@field.String({ optional: true })
	name?: string;

	@field.DateTime({ optional: true })
	expiresAt?: Date;

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	brandPermission!: PermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	fragrancePermission!: PermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	notesPermission!: PermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	perfumersPermission!: PermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	staffPermission!: PermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	feedbackPermission!: PermissionAction[];

	@field.Enum({ UserPermissionAction: UserPermissionAction }, { array: true })
	userPermission!: UserPermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	pushPermission!: PermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	snapshotPermission!: PermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	settingsPermission!: PermissionAction[];

	@field.Enum({ StaffPermission: PermissionAction }, { array: true })
	postPermission!: PermissionAction[];
}

export class StaffChangeStatusDto {
	@field.Enum({ StaffStatus })
	status!: StaffStatus;
}

export type StaffPermissions = { [P in PermissionKey]: StaffUpdateDto[`${P}Permission`][number] };

export type PermissionKey =
	Extract<keyof StaffUpdateDto, `${string}Permission`> extends `${infer K}Permission` ? K : never;
