import { Android, Apple, CheckCircle, Close, ContentCopy, HighlightOff, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	TextFieldProps,
} from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { UserModel } from '~/model/model.user';
import { UIService } from '~/service/service.ui';
import { UserMessageDto } from '~/shared/dto/admin/user.dto';
import { UserRole } from '~/shared/dto/model.enums';
import { Enum } from '~/shared/fields';
import { Form } from '~/shared/form';
import { useService } from '~/shared/service/service.base';
import { FieldText } from '~/view/fields/field.text';
import { AsyncView } from '~/view/view.async';
import { Col, Row, Text } from '~/view/view.box';

export const UserEditGeneral = observer<{ model: UserModel }>(({ model }) => {
	const { user } = model;

	return (
		<Row alignItems="flex-start" gap={4}>
			<UserMessageDialog model={model} />
			<Col flexGrow={1} maxWidth={600}>
				<Table size="small">
					<TableBody>
						<UserFormValue title="ID" value={user?.id} />
						<UserFormValue title="Role">
							<TextField
								select
								fullWidth
								value={user?.role ?? ''}
								onChange={e => model.changeRole(e.target.value as UserRole)}
							>
								{Enum.keys(UserRole).map(s => (
									<MenuItem key={s} value={s}>
										{s}
									</MenuItem>
								))}
							</TextField>
						</UserFormValue>
						<UserFormValue title="Email" value={user?.email} />
						<UserFormValue title="Nick name" value={user?.nickName} />
						<UserFormValue title="Status" value={user?.status} />
						<UserFormValue title="Fragrance vote weight" value={user?.fragranceVoteWeight} />
						<UserFormValue title="Auth provider" value={user?.oauthProvider} />
						<UserFormValue title="Referral code" value={user?.refLink} />
						<UserFormValue
							title="Admin comment"
							value={model.adminComment}
							multiline
							rows={3}
							disabled={false}
							onChange={model.setAdminComment}
							InputProps={
								model.isAdminCommentChanged
									? {
											endAdornment: (
												<IconButton onClick={() => model.saveAdminComment()}>
													<Save />
												</IconButton>
											),
									  }
									: undefined
							}
						/>
						<UserFormValue title="Bio" value={user?.bio} multiline rows={3} />
						<UserFormValue
							title="Nickname history"
							value={user?.nickNameHistory?.join('\n')}
							multiline
							rows={3}
						/>
					</TableBody>
				</Table>
			</Col>
			<Col alignItems="center" flexGrow={1} maxWidth={600} gap={4}>
				<Row alignItems="flex-start">
					<Table size="small">
						<TableBody>
							<UserFormValue title="Created at" value={user?.createdAt} />
							<UserFormValue title="Last seen at" value={user?.lastSeenAt} />
							<UserFormValue title="Activity date" value={user?.activityDate} />
							<UserFormValue title="Terms agreed at" value={user?.agreedTermsAt} />
						</TableBody>
					</Table>
					<Col gap={1}>
						<Button color="info" variant="contained" onClick={model.toggleSendMessageDialog}>
							SEND&nbsp;MESSAGE
						</Button>
						{user?.status === 'ACTIVE' && (
							<Button color="warning" variant="contained" onClick={model.ban}>
								BAN USER
							</Button>
						)}
						{user?.status === 'BAN' && (
							<Button color="success" variant="contained" onClick={model.unban}>
								UNBAN USER
							</Button>
						)}
						<Button color="error" variant="contained" onClick={model.delete}>
							DELETE&nbsp;USER
						</Button>
					</Col>
				</Row>
				<Text>Devices ({user?.devices.length}):</Text>
				<Col gap={1} alignSelf="stretch" pb={4}>
					{user?.devices
						.sort((a, b) => new Date(b.lastSeenAt ?? 0).getTime() - new Date(a.lastSeenAt ?? 0).getTime())
						.map(dev => (
							<Paper key={dev.id}>
								<ListItem
									key={dev.id}
									secondaryAction={dev.status === 'ACTIVE' ? <CheckCircle /> : <HighlightOff />}
								>
									<ListItemAvatar>
										{dev.brand?.toLowerCase() === 'apple' ? <Apple /> : <Android color="success" />}
									</ListItemAvatar>
									<ListItemText
										primary={`${dev.brand ?? 'Unknown brand'} ${
											dev.model ?? 'Unknown model'
										} (PxRatio: ${dev.pixelRatio ?? 'Unknown'})`}
										secondary={`${dev.appVersion ?? 'Unknown version'} last seen at ${dayjs(
											dev.lastSeenAt,
										).format()}\nLocation: ${dev.fineLocationPermission}, Notif: ${
											dev.notificationPermission
										}`}
										secondaryTypographyProps={{ style: { whiteSpace: 'pre' } }}
									/>
									<Text variant="body2">{dev.timezone}</Text>
								</ListItem>
							</Paper>
						))}
				</Col>
			</Col>
		</Row>
	);
});

function UserFormValue({
	title,
	value,
	children,
	...props
}: PropsWithChildren<{ title: string; value?: any } & TextFieldProps>) {
	const [ui] = useService(UIService);
	let val = value === null || value === undefined ? '' : value.toString();
	if (typeof value === 'string') {
		const dt = dayjs(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true);
		if (dt.isValid()) {
			val = dayjs(dt).format('YYYY-MM-DD HH:mm:ss');
		}
	}

	return (
		<TableRow>
			<TableCell sx={{ borderBottom: 0, whiteSpace: 'nowrap' }}>{title}</TableCell>
			<TableCell sx={{ minWidth: 300, borderBottom: 0 }}>
				{children ?? (
					<TextField
						value={val}
						disabled
						fullWidth
						InputProps={
							val
								? {
										endAdornment: (
											<IconButton
												onClick={() => {
													navigator.clipboard.writeText(value.toString());
													ui.notify('Copied to clipboard');
												}}
											>
												<ContentCopy />
											</IconButton>
										),
								  }
								: undefined
						}
						{...props}
					/>
				)}
			</TableCell>
		</TableRow>
	);
}

const UserMessageDialog = observer<{ model: UserModel }>(({ model }) => {
	const form = Form.use(UserMessageDto, {
		name: 'UserMessageDto',
		onSubmit: model.sendMessage,
	});

	return (
		<Dialog open={model.sendMessageDialogVisible} PaperProps={{ sx: { p: 2, width: 400 } }}>
			<AsyncView async={model.async}>
				<DialogTitle>
					<Row justifyContent="space-between">
						<Text>Send message to {model.user?.nickName}</Text>
						<IconButton onClick={model.toggleSendMessageDialog}>
							<Close />
						</IconButton>
					</Row>
				</DialogTitle>
				<DialogContent>
					<Col gap={2}>
						<FieldText field={form.$title} />
						<FieldText multiline field={form.$message} />
						<FieldText multiline field={form.$description} />
						<FieldText field={form.$analyticsLabel} />
						<FieldText field={form.$imgLeft} />
						<FieldText field={form.$imgRight} />
					</Col>
				</DialogContent>
				<DialogActions>
					<Button onClick={model.toggleSendMessageDialog}>Cancel</Button>
					<Button variant="contained" onClick={form.submit}>
						Send
					</Button>
				</DialogActions>
			</AsyncView>
		</Dialog>
	);
});
