import { Paper, SxProps, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { AppRoutes } from '~/app/app.routes';
import { UserModel } from '~/model/model.user';
import { UIService } from '~/service/service.ui';
import { useModel, useService } from '~/shared/service/service.base';
import { AsyncView } from '~/view/view.async';
import { UserEditGeneral } from './user.edit.general';
import { UserEditPosts } from './user.edit.posts';
import { UserEditShelves } from './user.edit.shelves';

export const UserEditPage = observer(() => {
	const [ui] = useService(UIService);
	const { id } = useTypedParams(AppRoutes.NoteEdit);
	const userModel = useModel(UserModel);
	const [tab, setTab] = useState('general' as 'general' | 'posts' | 'shelves');
	const { user, async } = userModel;

	ui.usePageTitle(user?.nickName ? `User - ${user.nickName}` : '');

	useEffect(() => {
		id && userModel.load(id);
	}, [id, userModel]);

	return (
		<AsyncView async={async}>
			<Paper elevation={0} sx={sxContainer}>
				<Tabs value={tab} onChange={(_, v) => setTab(v)} aria-label="user tabs" centered>
					<Tab label="General" value="general" />
					<Tab label="Shelves" value="shelves" />
					<Tab label="Posts" value="posts" />
				</Tabs>
				{tab === 'general' && <UserEditGeneral model={userModel} />}
				{tab === 'shelves' && <UserEditShelves model={userModel} />}
				{tab === 'posts' && <UserEditPosts model={userModel} />}
			</Paper>
		</AsyncView>
	);
});

const sxContainer: SxProps = {
	px: 1,
	pt: 1,
	pb: 0,
	display: 'flex',
	flexDirection: 'column',
	gap: '24px',
	width: '90vw',
};
