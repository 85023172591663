import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { C } from 'ts-toolbelt';
import { container, DependencyContainer, injectable, singleton } from 'tsyringe';

const ctx = createContext<DependencyContainer>(null!);

export interface Injector {
	<T extends Svc, A extends [...Svc[]]>(svc1: T, ...svcs: [...A]): [InstanceType<T>, ...InstanceTypes<A>];
}

type Svc = C.Class<[...any], any>;
// type ReadonlyTypes<Tuple extends [...Svc[]]> = {
// 	[Index in keyof Tuple]: Readonly<Tuple[Index] extends Svc ? InstanceType<Tuple[Index]> : 'NOT_A_CLASS'>;
// };
type InstanceTypes<Tuple extends [...Svc[]]> = {
	[Index in keyof Tuple]: Tuple[Index] extends Svc ? InstanceType<Tuple[Index]> : 'NOT_A_CLASS';
};

export const useService = ((...svcs: Svc[]) => {
	const app = useContext(ctx);
	return svcs.map(svc => app.resolve(svc));
}) as Injector;

export const AppContainerProvider: FC<PropsWithChildren> = ({ children }) => {
	return <ctx.Provider value={container}>{children}</ctx.Provider>;
};

export const Singleton = singleton;
export const Model = injectable;

export function useModel<T extends object>(modelClass: C.Class<[...any], T>): T {
	const app = useContext(ctx);
	const [model] = useState(() => app.resolve(modelClass));
	return model;
}
