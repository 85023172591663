import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { ASINType, Fragrance, FragranceId } from '~/api/api.declaration';
import { FragranceService } from '~/service/service.fragrance';
import { UIService } from '~/service/service.ui';
import { FragranceUpdateDto } from '~/shared/dto/admin/fragrance.dto';
import { Form } from '~/shared/form';
import { useService } from '~/shared/service/service.base';
import { FragranceOffersDialog } from '~/view/dialog/dialog.fragrance.offers';
import { Col, Row, Text } from '~/view/view.box';

const sortOrder: Record<ASINType, number> = {
	PRIMARY: 0,
	MANUAL: 1,
	VARIATION: 2,
	BLACKLIST: 3,
	POTENTIAL: 4,
};

export const FragranceAffiliate = observer<{ form: Form<FragranceUpdateDto, Fragrance> }>(({ form }) => {
	const [ui, fragSvc] = useService(UIService, FragranceService);
	const id = form.initial?.id;

	return (
		<Col>
			{id && <FragranceOffersDialog fragranceId={id as FragranceId} />}
			<Row gap={1} justifyContent="flex-start">
				<Button
					variant="contained"
					onClick={() => {
						id && fragSvc.refreshASIN(id);
					}}
				>
					Refresh ASIN from Amazon
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						ui.showDialog(FragranceOffersDialog);
					}}
				>
					Show offers
				</Button>
			</Row>
			{form.$asin.value
				.slice()
				.sort((a, b) => sortOrder[a.type] - sortOrder[b.type])
				.map(asin => {
					const initial = form.initial?.asin.find(a => a.asin === asin.asin);
					return (
						<Row
							justifyContent="flex-start"
							alignItems="flex-start"
							borderBottom="solid 1px #808080"
							gap={2}
							py={1}
							key={asin.asin}
						>
							<img
								src={initial?.images[0]}
								alt=""
								height={150}
								width={150}
								style={{ objectFit: 'contain' }}
							/>
							<Col py={1} flex={1}>
								<Text sx={{ textDecoration: 'underline' }}>
									<a href={initial?.url} target="_blank" rel="noreferrer">
										{initial?.title}
									</a>
								</Text>
								<Row gap={2}>
									<Col pl={2} flex={3}>
										<TitleValue title="ASIN: " value={asin.asin} />
										<TitleValue title="CreatedAt: " value={dayjs(initial?.createdAt).format()} />
										<TitleValue title="UpdatedAt: " value={dayjs(initial?.updatedAt).format()} />
										<TitleValue title="Type:" value={asin.type} />
									</Col>
									<Col flex={1} alignItems="flex-end">
										{(asin.type === 'PRIMARY' || asin.type === 'VARIATION') && (
											<Button
												variant="contained"
												color="warning"
												onClick={action(() => (asin.type = 'BLACKLIST'))}
											>
												TO BLACKLIST
											</Button>
										)}
										{asin.type === 'POTENTIAL' && (
											<Button
												variant="contained"
												color="success"
												onClick={action(() => (asin.type = 'MANUAL'))}
											>
												TO MANUAL
											</Button>
										)}
										{asin.type === 'MANUAL' && (
											<Button
												variant="contained"
												color="secondary"
												onClick={action(() => (asin.type = 'POTENTIAL'))}
											>
												TO POTENTIAL
											</Button>
										)}
										{asin.type === 'BLACKLIST' && (
											<Button
												variant="contained"
												color="primary"
												onClick={action(() => (asin.type = 'VARIATION'))}
											>
												TO VARIATION
											</Button>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					);
				})}
		</Col>
	);
});

const TitleValue = ({ title, value }: { title: ReactNode; value: ReactNode }) => {
	return (
		<Row gap={2}>
			<Text variant="body2" fontWeight="bold">
				{title}
			</Text>
			{typeof title === 'string' ? (
				<Text variant="body2" textAlign="right" flex={1}>
					{value}
				</Text>
			) : (
				value
			)}
		</Row>
	);
};
