import { createTheme } from '@mui/material';
import { blue, teal } from '@mui/material/colors';

export const appTheme = createTheme({
	palette: {
		primary: process.env.APP_ENV === 'production' ? teal : blue,
	},
	components: {
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'standard',
			},
		},
	},
	zIndex: {
		appBar: 1350,
	},
});
