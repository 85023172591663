import { action, computed, makeObservable, observable } from 'mobx';
import { container, singleton } from 'tsyringe';
import { API } from '~/api';
import { PermissionKey, StaffPermissions } from '~/shared/dto/admin/staff.dto';
import { Async } from '~/shared/tools/async';
import { ApiService } from './service.api';

const TOKEN_KEY = 'authtoken';

@singleton()
export class AuthService {
	@observable me?: API.StaffMe = undefined;
	readonly async = new Async();

	@computed get authenticated() {
		return !!this.me;
	}

	@computed get authorized() {
		return this.me?.status === 'OK';
	}

	constructor(private api: ApiService) {
		makeObservable(this);
		this.setToken(localStorage.getItem(TOKEN_KEY));
	}

	loadMe = () => {
		this.async.call(async () => {
			const { data } = await this.api.authMe();
			return () => {
				this.me = data;
				this.setUnauthorizedInterceptor();
			};
		});
	};

	signInWithGoogle = (token: string) => {
		this.setToken(undefined);
		return this.async.call(async () => {
			const { data } = await this.api.authSignInWithGoogle({ token });
			return () => {
				this.setToken(data.token);
				this.me = data.staff;
				localStorage.setItem(TOKEN_KEY, data.token);
				this.setUnauthorizedInterceptor();
			};
		});
	};

	tryLogout = () => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm('Are you sure you want to logout?')) {
			this.logout();
		}
	};

	@action.bound logout() {
		localStorage.removeItem(TOKEN_KEY);
		this.me = undefined;
		this.setToken(undefined);
		container.clearInstances();
		this.api.axios.interceptors.response.clear();
	}

	can<K extends PermissionKey>(type: K, permission: StaffPermissions[K]) {
		return this.me?.[`${type}Permission`].includes(permission as any) ?? false;
	}

	private setToken(token: string | null | undefined) {
		this.api.axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : undefined;
	}

	private setUnauthorizedInterceptor() {
		this.api.axios.interceptors.response.use(
			resp => resp,
			err => {
				if (err.response?.status === 401) {
					this.logout();
				}
				throw err;
			},
		);
	}
}
