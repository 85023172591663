import { TablePaginationProps } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import { GridPagination, gridPageSizeSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';

function Pagination({
	page,
	onPageChange,
	className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
	const apiRef = useGridApiContext();
	const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
	const pageCount = Math.ceil(apiRef.current.getRowsCount() / pageSize);

	return (
		<MuiPagination
			color="primary"
			className={className}
			count={pageCount}
			page={page + 1}
			onChange={(event, newPage) => {
				onPageChange(event as any, newPage - 1);
			}}
		/>
	);
}

export const MuiGridPagination = (props: any) => <GridPagination ActionsComponent={Pagination} {...props} />;
