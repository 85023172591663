import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import { Form } from '~/shared/form/form';

export const FieldText = Form.field<string, TextFieldProps & { password?: boolean }>(
	({ field, password, ...props }) => {
		const [pwdVisible, setPwdVisible] = useState(!!password);

		return (
			<TextField
				fullWidth
				type={password && pwdVisible ? 'password' : 'text'}
				error={!!field.hasError}
				label={field.label}
				onChange={field.onChange}
				value={field.value ?? ''}
				helperText={field.helperText}
				InputProps={{
					endAdornment: password ? (
						<IconButton color="inherit" onClick={() => setPwdVisible(!pwdVisible)}>
							{pwdVisible ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					) : null,
				}}
				{...props}
			/>
		);
	},
);
