import { action, makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { GridStateModel } from '~/model/model.gridstate';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

@Singleton()
export class ChangeLogService {
	readonly items = observable.array([] as API.ChangeLogItem[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('changelog');

	@observable totalCount = 0;
	@observable filterEntity?: API.ChangeLogEntity = undefined;
	@observable filterEntityId?: number = undefined;
	@observable selectedItem?: API.ChangeLogItem = undefined;

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	@action.bound setSelectedItem(item: API.ChangeLogItem | undefined) {
		this.selectedItem = item;
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.changeLogGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState?.sort,
				sortDir: this.gridState?.sortDir,
				entity: this.filterEntity,
				entityId: this.filterEntityId,
			});
			return () => {
				this.items.replace(data.items);
				this.totalCount = data.total;
			};
		});
	};

	@action.bound resetFilterByEntity() {
		this.filterEntity = undefined;
		this.filterEntityId = undefined;
		this.reload();
	}

	@action.bound filterByEntity(entity: API.ChangeLogEntity | undefined, id: string | number | undefined) {
		this.filterEntity = entity;
		this.filterEntityId = id ? +id : undefined;
	}

	async getOne(id: number | undefined) {
		if (id === undefined) return;
		return this.async.exec(() => this.api.brandGetOne(id).then(resp => resp.data));
	}
}
