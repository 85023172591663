import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { Async } from '~/shared/tools/async';
import { AsyncView } from '../view.async';
import { Col } from '../view.box';

export const GridContainer = observer<PropsWithChildren<{ async: Async }>>(({ async, children }) => {
	return (
		<Col mx={2} mt={1} width="90vw" height="calc(100vh - 72px);">
			<AsyncView async={async} withoutLoading>
				{children}
			</AsyncView>
		</Col>
	);
});
