import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { configure } from 'mobx';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'reflect-metadata';
import '~/style/globals.css';
import { i18n } from '~/text';
import { AppContainerProvider } from '../shared/service/service.base';
import { appTheme } from '../style/theme';
import { AppView } from './app.view';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend((option, dayjsClass, dayjsFactory) => {
	const oldFormat = dayjsClass.prototype.format;

	dayjsClass.prototype.format = function (formatString) {
		const fmt = formatString === 'date' ? 'YYYY-MM-DD' : formatString ?? 'YYYY-MM-DD HH:mm:ss';
		return oldFormat.call(this, fmt);
	};
});

configure({
	useProxies: 'never',
	enforceActions: 'always',
});

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
const router = createBrowserRouter([{ path: '*', element: <AppView /> }]);

process.env.APP_SENTRY_DSN &&
	Sentry.init({
		dsn: process.env.APP_SENTRY_DSN,
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

root.render(
	<Suspense fallback="loading">
		<I18nextProvider i18n={i18n}>
			<AppContainerProvider>
				<ThemeProvider theme={appTheme}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<RouterProvider router={router} />
					</LocalizationProvider>
				</ThemeProvider>
			</AppContainerProvider>
		</I18nextProvider>
	</Suspense>,
);
