import { makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { GridStateModel } from '~/model/model.gridstate';
import { PostCreateDto } from '~/shared/dto/admin/post.dto';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';
import { UIService } from './service.ui';

@Singleton()
export class PostService {
	readonly items = observable.array([] as API.PostItem[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('posts');
	@observable totalCount = 0;

	constructor(
		private api: ApiService,
		private ui: UIService,
	) {
		makeObservable(this);
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.postGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState.sort,
				sortDir: this.gridState.sortDir,
			});
			return () => {
				this.items.replace(data.items);
				this.totalCount = data.total;
			};
		});
	};

	async getOne(id: number | undefined) {
		if (id === undefined) return;
		return this.async.exec(() => this.api.postGetOne(id).then(resp => resp.data));
	}

	save(id: number | undefined, data: PostCreateDto) {
		return this.async.exec(async () => {
			if (id === undefined) {
				await this.api.postCreate(data);
			} else {
				await this.api.postUpdate(id, data);
			}
		});
	}

	delete = (id: number) => {
		return this.async.call(async () => {
			await this.api.postDelete(id);
			this.ui.notify('Post deleted', 'warning');
		});
	};

	hide = (id: number) => {
		return this.async.call(async () => {
			await this.api.postModerate(id, { moderationStatus: 'HIDDEN' });
			this.ui.notify('Post hidden', 'success');
		});
	};

	approve = (id: number) => {
		return this.async.call(async () => {
			await this.api.postModerate(id, { moderationStatus: 'APPROVED' });
			this.ui.notify('Post approved', 'success');
		});
	};
}
