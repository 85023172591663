import { field } from '../../fields';
import { PerfumerId } from '../id';

export class PerfumerUpdateDto {
	@field.String({ trim: true, min: 1 })
	name!: string;

	@field.String({ optional: true })
	description?: string;

	@field.String({ optional: true })
	company?: string;

	@field.String({ array: true, optional: true })
	images?: string[];

	@field.Bool({ optional: true })
	active = false;

	@field.String({ optional: true })
	comment?: string;
}

export class PerfumerCreateDto extends PerfumerUpdateDto {
	@PerfumerId()
	id!: PerfumerId;
}
