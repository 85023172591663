import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AppRoutes } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { IngredientService } from '~/service/service.ingredient';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import { ActionsColumn, AvatarColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';

export const IngredientListPage = observer(() => {
	const [ingredients, ui, auth] = useService(IngredientService, UIService, AuthService);
	const columns = useGridColumns<API.IngredientItem>(() => [
		AvatarColumn({ field: 'image', headerName: 'Image' }),
		{ field: 'id', headerName: 'ID' },
		{ field: 'name', headerName: 'Name', minWidth: 200 },
		{ field: 'casNumber', headerName: 'CAS', minWidth: 150 },
		{ field: 'vendor', headerName: 'Vendor', minWidth: 200 },
		{ field: 'description', headerName: 'Description', flex: 1 },
		ActionsColumn({
			editRoute: AppRoutes.IngredientEdit.buildPath,
			onDelete: ingredients.delete,
			permission: 'notes',
		}),
	]);

	ui.usePageTitle('Ingredients');
	useEffect(() => ingredients.loadAndSubscribe(), [ingredients]);

	return (
		<GridContainer async={ingredients.async}>
			<DataGrid
				initialState={ingredients.gridState.initial()}
				loading={ingredients.async.loading}
				rowCount={ingredients.totalCount}
				rows={ingredients.items.slice()}
				columns={columns}
				disableColumnFilter
				paginationMode="server"
				sortingMode="server"
				onStateChange={ingredients.gridState.onStateChange}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No ingredients found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={ingredients.reload}
							gridState={ingredients.gridState}
							onCreateUrl={
								auth.can('notes', 'CREATE') ? AppRoutes.IngredientNew.buildPath({}) : undefined
							}
							exportEnabled={auth.can('notes', 'EXPORT')}
						/>
					),
				}}
			/>
		</GridContainer>
	);
});
