import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { FC, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';
import { FragrancePhotoDto } from '~/shared/dto/admin/fragrance.dto';
import { Form } from '~/shared/form';
import { Col, Row, Text } from '../view.box';

const Img = styled('img')({
	maxWidth: 320,
	marginBottom: 16,
});

export const FieldPhotos = Form.field<
	FragrancePhotoDto[] | undefined,
	{
		disableReorder?: boolean;
		disableAdd?: boolean;
		header?: FC<{ photo: FragrancePhotoDto }>;
		footer?: FC<{ photo: FragrancePhotoDto }>;
	}
>(({ field, disableAdd, disableReorder, header: Header, footer: Footer }) => {
	const { value } = field;

	return (
		<DragDropContext
			onDragEnd={e => {
				if (!e.destination) return;
				const arr = [...(value ?? [])];
				const [removed] = arr.splice(e.source.index, 1);
				arr.splice(e.destination.index, 0, removed);
				field.set(arr);
			}}
		>
			<Col>
				<Droppable droppableId="images">
					{provided => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{value?.map((image, idx) => (
								<Draggable
									index={idx}
									draggableId={image.fileId ?? image.url}
									key={image.fileId ?? image.url}
									isDragDisabled={disableReorder}
								>
									{provided => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
										>
											<Paper
												elevation={3}
												sx={{
													mb: 2,
													pb: 1,
													px: 0,
													display: 'flex',
													flexDirection: 'column',
													alignContent: 'center',
													justifyContent: 'center',
													position: 'relative',
												}}
											>
												{Header && (
													<Row
														sx={{
															position: 'absolute',
															top: 0,
															left: 0,
															right: 0,
															px: 1,
															py: 1,
															background:
																'linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);',
															color: 'white',
														}}
													>
														<Header photo={image} />
													</Row>
												)}
												<Img src={value?.[idx].url} />
												{Footer && <Footer photo={image} />}
											</Paper>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
				<br />
				{!disableAdd && <DropField field={field} />}
			</Col>
		</DragDropContext>
	);
});

const DropDiv = styled('div')({
	border: '1px dashed #909090',
	padding: 20,
	textAlign: 'center',
	cursor: 'pointer',
});

const DropField = ({ field }: { field: Form.Field<any> }) => {
	const onDrop = useCallback(
		(files: File[]) => {
			console.log('onDrop', files);
			const images = files.filter(f => f.type.startsWith('image/')).map(f => URL.createObjectURL(f));
			if (images.length) field.set([...(field.value ?? []), ...images.map(url => ({ url }))]);
		},
		[field],
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<DropDiv {...getRootProps()}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<Text variant="body2">Drop the files here ...</Text>
			) : (
				<Text variant="body2">
					Drag 'n' drop some files here
					<br />
					or click to select files
				</Text>
			)}
		</DropDiv>
	);
};
