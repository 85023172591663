import { action, makeObservable, observable } from 'mobx';
import { ChangeEvent } from 'react';
import { API } from '~/api';
import { FTSStrategy } from '~/api/api.declaration';
import { GridStateModel } from '~/model/model.gridstate';
import { BrandCreateDto } from '~/shared/dto/admin/brand.dto';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';
import { LocalStorageService } from './service.localstorage';

@Singleton()
export class BrandService {
	readonly items = observable.array([] as API.Brand[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('brands');

	@observable totalCount = 0;
	@observable searchTime = undefined as number | undefined;
	@observable.ref names: API.BrandName[] = [];
	@observable searchStrategy: FTSStrategy = 'FTS_V1';

	constructor(
		private api: ApiService,
		private localStorage: LocalStorageService,
	) {
		this.searchStrategy = localStorage.getEnum('brandSearchStrategy', FTSStrategy, 'FTS_V1');
		makeObservable(this);
	}

	@action.bound changeSearchStrategy(strategy: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		this.searchStrategy = strategy.target.value as FTSStrategy;
		this.localStorage.setItem('brandSearchStrategy', this.searchStrategy);
		this.reload();
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.brandGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState?.sort,
				sortDir: this.gridState?.sortDir,
				strategy: this.searchStrategy,
			});
			return () => {
				this.items.replace(data.items);
				this.totalCount = data.total;
				this.searchTime = data.time;
			};
		});
	};

	async getOne(id: number | undefined) {
		if (id === undefined) return;
		return this.async.exec(() => this.api.brandGetOne(id).then(resp => resp.data));
	}

	save(id: number | undefined, data: BrandCreateDto) {
		return this.async.exec(async () => {
			await this.api.uploadImgs(data.images);
			if (id === undefined) {
				await this.api.brandCreate(data);
			} else {
				await this.api.brandUpdate(id, data);
			}
		});
	}

	delete = (id: number) => {
		return this.async.exec(() => this.api.brandDelete(id)).then(this.reload);
	};

	fetchNames = () => {
		this.async.call(async () => {
			const { data } = await this.api.brandGetNames();
			return () => (this.names = data.items);
		});
	};
}
