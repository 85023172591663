import { Row, Text } from './view.box';

export function PerformTimeView({ time }: { time: number | undefined }) {
	if (time === null || time === undefined) return null;

	return (
		<Row position="fixed" bottom={2} paddingLeft="18px">
			<Text variant="caption" noWrap color="#aaa">
				Search performed in {Math.round(time)}ms
			</Text>
		</Row>
	);
}
