import { Enum } from '../fields';
import { ShelfId } from './id';

export const Concentration = Enum(
	'OTHER',
	'EAU_DE_TOILETTE',
	'EAU_DE_PARFUM',
	'PERFUME',
	'EAU_DE_COLOGNE',
	'BODY_MIST',
	'HAIR_MIST',
	'SOLID_PERFUME',
	'OIL_PERFUME',
);
export type Concentration = Enum<typeof Concentration>;

export const FragranceSize = Enum('STRIP', 'DECANT', 'SAMPLE', 'BOTTLE');
export type FragranceSize = Enum<typeof FragranceSize>;

export const VolumeUnit = Enum('ML', 'OZ');
export type VolumeUnit = Enum<typeof VolumeUnit>;

export const Sillage = Enum('CLOSE_TO_SKIN', 'ARM_LENGTH', 'FEW_METERS', 'FILLS_ROOM');
export type Sillage = Enum<typeof Sillage>;

export const Longevity = Enum('LESS_THAN_HOUR', 'ABOUT_1_HOUR', 'ABOUT_5_HOURS', 'ABOUT_10_HOURS', 'MORE_12_HOURS');
export type Longevity = Enum<typeof Longevity>;

export const Gender = Enum('MALE', 'FEMALE', 'UNISEX');
export type Gender = Enum<typeof Gender>;

export const Season = Enum('WINTER', 'SPRING', 'SUMMER', 'AUTUMN');
export type Season = Enum<typeof Season>;

export const NoteType = Enum('TOP', 'MIDDLE', 'BASE');
export type NoteType = Enum<typeof NoteType>;

export const Occasion = Enum('DAILY', 'NIGHTLY', 'OFFICE', 'SPORTS', 'SPECIAL', 'LEASURE', 'BEDTIME');
export type Occasion = Enum<typeof Occasion>;

export const Weather = Enum('RAIN', 'SNOW', 'SUNNY', 'WINDY');
export type Weather = Enum<typeof Weather>;

export const Humidity = Enum('HIGH', 'LOW', 'MEDIUM');
export type Humidity = Enum<typeof Humidity>;

export const Temperature = Enum('HOT', 'COLD', 'WARM');
export type Temperature = Enum<typeof Temperature>;

export const UserRelationType = Enum('FOLLOW', 'BLOCK');
export type UserRelationType = Enum<typeof UserRelationType>;

export const ComplaintType = Enum(
	'SPAM',
	'NUDITY',
	'HATE',
	'ILLEGAL',
	'FALSE',
	'BULLING',
	'SCAM',
	'VIOLENCE',
	'PROPERTY',
	'OTHER',
	'ADS',
	'INAPPROPRIATE',
	'NOTMATCH',
);
export type ComplaintType = Enum<typeof ComplaintType>;

export const PhotoComplaints = new Set(['INAPPROPRIATE', 'NOTMATCH', 'ADS']);

export const Reaction = Enum('LIKE');
export type Reaction = Enum<typeof Reaction>;

export const FragrancePhotoStatus = Enum('PUBLIC', 'PRIVATE', 'HIDDEN', 'PRELIMINARY');
export type FragrancePhotoStatus = Enum<typeof FragrancePhotoStatus>;

export const SystemShelves = {
	I_HAVE: 1 as ShelfId,
	I_HAD: 2 as ShelfId,
	TO_TEST: 3 as ShelfId,
	TESTED: 4 as ShelfId,
	WISHLIST: 5 as ShelfId,
};

export const UserStatus = Enum('NEW', 'ACTIVE', 'BAN', 'FAKE');
export type UserStatus = Enum<typeof UserStatus>;

export const UserRole = Enum('REGULAR', 'TESTER', 'WRITER');
export type UserRole = Enum<typeof UserRole>;

export const ModerationStatus = Enum('NEED_MODERATION', 'WAIT_MODERATION', 'APPROVED', 'HIDDEN');
export type ModerationStatus = Enum<typeof ModerationStatus>;
