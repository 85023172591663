import { field } from '../../fields';
import { IngredientId } from '../id';

export class IngredientUpdateDto {
	@field.String({ trim: true, min: 1 })
	name!: string;

	@field.String({ array: true, optional: true })
	images?: string[];

	@field.String({ min: 0, max: 255, optional: true })
	casNumber?: string;

	@field.String({ min: 0, max: 255, optional: true })
	vendor?: string;

	@field.String({ min: 0, max: 1024, optional: true })
	description?: string;

	@IngredientId({ array: true, optional: true })
	analogs?: IngredientId[];
}

export class IngredientCreateDto extends IngredientUpdateDto {}
