import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { StaffStatus } from '~/api/api.declaration';
import { AppRoutes, useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { StaffService } from '~/service/service.staff';
import { UIService } from '~/service/service.ui';
import { PermissionAction, StaffUpdateDto, UserPermissionAction } from '~/shared/dto/admin/staff.dto';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FieldDate } from '~/view/fields/field.date';
import { FieldEnum, FieldMultiEnum } from '~/view/fields/field.enum';
import { FieldText } from '~/view/fields/field.text';
import { Text } from '~/view/view.box';
import { EditCenter, EditContainer, EditLeft, EditRight } from '~/view/view.edit';

export const StaffEditPage = observer(() => {
	const [ui, auth, staff] = useService(UIService, AuthService, StaffService);
	const navBack = useNavBack();
	const { id } = useTypedParams(AppRoutes.StaffEdit);

	const form = Form.use(StaffUpdateDto, {
		name: 'StaffUpdateDto',
		onSubmit: dto =>
			staff.update(id, dto).then(() => {
				navBack();
				ui.notify('Staff saved successfully', 'success');
			}),
		initial: () => staff.getOne(id),
	});

	ui.usePageTitle(form.initial?.email ? `Staff - ${form.initial?.email}` : '');

	return (
		<EditContainer async={staff.async}>
			<EditLeft>
				<img src={form.initial?.avatar} alt="Avatar" />
				<Text fontWeight="bold">Created at:</Text>
				<Text variant="body2">{ui.formatDateTime(form.initial?.updatedAt)}</Text>
				<br />
				<Text fontWeight="bold">Updated at:</Text>
				<Text variant="body2">{ui.formatDateTime(form.initial?.updatedAt)}</Text>
				<br />
				<Text fontWeight="bold">Last seen at:</Text>
				<Text variant="body2">{ui.formatDateTime(form.initial?.lastSeenAt)}</Text>
			</EditLeft>
			<EditCenter>
				<FieldText field={form.$name} />
				<FieldEnum field={form.$status} enumeration={StaffStatus} />
				<FieldDate field={form.$expiresAt} />
				<FieldMultiEnum field={form.$brandPermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$fragrancePermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$perfumersPermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$notesPermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$feedbackPermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$staffPermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$userPermission} enumeration={UserPermissionAction} />
				<FieldMultiEnum field={form.$pushPermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$snapshotPermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$settingsPermission} enumeration={PermissionAction} />
				<FieldMultiEnum field={form.$postPermission} enumeration={PermissionAction} />
			</EditCenter>
			<EditRight>
				<Button variant="contained" onClick={form.submit} disabled={!auth.can('staff', 'UPDATE')}>
					Save
				</Button>
				<br />
				<Button variant="outlined" onClick={navBack}>
					Cancel
				</Button>
				<Button variant="outlined" onClick={form.reset}>
					Reset
				</Button>
				{id && (
					<>
						<br />
						<Button
							disabled={id === auth.me?.id || !auth.can('perfumers', 'DELETE')}
							variant="outlined"
							color="error"
							onClick={() =>
								ui.confirmDelete(() =>
									staff.delete(id).then(() => {
										navBack();
										ui.notify('Staff deleted successfully', 'success');
									}),
								)
							}
						>
							Delete
						</Button>
					</>
				)}
			</EditRight>
		</EditContainer>
	);
});
