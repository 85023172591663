import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { AppRoutes, useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { PostService } from '~/service/service.post';
import { UIService } from '~/service/service.ui';
import { UserService } from '~/service/service.user';
import { PostCreateDto } from '~/shared/dto/admin/post.dto';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FieldDate } from '~/view/fields/field.date';
import { FieldFragrance } from '~/view/fields/field.fragrance';
import { FieldNumber } from '~/view/fields/field.number';
import { FieldSelect } from '~/view/fields/field.select';
import { FieldText } from '~/view/fields/field.text';
import { EditCenter, EditContainer, EditRight } from '~/view/view.edit';

export const PostEditPage = observer(() => {
	const [ui, auth, posts, users] = useService(UIService, AuthService, PostService, UserService);
	const navBack = useNavBack();
	const { id } = useTypedParams(AppRoutes.PostEdit);

	const form = Form.use(PostCreateDto, {
		name: 'PostCreateDto',
		onSubmit: dto =>
			posts.save(id, dto).then(() => {
				navBack();
				ui.notify('Post saved successfully', 'success');
			}),
		initial: () => posts.getOne(id),
	});

	useEffect(() => {
		users.getWriters();
	}, [users]);

	ui.usePageTitle('Post');
	const canSave = id ? auth.can('post', 'UPDATE') : auth.can('post', 'CREATE');

	return (
		<EditContainer async={posts.async}>
			<EditCenter>
				<FieldFragrance field={form.$fragranceId} />
				<FieldSelect field={form.$userId} items={users.writers} labelProp="nickName" valueProp="id" />
				<FieldDate field={form.$publishAt} />
				<FieldText field={form.$country} />
				<FieldText field={form.$city} />
				<FieldText field={form.$weather} />
				<FieldNumber field={form.$rating} />
				<FieldNumber field={form.$temperature} />
				<FieldText multiline field={form.$text} />
			</EditCenter>
			<EditRight>
				<Button variant="contained" onClick={form.submit} disabled={!canSave}>
					Save
				</Button>
				<br />
				<Button variant="outlined" onClick={navBack}>
					Cancel
				</Button>
				<Button variant="outlined" onClick={form.reset}>
					Reset
				</Button>
				{id && (
					<>
						<br />
						<Button
							disabled={!auth.can('post', 'DELETE')}
							variant="outlined"
							color="error"
							onClick={() =>
								ui.confirmDelete(() =>
									posts.delete(id).done(() => {
										navBack();
										ui.notify('Post deleted successfully', 'success');
									}),
								)
							}
						>
							Delete
						</Button>
					</>
				)}
			</EditRight>
		</EditContainer>
	);
});
