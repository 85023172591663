import { CreateNewFolder, Delete } from '@mui/icons-material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Button, IconButton, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { API } from '~/api';
import { AuthService } from '~/service/service.auth';
import { SnapshotService } from '~/service/service.snapshot';
import { UIService } from '~/service/service.ui';
import { useService } from '~/shared/service/service.base';
import { ActionsColumn, DateTimeColumn, useGridColumns } from '~/view/grid/grid.columns';
import { GridContainer } from '~/view/grid/grid.container';
import { GridNoRows } from '~/view/grid/grid.norows';
import { MuiGridPagination } from '~/view/grid/grid.pagination';
import { GridToolbarView } from '~/view/grid/grid.toolbar';
import { Row, Text } from '~/view/view.box';

export const SnapshotListPage = observer(() => {
	const [snapSvc, ui, auth] = useService(SnapshotService, UIService, AuthService);
	const columns = useGridColumns<API.Snapshot>(() => [
		DateTimeColumn({ field: 'createdAt', headerName: 'Created at', minWidth: 200 }),
		{ field: 'createdBy', headerName: 'Created by', flex: 1 },
		ActionsColumn({
			editRoute: null,
			permission: 'snapshot',
			extra: item => (
				<>
					<IconButton
						disabled={!auth.can('snapshot', 'DELETE')}
						onClick={() => {
							ui.confirmDelete(() =>
								snapSvc.delete(item.id).then(() => ui.notify('Deleted successfully', 'success')),
							);
						}}
					>
						<Delete />
					</IconButton>
					<IconButton
						disabled={!auth.can('snapshot', 'UPDATE')}
						onClick={() => {
							if (
								prompt(
									`Are you sure to restore backup ${item.id}? This change is not revertible! You can loose information. Type YES to confirm.`,
								)?.toLowerCase() === 'yes'
							) {
								snapSvc
									.restore(item.id)
									.then(() => ui.notify('Backup restored successfully', 'success'));
							} else {
								ui.notify('Restore backup cancelled', 'warning');
							}
						}}
					>
						<SettingsBackupRestoreIcon />
					</IconButton>
				</>
			),
		}),
	]);

	ui.usePageTitle('Snapshots');
	useEffect(() => snapSvc.loadAndSubscribe(), [snapSvc]);

	return (
		<GridContainer async={snapSvc.async}>
			<DataGrid
				initialState={snapSvc.gridState.initial()}
				loading={snapSvc.async.loading}
				rowCount={snapSvc.totalCount}
				rows={snapSvc.items.slice()}
				columns={columns}
				disableColumnFilter
				paginationMode="server"
				sortingMode="server"
				onStateChange={snapSvc.gridState.onStateChange}
				slots={{
					loadingOverlay: LinearProgress,
					noRowsOverlay: () => <GridNoRows>No snapshots found</GridNoRows>,
					pagination: MuiGridPagination,
					toolbar: () => (
						<GridToolbarView
							onReload={snapSvc.reload}
							gridState={snapSvc.gridState}
							exportEnabled={false}
							rightMenu={
								<>
									<Button size="small" startIcon={<CreateNewFolder />} onClick={snapSvc.create}>
										Create New
									</Button>
								</>
							}
						>
							<Row pl={2}>
								{snapSvc.restoreStatus === 'PROGRESS' ? (
									<Button variant="contained" color="warning" onClick={snapSvc.cancelRestore}>
										Restoring in progress. Press to cancel
									</Button>
								) : snapSvc.restoreStatus === 'ERROR' ? (
									<Text color="red">Restore error. Contact system administrator</Text>
								) : snapSvc.restoreStatus === 'FINISHED' ? (
									<Text color="green">Restore finished successfully</Text>
								) : snapSvc.restoreStatus === 'CANCELLED' ? (
									<Text color="orange">Restore cancelled</Text>
								) : null}
							</Row>
						</GridToolbarView>
					),
				}}
			/>
		</GridContainer>
	);
});
