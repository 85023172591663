import { action, makeObservable, observable } from 'mobx';
import { useEffect } from 'react';
import { API } from '~/api';
import { FragranceId } from '~/api/api.declaration';
import { ApiService } from '~/service/service.api';
import { Model } from '~/shared/service/service.base';
import { Async } from '~/shared/tools/async';

@Model()
export class FragranceOffersModel {
	@observable data = [] as API.AffiliateOffer[];
	readonly async = new Async();
	private fragranceId?: FragranceId;

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	use = (fragranceId: FragranceId) => {
		this.fragranceId = fragranceId;
		useEffect(() => {
			this.fetchNextPage();
		}, []);
	};

	fetchNextPage = () => {
		this.async.call(async () => {
			if (this.fragranceId) {
				const { data } = await this.api.fragranceGetAffifliateOffers(this.fragranceId);
				return () => {
					this.data = data.offers;
				};
			}
		});
	};

	@action.bound reload() {
		this.fetchNextPage();
	}
}
