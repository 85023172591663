import { makeObservable, observable } from 'mobx';
import { API } from '~/api';
import { GridStateModel } from '~/model/model.gridstate';
import { Async } from '~/shared/tools/async';
import { Singleton } from '../shared/service/service.base';
import { ApiService } from './service.api';

@Singleton()
export class SnapshotService {
	readonly items = observable.array([] as API.Snapshot[], { deep: false });
	readonly async = new Async();
	readonly gridState = new GridStateModel('notes');

	@observable totalCount = 0;
	@observable restoreStatus: API.SnapshotRestoreStatus = 'IDLE';

	constructor(private api: ApiService) {
		makeObservable(this);
	}

	loadAndSubscribe = () => {
		this.reload();
		return this.gridState.subscribe(this.reload);
	};

	reload = () => {
		this.async.call(async () => {
			const { data } = await this.api.snapshotGetAll({
				filter: this.gridState.filterQuery,
				limit: this.gridState.pageSize,
				offset: this.gridState.offset,
				sort: this.gridState.sort,
				sortDir: this.gridState.sortDir,
			});
			return () => {
				this.items.replace(data.items);
				this.restoreStatus = data.restoreStatus;
				this.totalCount = data.total;
			};
		});
	};

	create = () => {
		return this.async.exec(async () => this.api.snapshotCreate()).then(this.reload);
	};

	cancelRestore = () => {
		return this.async.exec(async () => this.api.snapshotCancel()).then(this.reload);
	};

	restore(id: string) {
		return this.async.exec(async () => {
			await this.api.snapshotRestore(id).then(this.reload);
		});
	}

	delete = (id: string) => {
		return this.async.exec(() => this.api.snapshotDelete(id)).then(this.reload);
	};
}
