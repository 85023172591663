import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { AppRoutes, useNavBack } from '~/app/app.routes';
import { AuthService } from '~/service/service.auth';
import { NoteService } from '~/service/service.note';
import { UIService } from '~/service/service.ui';
import { NoteCreateDto } from '~/shared/dto/admin/note.dto';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FieldImages } from '~/view/fields/field.images';
import { FieldNumber } from '~/view/fields/field.number';
import { FieldSwitch } from '~/view/fields/field.switch';
import { FieldText } from '~/view/fields/field.text';
import { FieldTextArray } from '~/view/fields/field.textarray';
import { EditCenter, EditContainer, EditLeft, EditRight } from '~/view/view.edit';

export const NoteEditPage = observer(() => {
	const [ui, auth, notes] = useService(UIService, AuthService, NoteService);
	const navBack = useNavBack();
	const { id } = useTypedParams(AppRoutes.NoteEdit);

	const form = Form.use(NoteCreateDto, {
		name: 'NoteCreateDto',
		onSubmit: dto =>
			notes.save(id, dto).then(() => {
				navBack();
				ui.notify('Note saved successfully', 'success');
			}),
		initial: () => notes.getOne(id),
	});

	ui.usePageTitle(form.initial?.name ? `Note - ${form.initial?.name}` : '');
	const canSave = id ? auth.can('notes', 'UPDATE') : auth.can('notes', 'CREATE');

	return (
		<EditContainer async={notes.async}>
			<EditLeft>
				<FieldImages field={form.$images} images={form.initial?.images} />
			</EditLeft>
			<EditCenter>
				<FieldNumber disabled={!!id} field={form.$id} />
				<FieldText field={form.$name} />
				<FieldTextArray field={form.$synonyms} multiline />
				<FieldText multiline field={form.$comment} />
				<FieldSwitch field={form.$active} />
			</EditCenter>
			<EditRight>
				<Button variant="contained" onClick={form.submit} disabled={!canSave}>
					Save
				</Button>
				<br />
				<Button variant="outlined" onClick={navBack}>
					Cancel
				</Button>
				<Button variant="outlined" onClick={form.reset}>
					Reset
				</Button>
				{id && (
					<>
						<br />
						<Button
							disabled={!auth.can('notes', 'DELETE')}
							variant="outlined"
							color="error"
							onClick={() =>
								ui.confirmDelete(() =>
									notes.delete(id).then(() => {
										navBack();
										ui.notify('Note deleted successfully', 'success');
									}),
								)
							}
						>
							Delete
						</Button>
					</>
				)}
			</EditRight>
		</EditContainer>
	);
});
