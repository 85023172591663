import { DeleteForever } from '@mui/icons-material';
import { Button, IconButton, Paper } from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FragranceLinkType } from '~/api/api.declaration';
import { FragranceService } from '~/service/service.fragrance';
import { FragranceLinkDto } from '~/shared/dto/admin/fragrance.dto';
import { FragranceLinkId } from '~/shared/dto/id';
import { Form } from '~/shared/form/form';
import { useService } from '~/shared/service/service.base';
import { FieldEnum } from '~/view/fields/field.enum';
import { FieldText } from '~/view/fields/field.text';
import { Col, Row } from '~/view/view.box';

export const FragranceLinks = observer(({ field }: { field: Form.Field<FragranceLinkDto[]> }) => {
	const { value } = field;

	return (
		<DragDropContext
			onDragEnd={e => {
				if (!e.destination) return;
				const arr = [...(value ?? [])];
				const [removed] = arr.splice(e.source.index, 1);
				arr.splice(e.destination.index, 0, removed);
				field.set(arr);
			}}
		>
			<Col>
				<Droppable droppableId="links">
					{provided => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{value?.map((link, idx) => (
								<Draggable
									index={idx}
									draggableId={(link.id ?? 0)?.toString()}
									key={(link.id ?? 0)?.toString()}
								>
									{provided => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
										>
											<FragranceLink
												link={link}
												onRemove={() => field.set(value.filter((_, i) => idx !== i))}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</Col>
			<Button
				variant="outlined"
				onClick={() => {
					field.set([
						...value,
						{
							id: Math.random() as FragranceLinkId,
							subtitle: '',
							title: '',
							type: 'ARTICLE',
							url: '',
						},
					]);
				}}
			>
				Add link
			</Button>
		</DragDropContext>
	);
});

const FragranceLink = observer(({ link, onRemove }: { link: FragranceLinkDto; onRemove: () => void }) => {
	const [fragranceSvc] = useService(FragranceService);
	const form = Form.use(FragranceLinkDto, {
		initial: link,
		onSubmit: v => {
			runInAction(() => Object.assign(link, v));
		},
		hookGetInstance(dto) {
			if (dto.id && dto.id < 1) dto.id = undefined;
		},
	});

	return (
		<Paper
			elevation={3}
			sx={{
				mb: 2,
				py: 2,
				px: 2,
				display: 'flex',
				flexDirection: 'row',
				alignContent: 'center',
				justifyContent: 'center',
				position: 'relative',
			}}
		>
			<Col flex={1}>
				<Row gap={1}>
					<FieldEnum
						field={form.$type}
						enumeration={FragranceLinkType}
						enumName="FragranceLinkType"
						sx={{ width: 120 }}
					/>
					<FieldText
						field={form.$url}
						fullWidth
						onPaste={e => {
							fragranceSvc.getUrlInfo(e.clipboardData.getData('text')).then(resp => {
								if (resp) {
									form.$title.set(resp.title);
								}
							});
						}}
					/>
					<IconButton onClick={onRemove}>
						<DeleteForever />
					</IconButton>
				</Row>
				<FieldText field={form.$title} fullWidth />
				<FieldText field={form.$subtitle} fullWidth />
			</Col>
		</Paper>
	);
});
