import { debounce } from '@mui/material';
import { GridDensity, GridPaginationModel } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { action, makeObservable, observable, reaction } from 'mobx';
import { ChangeEvent } from 'react';

export class GridStateModel {
	@observable.ref columnVisibility: Record<string, boolean> = {};
	@observable.struct density: GridDensity = 'standard';
	@observable pageSize = 25;
	@observable page = 0;
	@observable.struct sort?: string = undefined;
	@observable.struct sortDir?: 'ASC' | 'ASC' = undefined;
	@observable.struct filter?: string = undefined;
	@observable.struct filterQuery?: string = undefined;
	@observable.ref paginationModel = { page: 0, pageSize: 25 };

	constructor(public readonly name: string) {
		makeObservable(this);
		this.load();
		reaction(() => JSON.stringify(this), this.save);
	}

	@action load() {
		const state = localStorage.getItem('grid_' + this.name);
		if (state) {
			const parsed = JSON.parse(state);
			this.density = parsed.density;
			this.pageSize = parsed.pageSize;
			this.columnVisibility = parsed.columnVisibility;
			this.sort = parsed.sort;
			this.sortDir = parsed.sortDir?.toUpperCase();
		}
	}

	@action onStateChange = (state: any) => {
		this.density = state.density.value;
		this.columnVisibility = state.columns.columnVisibilityModel;
		this.page = state.pagination.paginationModel.page;
		this.pageSize = state.pagination.paginationModel.pageSize;
		const sort = state.sorting.sortModel[0];
		this.sort = sort?.field;
		this.sortDir = sort?.sort.toUpperCase();
	};

	@action.bound onPaginationModelChange(model: GridPaginationModel) {
		this.paginationModel = model;
	}

	changeFilter = (e: ChangeEvent<{ value: string }>) => this.setFilter(e.target.value);

	@action.bound setFilter(filter: string | undefined) {
		this.filter = filter;
		this.setFilterQuery(filter);
	}

	@action.bound resetFilter() {
		this.filter = undefined;
		this.filterQuery = undefined;
	}

	private setFilterQuery = debounce(
		action((filterQuery: string | undefined) => {
			this.filterQuery = filterQuery;
			this.paginationModel = { page: 0, pageSize: this.paginationModel.pageSize };
		}),
		2000,
	);

	save = () => {
		localStorage.setItem('grid_' + this.name, JSON.stringify({ ...this, page: undefined }));
	};

	get offset(): number {
		return this.page * this.pageSize;
	}

	initial(): GridInitialStateCommunity {
		return {
			columns: {
				columnVisibilityModel: this.columnVisibility,
			},
			pagination: {
				paginationModel: { page: this.page, pageSize: this.pageSize },
			},
			sorting: {
				sortModel: this.sort
					? [{ field: this.sort, sort: this.sortDir ? (this.sortDir === 'ASC' ? 'asc' : 'desc') : undefined }]
					: [],
			},
		};
	}

	subscribe(onChange: () => void): void | (() => void) {
		return reaction(
			() =>
				Object.keys(this)
					.filter(k => k !== 'filter' && k !== 'paginationModel')
					.map(k => (this as any)[k]),
			onChange,
		);
	}
}
